import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import LoginButton from "./login/LoginButton";
import { useNavigate } from "react-router-dom";
import { CenterOnPage } from "./core/CenterOnPage";
import { Card, Box, CardActions, Stack, Typography } from "@mui/material";
import textLogo from "./assets/text-logo.png";
import { theme } from "./theme";

const LoggedoutPage = () => {
  return (
    <CenterOnPage>
      <Box width="75ch">
        <Card>
          <Box p={2}>
            <Stack direction="row" pb={2} pt={2}>
              <img src={textLogo} alt="" style={{ width: 200, height: "auto" }} />
            </Stack>
            <Typography variant="h3" textAlign="center">
              Taking your games from <span style={{ color: theme.palette.primary.main }}>pitch</span> to the&nbsp;
              <span style={{ color: theme.palette.primary.main }}>table</span>.
            </Typography>
            <Box pt={2}>
              <Typography>
                Whether you're a designer, publisher or self-publisher, Pitch2Table is here to help you take your games
                to the table.
              </Typography>
            </Box>
          </Box>
          <CardActions>
            <LoginButton overrideLabel="Login" />
          </CardActions>
        </Card>
      </Box>
    </CenterOnPage>
  );
};

/**
 * @param {object} props
 * @property {React.JSX.Element|string} props.UnauthenticatedView
 */
function ProtectedRoute({ children, UnauthenticatedView = null }) {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  const ToShow = UnauthenticatedView ? UnauthenticatedView : LoggedoutPage;

  if (!isAuthenticated && typeof UnauthenticatedView === "string") {
    navigate(UnauthenticatedView);
    return;
  }

  return <>{isAuthenticated ? children : <ToShow />}</>;
}

export default ProtectedRoute;
