import React from "react";
import { Box, Card, CircularProgress, List, ListItem, ListItemText } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import {
  fetchThisUser,
  updateContactNumber,
  updateFirstName,
  updateLastName,
  updatePreferredName,
  updatePronouns,
} from "../users/users-service";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token } from "../util/type-conversion";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import { ThisUser } from "../games/query-keys";
import endpoints from "../endpoints";
import { enqueueSnackbar } from "notistack";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileValidateType);

const FirstNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="First Name"
      helperText="Your first name, as you'd like to appear on the box"
      initialValue={initialValue}
      updateFunction={updateFirstName}
      queryKey={ThisUser()}
    />
  );
};

const LastNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Last Name"
      helperText="Your last name, as you'd like to appear on the box"
      initialValue={initialValue}
      updateFunction={updateLastName}
      queryKey={ThisUser()}
    />
  );
};

const PreferredNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Preferred Name"
      helperText="The name you'd like people to call you."
      initialValue={initialValue}
      updateFunction={updatePreferredName}
      queryKey={ThisUser()}
    />
  );
};

const PronounsTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Pronouns"
      helperText="What are your pronouns?"
      initialValue={initialValue}
      updateFunction={updatePronouns}
      queryKey={ThisUser()}
    />
  );
};

const ContactNumberTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Contact Number"
      helperText="Your preferred contact number (mobile or landline)"
      initialValue={initialValue}
      updateFunction={updateContactNumber}
      queryKey={ThisUser()}
    />
  );
};

export const AboutYou = () => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { status, data } = useQuery({
    queryKey: ThisUser(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      return fetchThisUser(token);
    },
  });

  if (status === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (status === "error" || !data) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <Box pl={2} pr={2}>
          <FilePond
            allowMultiple={false}
            allowReplace={false}
            allowRevert={false}
            allowProcess={false}
            credits={false}
            instantUpload={true}
            labelIdle={'Drag & Drop your avatar or <span class="filepond--label-action"> Browse </span>'}
            imageValidateSizeMaxWidth={1024}
            imageValidateSizeMaxHeight={1024}
            imageCropAspectRatio="1:1"
            acceptedFileTypes={["image/png", "image/jpeg"]}
            fileValidateTypeLabelExpectedTypesMap={{ "image/png": "png", "image/jpeg": "jpg" }}
            server={{
              process: async (_fieldName, file, _metadata, load, error, progress, abort) => {
                const formData = new FormData();
                formData.append("avatar", file, file.name);

                const token = s2Token(await getAccessTokenSilently());

                const { users } = endpoints();
                const url = users().avatar();

                const request = new XMLHttpRequest();
                request.open("POST", url);
                request.setRequestHeader("Authorization", `Bearer ${token}`);

                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                };

                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                    enqueueSnackbar("Avatar updated", { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: ThisUser() });
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error("oh no");
                    enqueueSnackbar("An error occured uploading the avatar", { variant: "error" });
                  }
                };

                request.send(formData);

                return {
                  abort: () => {
                    request.abort();
                    abort();
                  },
                };
              },
            }}
          />
        </Box>
        <ListItem>
          <FirstNameTextField initialValue={data.firstName} />
        </ListItem>
        <ListItem>
          <LastNameTextField initialValue={data.lastName} />
        </ListItem>
        <ListItem>
          <PreferredNameTextField initialValue={data.preferredName} />
        </ListItem>
        <ListItem>
          <PronounsTextField initialValue={data.pronouns} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" secondary={data.email} />
        </ListItem>
        <ListItem>
          <ContactNumberTextField initialValue={data.contactNumber} />
        </ListItem>
      </List>
    </Card>
  );
};
