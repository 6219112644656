import { Box, Button, Card, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SectionHeading } from "../core/font-sizes";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { createNewPublisher, fetchMyPublishers } from "./service";
import { Path } from "../Path";
import quoll from "../assets/text-logo.png";
import { SmartTextField } from "../games/SmartTextField";
import { enqueueSnackbar } from "notistack";
import { MyPublishers } from "../games/query-keys";

export const CreateNewPublisher = () => {
  const { getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [publisherName, setPublisherName] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);

  const { data = [] } = useQuery({
    queryKey: MyPublishers(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      return fetchMyPublishers(token);
    },
  });

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    navigate(Path.Publisher.Home(data[0].id));
  }, [data, navigate]);

  useEffect(() => {
    setCanSubmit(publisherName.length > 0);
  }, [publisherName]);

  const mutation = useMutation({
    /**
     * @param {string} name
     */
    mutationFn: async (name) => {
      const token = s2Token(await getAccessTokenSilently());
      return await createNewPublisher(token, name);
    },
    /**
     *
     * @param {Publisher} publisher
     */
    onSuccess: (publisher) => {
      enqueueSnackbar("Saved", { variant: "success" });
      navigate(Path.Publisher.Home(publisher.id));
      queryClient.invalidateQueries({ queryKey: MyPublishers() });
    },
    onError: ({ response }) => {
      if (response.status === 409) {
        enqueueSnackbar("A publisher with that name already exists.", { variant: "warning" });
        return;
      }

      enqueueSnackbar("An error occured.", { variant: "error" });
    },
  });

  const handleFinishPublisherSetup = async () => {
    mutation.mutate(publisherName);
  };

  return (
    <Card>
      <Box p={2} width="100%" flex={1}>
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Box pt={8}>
              <img src={quoll} alt="Quoll mascot" width="100%" />
            </Box>
          </Grid>
          <Grid item xs={8}>
            <Box p={4}>
              <Box pb={2}>
                <SectionHeading>Welcome!</SectionHeading>
              </Box>
              <SectionHeading>Let's get you setup.</SectionHeading>
              <Box pt={4}>
                <SmartTextField
                  required
                  label="Company Name"
                  helperText="This is what designers will see when they work with you."
                  onChange={setPublisherName}
                />
              </Box>
              <Box pt={4}>
                <Button disabled={!canSubmit} variant="contained" color="primary" onClick={handleFinishPublisherSetup}>
                  Finish
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};
