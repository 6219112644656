/**
 * @param {GameId?} [gameId]
 */
export const PublicGameByGameId = (gameId) => ["public-game", gameId];

/**
 * @param {GameId?} [gameId]
 */
export const PublisherInterestByGameId = (gameId) => ["publisher-interest", gameId];

/**
 * @param {PublisherId?} [publisherId]
 * @param {GameId?} [gameId]
 */
export const PublisherGameByGameId = (publisherId, gameId) => ["publisher-game", publisherId, gameId];

/**
 * @param {GameId} [gameId]
 */
export const DesignersByGameId = (gameId) => ["designers", gameId];

/**
 * @param {GameId} gameId
 */
export const AllRulesByGameId = (gameId) => ["rules", gameId];

/**
 * @param {GameId} gameId
 */
export const AllSellsheetsByGameId = (gameId) => ["sellsheets", gameId];

/**
 * @param {GameId} gameId
 */
export const LatestRulesByGameId = (gameId) => ["latest-rules", gameId];

/**
 * @param {GameId} gameId
 */
export const LatestSellsheetByGameId = (gameId) => ["latest-sellsheet", gameId];

/**
 * @param {GameId} gameId
 */
export const PrivateGameByGameId = (gameId) => ["private-game", gameId];

export const MyGamesAsSummaries = () => ["my-summaries"];
export const MyPublishers = () => ["my-publishers"];
export const ThisUser = () => ["this-user"];

/**
 * @param {PublisherId} [publisherId]
 */
export const PublisherByPublisherId = (publisherId) => ["publisher", publisherId];

/**
 * @param {PublisherId?} [publisherId]
 */
export const PublisherSubmissionsByPublisherId = (publisherId) => ["publisher-submissions", publisherId];
