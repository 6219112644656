import endpoints from "../endpoints";
import { get, optionsNoAuth } from "../network";

/**
 * @returns {Promise<ServerConfig>}
 */
export const fetchConfig = async () => {
  const { server } = endpoints();
  const url = server().config();

  const response = await get(url, optionsNoAuth());
  return response.data;
};
