import React from "react";
import { ListDetail } from "../core/ListDetail";
import { PublisherDetail } from "../concept/PublisherDetail";
import { PublisherList, game, pitchedGames } from "../concept/PublisherList";

export const GamePitches = () => {
  const ListView = () => <PublisherList gameName={game} publishers={pitchedGames} />;

  return <ListDetail ListView={ListView} DetailView={PublisherDetail} />;
};
