import { useQuery } from "@tanstack/react-query";
import { PrivateGameByGameId, PublicGameByGameId, PublisherInterestByGameId } from "./query-keys";
import { fetchGame, fetchPublicGame, fetchPublisherInterest } from "./games-service";
import { DefaultCacheTime, DefaultStaleTime } from "../core/react-query";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @param {GameId} gameId
 */
export const usePublicGame = (gameId) => {
  return useQuery({
    queryKey: PublicGameByGameId(gameId),
    queryFn: async () => {
      return fetchPublicGame(gameId);
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};

/**
 * @param {GameId} gameId
 */
export const useDesignerGame = (gameId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: PrivateGameByGameId(gameId),
    queryFn: async () => {
      if (!gameId) {
        return null;
      }

      const token = s2Token(await getAccessTokenSilently());
      return fetchGame(token, gameId);
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};

/**
 * @param {GameId} gameId
 */
export const usePublisherInterestInGame = (gameId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: PublisherInterestByGameId(gameId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const data = await fetchPublisherInterest(token, gameId);
      return data;
    },
    staleTime: 0,
    cacheTime: 0,
  });
};
