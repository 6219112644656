import React from "react";
import { DateTime } from "luxon";
import { ListView } from "../core/ListView";
import { ListItemButton, ListItemText, Typography } from "@mui/material";

/**
 * @param {Object} props
 * @property {Sellsheet} sellsheet
 * @property {boolean} selected
 * @property {Function} onClick
 */
const SellsheetListEntry = ({ sellsheet, selected, version, onClick }) => {
  return (
    <ListItemButton
      alignItems="flex-start"
      selected={selected}
      onClick={() => {
        onClick(sellsheet.id);
      }}
    >
      <ListItemText
        primary={`v${version}`}
        secondary={`Uploaded ${DateTime.fromMillis(sellsheet.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @property {Sellsheet[]} sellsheets
 * @property {string} selected
 * @property {Function} onClick
 */
export const SellsheetList = ({ sellsheets = [], selected, onClick, back }) => {
  return (
    <ListView title="SELLSHEETS" Filters={null} Action={null} back={back}>
      {sellsheets.map((sellsheet, index) => (
        <SellsheetListEntry
          key={sellsheet.id.toString()}
          sellsheet={sellsheet}
          selected={selected === sellsheet.id}
          onClick={onClick}
          version={sellsheets.length - index}
        />
      ))}
      {sellsheets.length === 0 && <Typography>No sellsheets found.</Typography>}
    </ListView>
  );
};
