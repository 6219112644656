import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../../providers/GameProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import numeral from "numeral";

/**
 * @param {number|undefined?} mm
 * @returns {number}
 */
const mm2in = (mm) => (mm ? mm / 25.4 : 0);

/**
 * @param {number?} g
 * @returns {number}
 */
const g2oz = (g = 0) => (g || 0) / 28.35;

export const ComponentConfiguration = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  const activeComponentConfiguration = game.components.find((configuration) => configuration.status === "Active");
  if (!activeComponentConfiguration) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Components - {activeComponentConfiguration.configuationName}
        </Typography>
        <Typography color="text.secondary">
          Showing active configuration.&nbsp;
          <Link component={RouterLink} to={`/game/${game.internalId}/components`}>
            {game.components.length} other configurations found.
          </Link>
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell>Name</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeComponentConfiguration.components.map((component) => {
              return (
                <TableRow key={component.id}>
                  <TableCell>{component.id}</TableCell>
                  <TableCell align="right">{component.quantity}</TableCell>
                  <TableCell>{component.name}</TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{activeComponentConfiguration.cartonSize}</TableCell>
              <TableCell>Units per Carton</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{`${activeComponentConfiguration.cartonDimensions?.width} x ${activeComponentConfiguration.cartonDimensions?.length} x ${activeComponentConfiguration.cartonDimensions?.height}mm`}</TableCell>
              <TableCell>Carton Size (mm)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">{`${numeral(mm2in(activeComponentConfiguration.cartonDimensions?.width)).format(
                "0.0"
              )}" x ${numeral(mm2in(activeComponentConfiguration.cartonDimensions?.length)).format("0.0")}" x ${numeral(
                mm2in(activeComponentConfiguration.cartonDimensions?.height)
              ).format("0.0")}"`}</TableCell>
              <TableCell>Carton Size (in)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">
                {activeComponentConfiguration.actualWeightInGrams
                  ? activeComponentConfiguration.actualWeightInGrams
                  : activeComponentConfiguration.estimatedWeightInGrams}
              </TableCell>
              <TableCell>Weight (g)</TableCell>
            </TableRow>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">
                {activeComponentConfiguration.actualWeightInGrams
                  ? numeral(g2oz(activeComponentConfiguration.actualWeightInGrams)).format("0.00")
                  : g2oz(activeComponentConfiguration.estimatedWeightInGrams)}
              </TableCell>
              <TableCell>Weight (oz)</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
