import { Grid, Box, Card, Typography, Paper, styled } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../../providers/GameProvider";

const Planning = () => {
  const { games } = useContext(GameContext);

  return (
    <Box pt={2}>
      <Grid container spacing={2} columns={4} justifyContent="center">
        <Grid item xs={1}>
          <Paper>
            <Typography variant="h6" textAlign="center">
              Q3, 2023
            </Typography>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Ten Wickets (Printing)</Typography>
              </Paper>
            </Box>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Team Principal (Development)</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Paper>
            <Typography variant="h6" textAlign="center">
              Q4, 2023
            </Typography>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Ten Wickets (Shipping)</Typography>
              </Paper>
            </Box>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Team Principal (Development)</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Paper>
            <Typography variant="h6" textAlign="center">
              Q1, 2024
            </Typography>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Team Principal (Artwork)</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={1}>
          <Paper>
            <Typography variant="h6" textAlign="center">
              Q2, 2024
            </Typography>
            <Box p={2}>
              <Paper variant="outlined" square>
                <Typography textAlign="center">Team Principal (Kickstarter)</Typography>
              </Paper>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Planning;
