import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { CenterOnPage } from "./core/CenterOnPage";
import { Heading } from "./core/Heading";
import textLogo from "./assets/text-logo.png";

export const ErrorPage = () => (
  <CenterOnPage>
    <Card style={{ maxWidth: "66ch" }}>
      <Box p={2}>
        <Box display="flex" justifyContent="center">
          <img src={textLogo} width="50%" alt="pitch2table.com logo" />
        </Box>
        <Heading textAlign="center" variant="h5">
          ERROR
        </Heading>
        <Box pt={2} pb={2}>
          <Typography textAlign="center">
            We've encountered an error and have not been able to complete your request. Try again and if it's still not
            working, reach out to us at: <a href="mailto:support@pitch2table.com">support@pitch2table.com</a>
          </Typography>
        </Box>
      </Box>
    </Card>
  </CenterOnPage>
);
