/**
 * @type {Record<DesignerStatus,string>}
 */
export const StatusToLabel = {
  Abandoned: "Abandoned",
  Idea: "Idea",
  InDevelopmentEarly: "In Development - Early",
  InDevelopmentMid: "In Development - Mid",
  InDevelopmentLate: "In Development - Late",
  DesignComplete: "Design Complete",
  PitchingNow: "Pitching Now",
  InProduction: "In Production",
  PitchPreparation: "Ready for Pitching",
  Released: "Released",
  Retired: "Retired",
  Signed: "Signed",
};
/**
 * @type {Record<DesignerGameStatus,string>}
 */
export const DesignerGameStatusToLabel = {
  Idea: "Idea",
  InDevelopmentEarly: "In Development - Early",
  InDevelopmentMid: "In Development - Mid",
  InDevelopmentLate: "In Development - Late",
  PitchingNow: "Pitching Now",
  Published: "Published",
  Signed: "Signed",
};

/**
 * @type {Record<DesignerStatus, string>}
 */
export const HelperText = {
  Idea: "An untested game idea.",
  InDevelopmentEarly: "Early tests with significant changes expected.",
  InDevelopmentMid: "Basic systems bedded down, changes still expected.",
  InDevelopmentLate: "Balancing and iteration",
  DesignComplete: "The design is complete, no changed expected",
  PitchPreparation: "Pitching material preparation in progress.",
  PitchingNow: "Actively pitching to publishers.",
  Signed: "Contract signed with publisher.",
  // Released: "Print complete. Pending release".
  Released: "Available for purchase.",
  Retired: "No longer available.",
  Abandoned: "No further work planned.",
  InProduction: "The game is being printed.",
};
/**
 * @type {Record<DesignerGameStatus, string>}
 */
export const DesignerGameStatusHelperText = {
  Idea: "An untested game idea.",
  InDevelopmentEarly: "Early tests with significant changes expected.",
  InDevelopmentMid: "Basic systems bedded down, changes still expected.",
  InDevelopmentLate: "Balancing and iteration",
  PitchingNow: "Actively pitching to publishers.",
  Signed: "Contract signed with publisher.",
  Published: "In the market or out of print.",
};
