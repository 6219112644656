import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import NoProfile from "@mui/icons-material/Person2Outlined";

const LoginButton = ({ overrideLabel }) => {
  const { loginWithRedirect } = useAuth0();

  const onClickHandler = useCallback(() => loginWithRedirect(), [loginWithRedirect]);

  return (
    <Button variant="contained" onClick={onClickHandler}>
      {overrideLabel ? overrideLabel : <NoProfile />}
    </Button>
  );
};

export default LoginButton;
