import {
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
  Card,
  CardContent,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  InputAdornment,
  Box,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { GameContext } from "../providers/GameProvider";
import { useNavigate, useParams } from "react-router-dom";
import { HelperText } from "./model";
import { Check, PendingOutlined } from "@mui/icons-material";
import { useDebounce } from "use-debounce";

const IconMap = {
  HIDDEN: Box,
  SAVING: PendingOutlined,
  SAVED: Check,
};

export const GameOverview = () => {
  const { games, updateName } = useContext(GameContext);
  const params = useParams();
  const navigate = useNavigate();

  const game = games.find((g) => g.internalId === params.gameId);
  const [name, setName] = useState(game ? game.name : "");
  const [debouncedValue] = useDebounce(name, 500);
  const [nameSaveProgress, setNameSaveProgress] = useState("HIDDEN");

  useEffect(() => {
    if (!game) {
      navigate("/");
      return;
    }
  }, [game, navigate]);

  useEffect(() => {
    setNameSaveProgress("SAVING");
  }, [name]);

  useEffect(() => {
    if (debouncedValue.length === 0) {
      return;
    }
    if (!game) {
      return;
    }
    if (debouncedValue === game.name) {
      setNameSaveProgress("SAVED");
      return;
    }

    updateName(game.internalId, debouncedValue);
    setNameSaveProgress("SAVED");
  }, [game, debouncedValue, updateName]);

  if (!game) {
    return;
  }

  const nameOnChangeHandler = (ev) => {
    setName(ev.target.value);
  };

  const tagOnChangeHandler = (ev) => {};
  const statusOnChangeHandler = (ev) => {};

  const Icon = IconMap[nameSaveProgress];

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Game Essentials
        </Typography>
        <TextField required disabled fullWidth type="text" value={game.internalId} label="ID" margin="normal" />
        <TextField
          required
          fullWidth
          type="text"
          value={name}
          label="Game Name"
          onChange={nameOnChangeHandler}
          margin="normal"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Icon />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          required
          fullWidth
          multiline
          type="text"
          value={game.briefDescription}
          label="Tag Line"
          onChange={tagOnChangeHandler}
          margin="normal"
        />
        <FormControl sx={{ width: "100%" }} margin="normal">
          <InputLabel id="game-status">Status</InputLabel>
          <Select
            labelId="game-status"
            value={game.status}
            onChange={statusOnChangeHandler}
            placeholder="status"
            label="Status"
            input={<OutlinedInput label="Status" />}
          >
            <ListSubheader>New</ListSubheader>
            <MenuItem value="Idea">&nbsp;&gt;&nbsp;Idea</MenuItem>
            <MenuItem value="InDevelopment">&nbsp;&gt;&nbsp;In Development</MenuItem>
            <ListSubheader>Goal: Pitch</ListSubheader>
            <MenuItem value="PitchPreparation">&nbsp;&gt;&nbsp;Pitch Preparation</MenuItem>
            <MenuItem value="PitchingNow">&nbsp;&gt;&nbsp;Pitching Now</MenuItem>
            <MenuItem value="Signed">&nbsp;&gt;&nbsp;Signed</MenuItem>
            <ListSubheader>Goal: Publish</ListSubheader>
            <MenuItem value="DesignComplete">&nbsp;&gt;&nbsp;Design Complete</MenuItem>
            <MenuItem value="InProduction">&nbsp;&gt;&nbsp;In Production</MenuItem>
            <ListSubheader>Completed</ListSubheader>
            <MenuItem value="Released">&nbsp;&gt;&nbsp;Released</MenuItem>
            <MenuItem value="Abandoned">&nbsp;&gt;&nbsp;Abandoned</MenuItem>
          </Select>
          <FormHelperText>{HelperText[game.status] || ""}</FormHelperText>
        </FormControl>
      </CardContent>
    </Card>
  );
};
