import React, { useContext } from "react";
import { AccountContext } from "../core/AccountProvider";
import { usePublisherSubmissions } from "./queries";

export const PublisherSubmissionsData = ({ children }) => {
  const { publisherId } = useContext(AccountContext);
  const { status = "loading", data = [], error } = usePublisherSubmissions(publisherId);

  return <>{React.cloneElement(children, { submissions: data, submissionsStatus: status, submissionsError: error })}</>;
};
