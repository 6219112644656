import React, { useContext } from "react";
import { AccountContext } from "./core/AccountProvider";
import { useTheme } from "@mui/material/styles";

export const AppFrame = ({ children }) => {
  const { mode } = useContext(AccountContext);
  const theme = useTheme();
  const borderColor = mode === "Designer" ? theme.palette.background.default : theme.palette.secondary.main;

  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        minHeight: "100vh",
        width: "100vw",
        borderColor: borderColor,
        borderTopWidth: 10,
        borderBottomWidth: 10,
        borderLeftWidth: 0,
        borderRightWidth: 0,
        borderStyle: "solid",
      }}
    >
      {children}
    </div>
  );
};
