import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { fetchMyPublishers, fetchPublisherContact, fetchPublisherGame, fetchSubmissions } from "./service";
import { DefaultStaleTime, DefaultCacheTime } from "../core/react-query";
import { MyPublishers, PublisherGameByGameId, PublisherSubmissionsByPublisherId } from "../games/query-keys";

/**
 * @param {PublisherId?} [publisherId]
 */
export const PublisherContactKey = (publisherId) => ["publisher-contact", publisherId];

/**
 * @param {PublisherId?} [publisherId]
 */
export const usePublisherSubmissions = (publisherId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: PublisherSubmissionsByPublisherId(publisherId),
    queryFn: async () => {
      if (!publisherId) {
        return [];
      }

      const token = s2Token(await getAccessTokenSilently());
      const games = await fetchSubmissions(token, publisherId);

      return games;
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};

/**
 * @param {PublisherId} publisherId
 * @param {GameId} gameId
 */
export const usePublisherGame = (publisherId, gameId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: PublisherGameByGameId(publisherId, gameId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const data = await fetchPublisherGame(token, publisherId, gameId);
      return data;
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};

/**
 * @param {PublisherId} publisherId
 */
export const usePublisherContact = (publisherId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: PublisherContactKey(publisherId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      const data = await fetchPublisherContact(token, publisherId);
      return data;
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};

export const useMyPublishers = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: MyPublishers(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      return fetchMyPublishers(token);
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};
