import { TextField, Typography, Card, CardContent, Link } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import { NumericFormatCustom } from "../games/NumericFormatCustom";

export const BudgetSimple = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  const activeBudget = game.budgets[0];

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Budget
        </Typography>
        <Typography color="text.secondary">
          Showing active budget.&nbsp;
          <Link component={RouterLink} to={`/game/${game.internalId}/budgets`}>
            {game.budgets.length === 1 ? "No other budgets found." : `${game.budgets.length}other budgets found.`}
          </Link>
        </Typography>
        {activeBudget.items.map((budgetItem) => (
          <TextField
            key={budgetItem.name}
            fullWidth
            type="text"
            value={budgetItem.amount}
            label={budgetItem.name}
            margin="normal"
            InputProps={{
              inputComponent: NumericFormatCustom,
            }}
          />
        ))}
        <TextField
          fullWidth
          type="text"
          disabled
          value={activeBudget.items.reduce((t, item) => t + item.amount, 0)}
          label="Total Budget"
          margin="normal"
          InputProps={{
            inputComponent: NumericFormatCustom,
          }}
        />
      </CardContent>
    </Card>
  );
};
