import React from "react";
import { ListDetail } from "../core/ListDetail";
import { DesignerSettingsList } from "./SettingsList";
import { AboutYou } from "./AboutYou";
import { YourLocation } from "./YourLocation";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useSettingsCategoryFromRoute } from "./params";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";

export const Settings = () => {
  const category = useSettingsCategoryFromRoute();
  const navigate = useNavigate();

  const onListItemSelected = (id) => {
    navigate(Path.settings().category(id));
  };

  const SelectedCategory = () => {
    if (category === "about-you") {
      return <AboutYou />;
    }
    if (category === "your-location") {
      return <YourLocation />;
    }

    return <EmptyDetailCard text="Select setting group from list on the left" />;
  };
  const ListView = () => <DesignerSettingsList selectedListItemId={category} onListItemSelected={onListItemSelected} />;

  return <ListDetail ListView={ListView} DetailView={SelectedCategory} />;
};
