import React from "react";
import {
  Avatar,
  Chip,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";

export const game = "Little Succulents";
/**
 * @type {Publisher[]}
 */
const publishers = [
  {
    id: "1",
    name: "Skybound Interactive",
  },
  {
    id: "2",
    name: "25th Century Games",
  },
  {
    id: "3",
    name: "Hasbro",
  },
  {
    id: "4",
    name: "Ravensburger",
  },
];
// const contacts = [
//   { id: "1", name: "Tricia Victor" },
//   { id: "2", name: "Ben Kepler" },
// ];
// const publisherContacts = [
//   {
//     id: "1",
//     contactId: "1",
//     publisherId: "1",
//   },
//   {
//     id: "2",
//     contactId: "2",
//     publisherId: "1",
//   },
// ];
/**
 * @type {PublisherGame[]}
 */
export const pitchedGames = [
  {
    gameId: "2022#17",
    id: "2022#17-1",
    publisherId: "1",
    publisherContactId: ["1", "2"],
    history: [
      {
        timestamp: DateTime.fromISO("20230923").toMillis(),
        status: "Declined",
        notes: "Unable to progress game until 2025.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230815").toMillis(),
        status: "PendingContracts",
        notes: "Still pending with legal.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230716").toMillis(),
        status: "PendingContracts",
        notes: "Still pending with legal.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230616").toMillis(),
        status: "PendingContracts",
        notes: "They want to sign.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230615").toMillis(),
        status: "MeetingRequested",
        notes: "Want to progress.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230606").toMillis(),
        status: "DigitalMaterialsRequested",
        notes: "Wanted another link.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230603").toMillis(),
        status: "FollowUp",
        notes: "Follow up sent.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230509").toMillis(),
        status: "DigitalMaterialsSubmitted",
        notes: "Sent screentop link.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230508").toMillis(),
        status: "DigitalMaterialsRequested",
        notes: "Request digital playtest link.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230506").toMillis(),
        status: "FollowUp",
        notes: "Sent follow up email.",
        userId: "1",
      },
      {
        timestamp: DateTime.fromISO("20230505").toMillis(),
        status: "DigitalMaterialsSubmitted",
        notes: "Pitched at UNPUB.",
        userId: "1",
      },
    ],
  },
  {
    gameId: "2022#17",
    id: "2022#17-2",
    publisherId: "2",
    publisherContactId: [],
    history: [
      {
        timestamp: DateTime.fromISO("20230906").toMillis(),
        status: "Declined",
        notes: "Too fiddly. Repitch with changes",
        userId: "1",
      },
    ],
  },
  {
    gameId: "2022#17",
    id: "2022#17-3",
    publisherId: "3",
    publisherContactId: [],
    history: [
      {
        timestamp: DateTime.fromISO("20230830").toMillis(),
        status: "Declined",
        notes: "Theme saturation",
        userId: "1",
      },
    ],
  },
  {
    gameId: "2022#17",
    id: "2022#17-4",
    publisherId: "4",
    publisherContactId: [],
    history: [
      {
        timestamp: DateTime.fromISO("20230624").toMillis(),
        status: "Declined",
        notes: "Theme saturation",
        userId: "1",
      },
    ],
  },
];
/**
 *
 * @param {PublisherGameStatusHistory} a
 * @param {PublisherGameStatusHistory} b
 * @returns {number}
 */
const sortPublisherHistory = (a, b) => b.timestamp - a.timestamp;
const PublisherListEntry = ({ publisher, selected }) => {
  const publisherInfo = publishers.find(({ id }) => id === publisher.publisherId);
  const mostRecentEntry = publisher.history.sort(sortPublisherHistory)[0];

  return (
    <ListItemButton alignItems="flex-start" selected={selected}>
      <ListItemAvatar>
        <Avatar>SI</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={publisherInfo.name}
        secondary={`${DateTime.fromMillis(mostRecentEntry.timestamp).toFormat("DD")} - ${mostRecentEntry.notes}`}
      />
      <ListItemIcon>{mostRecentEntry.status}</ListItemIcon>
    </ListItemButton>
  );
};

export const PublisherList = ({ gameName, publishers }) => {
  return (
    <Paper elevation={1} square={false}>
      <Typography variant="h4" textAlign="center" p={2}>
        {gameName}
      </Typography>
      <Stack direction="row" spacing={1} justifyContent="center" p={2}>
        <Chip label="ALL" variant="filled" onClick={() => {}} />
        <Chip label="IN PROGRESS" variant="outlined" onClick={() => {}} />
        <Chip label="DECLINED" variant="outlined" onClick={() => {}} />
      </Stack>
      <List>
        {publishers.map((publisher, i) => {
          return <PublisherListEntry selected={i === 0} publisher={publisher} key={publisher.id} />;
        })}
      </List>
    </Paper>
  );
};
