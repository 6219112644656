import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../../providers/GameProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Check } from "@mui/icons-material";
import numeral from "numeral";

/**
 * @param {number} mm
 * @returns {number}
 */
const mm2in = (mm) => mm / 25.4;

/**
 * @param {number?} g
 * @returns {number}
 */
const g2oz = (g = 0) => (g || 0) / 28.35;

export const ArtReadyness = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  const activeComponentConfiguration = game.components.find((configuration) => configuration.status === "Active");
  if (!activeComponentConfiguration) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Art Readyness - {activeComponentConfiguration.configuationName}
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Id</TableCell>
              <TableCell>Name</TableCell>
              <TableCell>Art</TableCell>
              <TableCell>eProof</TableCell>
              <TableCell>White</TableCell>
              <TableCell>Digital</TableCell>
              <TableCell>FINAL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {activeComponentConfiguration.components
              .filter(({ art }) => art)
              .map((component) => {
                return (
                  <TableRow key={component.id}>
                    <TableCell>{component.id}</TableCell>
                    <TableCell>{component.name}</TableCell>
                    <TableCell>{!!component.art?.artComplete && <Check />}</TableCell>
                    <TableCell>{!!component.art?.eProofApproved && <Check />}</TableCell>
                    <TableCell>{!!component.art?.whiteBoxApproved && <Check />}</TableCell>
                    <TableCell>{!!component.art?.digitalPrintApproved && <Check />}</TableCell>
                    <TableCell>{!!component.art?.finalSampleApproved && <Check />}</TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
