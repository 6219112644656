/**
 * @type {PalletConfiguration[]}
 */
const configurations = [
  {
    id: "48x40",
    idealFor: "North America (most common)",
    width: 1219,
    length: 1016,
    maxLoad: 2000,
    weight: 20,
    height: 150,
    maxHeight: 1270,
  },
  {
    id: "EUR1",
    idealFor: "Europe (most common)",
    width: 1200,
    length: 800,
    maxLoad: 1500,
    weight: 25,
    height: 144,
    maxHeight: 2200,
  },
  {
    id: "EUR2",
    idealFor: "UK",
    width: 1200,
    length: 1000,
    maxLoad: 1200,
    weight: 25,
    height: 144,
    maxHeight: 2200,
  },
  {
    id: "EUR2-Half",
    idealFor: "UK",
    width: 1200,
    length: 1000,
    maxLoad: 500,
    weight: 25,
    height: 144,
    maxHeight: 1200,
  },
  {
    id: "EUR2-Quarter",
    idealFor: "UK",
    width: 1200,
    length: 1000,
    maxLoad: 250,
    weight: 25,
    height: 144,
    maxHeight: 800,
  },
  {
    id: "AustralianStandardPallet",
    idealFor: "Australia",
    width: 1165,
    length: 1165,
    maxLoad: 2000,
    weight: 20,
    height: 150,
    maxHeight: 1800,
  },
];

/**
 * @param {PalletId} palletId
 * @param {number} cartonLength
 * @param {number} cartonWidth
 * @param {number} cartonHeight
 * @returns {number}
 */
export const cartonsPerPallot = (palletId, cartonLength, cartonWidth, cartonHeight) => {
  const configuration = configurations.find(({ id }) => id === palletId);
  if (!configuration) {
    return 0;
  }

  const cartonsWide = Math.floor(configuration.width / cartonWidth);
  const cartonsLong = Math.floor(configuration.length / cartonLength);
  const cartonsPerLayer = cartonsWide * cartonsLong;

  const layers = Math.floor((configuration.maxHeight - configuration.height) / cartonHeight);

  return layers * cartonsPerLayer;
};

/**
 * @param {PalletId} palletId
 * @param {number} cartonHeight
 * @returns {number} returns the height of the pallet in mm
 */
export const palletHeight = (palletId, cartonHeight) => {
  const configuration = configurations.find(({ id }) => id === palletId);
  if (!configuration) {
    return 0;
  }

  const layers = Math.floor((configuration.maxHeight - configuration.height) / cartonHeight);

  return layers * cartonHeight + configuration.height;
};

/**
 * @param {PalletId} palletId
 * @param {number} cartonLength
 * @param {number} cartonWidth
 * @param {number} cartonHeight
 * @returns {number}
 */
export const palletWeight = (palletId, cartonLength, cartonWidth, cartonHeight, cartonWeight) => {
  const configuration = configurations.find(({ id }) => id === palletId);
  if (!configuration) {
    return 0;
  }

  const cartonsWide = Math.floor(configuration.width / cartonWidth);
  const cartonsLong = Math.floor(configuration.length / cartonLength);
  const cartonsPerLayer = cartonsWide * cartonsLong;

  const layers = Math.floor((configuration.maxHeight - configuration.height) / cartonHeight);

  return layers * cartonsPerLayer * cartonWeight + configuration.weight;
};

export const unitsPerPallet = (unitsPerCarton, cartonsPerPallet) => unitsPerCarton * cartonsPerPallet;
