import React from "react";
import {
  Box,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineSeparator,
  timelineItemClasses,
} from "@mui/lab";
import { DateTime } from "luxon";
import { AddOutlined, EditOutlined } from "@mui/icons-material";

const skyboundActions = [
  { date: "20230923", notes: "Passed. Unable to progress game until 2025" },
  { date: "20230815", notes: "Still pending with legal." },
  { date: "20230716", notes: "Still pending with legal." },
  { date: "20230616", notes: "They want to sign." },
  { date: "20230615", notes: "Want to progress." },
  { date: "20230606", notes: "Wanted another link." },
  { date: "20230603", notes: "Follow up sent." },
  { date: "20230509", notes: "Sent screentop link." },
  { date: "20230506", notes: "Sent follow up email." },
  { date: "20230505", notes: "Pitched at UNPUB." },
];
export const PublisherDetail = () => (
  <Paper elevation={1} square={false}>
    <Stack direction="row">
      <Box p={2}>
        <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
          Skybound Interactive
        </Typography>
        <Typography variant="body1" textAlign="left" color="text.secondary">
          Tricia Victor & Ben Kepler
        </Typography>
      </Box>
      <Box p={2} flex={1}>
        <Typography variant="h5" textAlign="right" color="error.main">
          PASS
        </Typography>
      </Box>
    </Stack>
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          <TableCell align="left">Date</TableCell>
          <TableCell align="left">Detail</TableCell>
          <TableCell>
            <IconButton>
              <AddOutlined />
            </IconButton>
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {skyboundActions.map(({ date, notes }) => (
          <TableRow key={date}>
            <TableCell align="left">{DateTime.fromISO(date).toFormat("DD")}</TableCell>
            <TableCell align="left">{notes}</TableCell>
            <TableCell>
              <IconButton>
                <EditOutlined />
              </IconButton>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {skyboundActions.map(({ date, notes }, i) => (
        <TimelineItem key={date}>
          <TimelineSeparator>
            {i !== 0 && <TimelineConnector />}
            <TimelineDot color="primary" variant="filled"></TimelineDot>
            {i !== skyboundActions.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          <TimelineContent sx={{ py: "12px", px: 2 }}>
            <Typography variant="h6" component="span">
              {`${date} - STATUS`}
            </Typography>
            <Typography>{notes}</Typography>
          </TimelineContent>
        </TimelineItem>
      ))}
    </Timeline>
  </Paper>
);
