import React, { useContext } from "react";
import ProtectedRoute from "../App";
import { Stack } from "@mui/material";
import UserMenu from "./UserMenu";
import { AccountContext } from "./AccountProvider";
import { useNavigate } from "react-router-dom";

/**
 * @param {object} props
 * @property {React.JSX.Element|string} props.UnauthenticatedView
 * @property {React.JSX.Element} props.DesignerView
 * @property {React.JSX.Element|string} props.PublisherView
 */
export const AppWithSideMenu = ({
  children,
  UnauthenticatedView = null,
  DesignerView = null,
  PublisherView = null,
}) => {
  const { mode } = useContext(AccountContext);
  const navigate = useNavigate();
  const ViewToDisplay = mode === "Designer" ? DesignerView : PublisherView;
  if (typeof ViewToDisplay === "string") {
    navigate(ViewToDisplay);
    return;
  }

  return (
    <ProtectedRoute UnauthenticatedView={UnauthenticatedView}>
      <Stack direction="row">
        <UserMenu />
        <Stack direction="column" width="100%">
          <Stack direction="row">{(ViewToDisplay && <ViewToDisplay />) || children}</Stack>
        </Stack>
      </Stack>
    </ProtectedRoute>
  );
};
