export const ExchangeRates = {
  AUD2USD: [{ date: "2023-07-20", rate: 1.4751 }],
};
export const ExchangeRates2 = [
  { date: "2023-10-16", rate: 1.58, from: "USD", to: "AUD" },
  { date: "2023-10-16", rate: 0.82, from: "USD", to: "GBP" },
  { date: "2023-10-16", rate: 83.25, from: "USD", to: "INR" },
  { date: "2023-10-17", rate: 1.57, from: "USD", to: "AUD" },
  { date: "2023-10-17", rate: 0.81, from: "USD", to: "GBP" },
  { date: "2023-10-17", rate: 83.18, from: "USD", to: "INR" },
];
