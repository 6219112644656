import React, { useState } from "react";
import { ListDetail } from "../core/ListDetail";
import { useAuth0 } from "@auth0/auth0-react";
import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import { s2Token, s2GameId } from "../util/type-conversion";
import { fetchRulesForGame } from "../games/games-service";
import { RulesList } from "./RulesList";
import { RulesPreview } from "./RulesPreview";
import { Card } from "@mui/material";
import { AllRulesByGameId } from "../games/query-keys";

export const RulesListAndDetail = () => {
  const { gameId } = useParams();
  const { getAccessTokenSilently } = useAuth0();

  const [selected, setSelected] = useState("");

  const { data } = useQuery({
    queryKey: AllRulesByGameId(gameId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      const rules = await fetchRulesForGame(token, s2GameId(gameId || ""));
      rules.sort((a, b) => b.uploadedTimestamp - a.uploadedTimestamp);

      return rules;
    },
  });

  const SelectedRules = () => (
    <Card elevation={1} square={false}>
      <RulesPreview rules={(data || []).find((document) => document.id === selected)} />
    </Card>
  );
  const ListView = () => (
    <RulesList rules={data || []} selected={selected} onClick={setSelected} back={`/games/${gameId}`} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedRules} />;
};
