import React, { createContext, useCallback, useEffect, useMemo, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { createGame, fetchGame, fetchGames } from "../games/games-service";
import { s2Token, s2url } from "../util/type-conversion";
import { initialState } from "./initialState";

/**
 * @type {object}
 * @param {GameSummary[]} summaries
 * @param {Game[]} games
 */
const initialStateLocal = {
  summaries: [],
  games: initialState.games,
};

export const GameContext = createContext(initialStateLocal);

const GameProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  /**
   * @type {[Game[],React.Dispatch<React.SetStateAction<Game[]>>]}
   */
  const [games, setGames] = useState(initialStateLocal.games);
  /**
   * @type {[GameSummary[],React.Dispatch<React.SetStateAction<GameSummary[]>>]}
   */
  const [summaries, setSummaries] = useState(initialStateLocal.summaries);

  /**
   * @param {string} name
   * @param {string} tag
   * @param {DesignerGameStatus} status
   */
  const addGame = async (name, tag, status) => {
    const token = s2Token(await getAccessTokenSilently());
    const { game, summary } = await createGame(token, name, tag, status);
    setGames((current) => [...current, game]);
    setSummaries((current) => [...current, summary]);
  };

  const refreshGames = useCallback(async () => {
    if (!isAuthenticated) {
      return;
    }

    const token = s2Token(await getAccessTokenSilently());
    const games = await fetchGames(token);
    setSummaries(games);
  }, [isAuthenticated, getAccessTokenSilently]);

  /**
   * @param {GameId} gameId
   */
  const getGame = async (gameId) => {
    const game = games.find((game) => game.id === gameId);
    if (game) {
      return game;
    }

    const token = s2Token(await getAccessTokenSilently());
    const fetchedGame = await fetchGame(token, gameId);

    setGames((current) => [...current, fetchedGame]);

    return fetchedGame;
  };

  const addGameCallback = useCallback(addGame, [setGames, getAccessTokenSilently]);
  const getGameCallback = useCallback(getGame, [setGames, getAccessTokenSilently, games]);

  useEffect(() => {
    refreshGames();
  }, [refreshGames]);

  const value = useMemo(
    () => ({
      games,
      summaries,
      addGame: addGameCallback,
      getGame: getGameCallback,
    }),
    [games, summaries, addGameCallback, getGameCallback]
  );

  return <GameContext.Provider value={value}>{children}</GameContext.Provider>;
};

export default GameProvider;
