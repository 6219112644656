import React, { useEffect, useState } from "react";
import { ListDetail } from "../core/ListDetail";
import { usePublisherInterestInGame } from "./queries";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { PublisherInterestList } from "./PublisherInterestList";
import { PublisherInterestInGame } from "./PublisherInterestInGame";

const PublisherListFilters = [
  {
    /**
     * @param {DesignerPublisherGameView} game
     */
    filter: (game) => game.status === "PitchReceived",
    label: "NEW",
  },
  {
    /**
     * @param {DesignerPublisherGameView} game
     */
    filter: (game) => ["InProgress", "MaterialsRequested"].includes(game.status),
    label: "IN PROGRESS",
  },
  {
    /**
     * @param {DesignerPublisherGameView} game
     */
    filter: (game) => !!game,
    label: "ALL",
  },
  {
    /**
     * @param {DesignerPublisherGameView} game
     */
    filter: (game) => game.status === "Declined",
    label: "DECLINED",
  },
];

export const PublisherInterestListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data } = usePublisherInterestInGame(gameId);

  /**
   * @type {[DesignerPublisherGameView[],React.Dispatch<React.SetStateAction<DesignerPublisherGameView[]>>]}
   */
  const [filteredGames, setFilteredGames] = useState([]);
  const [filter, setFilter] = useState(PublisherListFilters[0].label);

  const onFilterClicked = (label) => {
    setFilter(label);
  };

  useEffect(() => {
    if (!data) {
      return;
    }
    if (data.length === 0) {
      return;
    }

    const appliedFilter = PublisherListFilters.find(({ label }) => label === filter);
    if (!appliedFilter) {
      return;
    }

    setFilteredGames(data.filter((game) => appliedFilter.filter(game)));
  }, [data, filter]);

  const SelectedGame = () => <PublisherInterestInGame />;
  const ListView = () => (
    <PublisherInterestList
      filter={filter}
      filters={PublisherListFilters}
      onFilterClicked={onFilterClicked}
      publishers={filteredGames}
    />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedGame} />;
};
