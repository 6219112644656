import React, { useContext } from "react";
import { Avatar, Divider, List, Link, ListItemButton, Stack, Paper } from "@mui/material";
import { Settings } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import LogoutButton from "../login/LogoutButton";
import { Path } from "../Path";
import { Link as RouterLink } from "react-router-dom";
import { fetchPublisher } from "../publisher/service";
import { s2Token } from "../util/type-conversion";
import { useQuery } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountContext } from "./AccountProvider";
import { PublisherByPublisherId } from "../games/query-keys";
import { ModeButton } from "./ModeButton";
import games from "../assets/games.png";
import { useUserProfile } from "../user/queries";

const PublisherHomeButton = () => {
  const location = useLocation();
  const { getAccessTokenSilently } = useAuth0();
  const { publisherId } = useContext(AccountContext);

  const { data } = useQuery({
    queryKey: PublisherByPublisherId(publisherId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      return fetchPublisher(token, publisherId);
    },
  });

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === "/"}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Home}
        aria-label="go publisher home"
        aria-controls="publisher home"
        sx={{ flexGrow: 1 }}
      >
        <Avatar src={data && data.logoUrl} />
      </Link>
    </ListItemButton>
  );
};

const UserHomeButton = () => {
  const { data } = useUserProfile();
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === "/games"}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Home}
        aria-label="go games"
        aria-controls="games"
        sx={{ flexGrow: 1 }}
      >
        <Avatar src={data ? data.pictureUrl : ""} />
      </Link>
    </ListItemButton>
  );
};

const MyGamesButton = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === "/games"}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.games()}
        aria-label="go games"
        aria-controls="games"
        sx={{ flexGrow: 1 }}
      >
        <img src={games} alt="Games Icon" style={{ width: "100%" }} />
      </Link>
    </ListItemButton>
  );
};

const UserSettingsButton = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      selected={location.pathname.startsWith(Path.Settings)}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.settings().none()}
        aria-label="go settings"
        aria-controls="settings"
        sx={{ flexGrow: 1 }}
      >
        <Settings />
      </Link>
    </ListItemButton>
  );
};

const PublisherSettingsButton = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      selected={location.pathname.startsWith(Path.Settings)}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Settings}
        aria-label="go to publisher settings"
        aria-controls="publisher settings"
        sx={{ flexGrow: 1 }}
      >
        <Settings />
      </Link>
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {PublisherId} props.publisherId
 */
const UserMenuBase = () => {
  const { mode } = useContext(AccountContext);

  return (
    <Paper style={{ height: "100%", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
      <Stack width={60} direction="column" p={0} m={0} justifyContent="space-between" display="flex">
        <Stack
          alignItems="center"
          spacing={2}
          pt={2}
          pb={2}
          sx={{
            mt: "auto",
            flexGrow: 1,
          }}
        >
          {mode === "Publisher" ? <PublisherHomeButton /> : <UserHomeButton />}
          {mode === "Designer" && <MyGamesButton />}
          <ModeButton />
          <List>{mode === "Publisher" ? <PublisherSettingsButton /> : <UserSettingsButton />}</List>
          <LogoutButton />
        </Stack>
      </Stack>
    </Paper>
  );
};

const UserMenu = () => <UserMenuBase />;

export default UserMenu;
