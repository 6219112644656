import React, { useCallback, useEffect, useState } from "react";
import { ListDetail } from "../core/ListDetail";
import { SubmissionsList } from "./SubmissionsList";
import { PublisherSubmissionsData } from "./PublisherSubmissionsData";
import { PublisherViewOfGameDetail } from "../games/PublisherViewOfGameDetail";

const initialFilter = "NEW";
const ListFilters = [
  {
    filter: (game) => ["PitchReceived"].includes(game.status),
    label: "NEW",
  },
  { filter: () => true, label: "IN REVIEW" },
  { filter: () => true, label: "MATERIALS REQ.D" },
  { filter: (game) => ["Signed", "Lost", "Declined"].includes(game.status), label: "EXITED" },
];

/**
 * @param {object} props
 * @param {PublisherGameSummary[]} props.submissions
 */
const SubmissionsListAndDetailBase = ({ submissions }) => {
  /**
   * @type {[PublisherGameSummary[],React.Dispatch<React.SetStateAction<PublisherGameSummary[]>>]}
   */
  const [filteredSubmissions, setFilteredSubmissions] = useState(submissions);
  const [filter, setFilter] = useState(initialFilter);

  const onFilterClicked = useCallback((label) => {
    setFilter(label);
  }, []);

  useEffect(() => {
    const appliedFilter = ListFilters.find(({ label }) => label === filter);
    if (!appliedFilter) {
      return;
    }

    const filtered = submissions.filter((game) => appliedFilter.filter(game));
    setFilteredSubmissions(filtered);
  }, [submissions, filter]);

  const SelectedGame = () => <PublisherViewOfGameDetail />;
  const ListView = () => (
    <SubmissionsList
      filter={filter}
      filters={ListFilters}
      onFilterClicked={onFilterClicked}
      submissions={filteredSubmissions}
    />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedGame} />;
};

export const SubmissionsListAndDetail = () => (
  <PublisherSubmissionsData>
    <SubmissionsListAndDetailBase submissions={[]} />
  </PublisherSubmissionsData>
);
