import React, { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import Profile from "@mui/icons-material/Person2";
import { LogoutOutlined } from "@mui/icons-material";

export const LogoutScreen = () => {
  const { logout } = useAuth0();

  logout({ logoutParams: { returnTo: window.location.origin } });

  return null;
};

const LogoutButton = () => {
  const { logout, user } = useAuth0();

  const onClickHandler = useCallback(() => logout({ logoutParams: { returnTo: window.location.origin } }), [logout]);

  return (
    <Button color="inherit" onClick={onClickHandler}>
      {user ? <LogoutOutlined /> : <Profile />}
    </Button>
  );
};

export default LogoutButton;
