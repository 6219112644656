import endpoints from "../endpoints";
import { get, optionsAuth, optionsNoAuth, post } from "../network";
import { s2url } from "../util/type-conversion";

/**
 * @typedef {Object} GameAndSummary
 * @property {Game} game
 * @property {GameSummary} summary
 */

/**
 * @param {TokenString} token
 * @param {string} name
 * @param {string} tag
 * @param {DesignerGameStatus} status
 * @returns {Promise<GameAndSummary>}
 */
export const createGame = async (token, name, tag, status) => {
  const { games } = endpoints();
  const url = games().create();

  /**
   * @type {CreateGameRequest}
   */
  const body = {
    name,
    tag,
    status,
  };

  const response = await post(url, body, optionsAuth(token));
  return response.data;
};

/**
 * @param {TokenString} token
 * @returns {Promise<GameSummary[]>}
 */
export const fetchGames = async (token) => {
  const { games } = endpoints();
  const url = games().get();

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {TokenString} token
 * @param {GameId} gameId
 * @returns {Promise<Game>}
 */
export const fetchGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().game(gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {GameId?} [gameId]
 * @returns {Promise<PublicGame|null>}
 */
export const fetchPublicGame = async (gameId) => {
  if (!gameId) {
    return null;
  }

  const { publicGames } = endpoints();
  const url = publicGames().game(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

/**
 * @param {TokenString} token
 * @param {GameId} gameId
 * @returns {Promise<Sellsheet[]>}
 */
export const fetchSellsheetsForGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().sellsheets(gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {TokenString} token
 * @param {GameId} gameId
 * @returns {Promise<Rules[]>}
 */
export const fetchRulesForGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().rules(gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {GameId} gameId
 * @returns {Promise<Sellsheet[]>}
 */
export const fetchLatestSellsheetForGame = async (gameId) => {
  const { publicGames } = endpoints();
  const url = publicGames().sellsheets(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

/**
 * @param {GameId} gameId
 * @returns {Promise<Rules[]>}
 */
export const fetchLatestRulesForGame = async (gameId) => {
  const { publicGames } = endpoints();
  const url = publicGames().rules(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

/**
 * @param {TokenString} token
 * @param {GameId} gameId
 * @returns {Promise<DesignerPublisherGameView[]>}
 */
export const fetchPublisherInterest = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().publisherInterest(gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {TokenString} token
 * @param {GameId} gameId
 * @param {string} name
 * @param {string} tag
 * @param {string} internalId
 * @param {Url} sizzle
 * @param {VisibilityTypes?} visibility
 * @property {string} data.playerCount
 * @property {string} data.duration
 * @property {number} data.age
 * @returns {Promise<Game>}
 */
export const updateGame = async (
  token,
  gameId,
  name = "",
  tag = "",
  internalId = "",
  sizzle = s2url(""),
  visibility = null,
  playerCount = "",
  duration = "",
  age = 0
) => {
  const { games } = endpoints();
  const url = games().game(gameId);

  /**
   * @type {UpdateGameRequest}
   */
  const body = {
    name,
    tag,
    internalId,
    sizzle,
    visibility,
    playerCount,
    duration,
    age,
  };

  const response = await post(url, body, optionsAuth(token));
  return response.data;
};
