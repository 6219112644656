import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchPublicGame } from "./games-service";
import { DefaultCacheTime, DefaultStaleTime } from "../core/react-query";
import { PublicGameByGameId } from "./query-keys";

/**
 * @param {object} props
 * @param {GameId?} [props.gameId]
 * @param {React.JSX.Element} props.children
 * @returns {React.JSX.Element}
 */
export const WithPublicGame = ({ gameId = null, children }) => {
  const { status, data, error } = useQuery({
    queryKey: PublicGameByGameId(gameId),
    queryFn: async () => {
      return fetchPublicGame(gameId);
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });

  return <>{React.cloneElement(children, { publicGame: data, publicGameStatus: status, publicGameError: error })}</>;
};
