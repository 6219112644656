import React from "react";
import LogoutButton from "../login/LogoutButton";
import { AppBar, Toolbar, Typography, Link } from "@mui/material";
import { AddOutlined } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

const TopBar = () => {
  return (
    <AppBar position="static">
      <Toolbar>
        <Link
          color="inherit"
          component={RouterLink}
          to="/"
          aria-label="go home"
          aria-controls="home"
          sx={{ flexGrow: 1 }}
        >
          <Typography variant="h6" component="p">
            HOME
          </Typography>
        </Link>
        <Link color="inherit" component={RouterLink} to="/add-game" aria-label="add game" aria-controls="add-game">
          <AddOutlined />
        </Link>
        <LogoutButton />
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
