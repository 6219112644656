import {
  Typography,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Avatar,
  AvatarGroup,
  Box,
  IconButton,
  Collapse,
  Link,
} from "@mui/material";
import React from "react";
import { DateTime } from "luxon";
import { ArrowForwardOutlined, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

const Row = ({ publisher, status, detail, nextAction, days, history }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {publisher}
        </TableCell>
        <TableCell align="center">{status}</TableCell>
        <TableCell align="right">{detail}</TableCell>
        <TableCell align="right">{nextAction}</TableCell>
        <TableCell align="right">{days}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                History
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {history.map((historyRow) => (
                    <TableRow key={historyRow.date}>
                      <TableCell component="th" scope="row">
                        {DateTime.fromISO(historyRow.date).toFormat("DD")}
                      </TableCell>
                      <TableCell>{historyRow.action}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const skyboundActions = [
  { date: "20230923", action: "Passed" },
  { date: "20230815", action: "Still pending with legal" },
  { date: "20230716", action: "Still pending with legal" },
  { date: "20230616", action: "They want to sign" },
  { date: "20230615", action: "Want to progress" },
  { date: "20230606", action: "Wanted another link" },
  { date: "20230603", action: "Follow up sent" },
  { date: "20230509", action: "Sent screentop link" },
  { date: "20230506", action: "Sent follow up email" },
  { date: "20230505", action: "Pitched at UNPUB" },
];

const Campaigns = () => {
  return (
    <Box pt={2}>
      <Card variant="elevation" sx={{ width: "100%" }}>
        <CardContent>
          <Typography variant="h6" color="text.secondary" gutterBottom>
            Submission Groups
          </Typography>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Name</TableCell>
                <TableCell>Code</TableCell>
                <TableCell>Submissions</TableCell>
                <TableCell>Ends</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>PAX 2023</TableCell>
                <TableCell>ALL-ASK-RAD</TableCell>
                <TableCell>2</TableCell>
                <TableCell>No Date</TableCell>
                <TableCell>
                  <Link color="inherit" component={RouterLink} to={`/submissions/campaigns/1`}>
                    <ArrowForwardOutlined />
                  </Link>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>UNPUB #3</TableCell>
                <TableCell>KIK-HAS-BAD</TableCell>
                <TableCell>12</TableCell>
                <TableCell>Closed</TableCell>
                <TableCell>
                  <Link color="inherit" component={RouterLink} to={`/submissions/campaigns/2`}>
                    <ArrowForwardOutlined />
                  </Link>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </Box>
  );
};

const ReceivedSubmissions = () => {
  return (
    <>
      <Box pt={2}>
        <Card variant="elevation" sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Submissions
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Designers</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Days In Status</TableCell>
                  <TableCell align="right">Assigned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Downhill Racer</TableCell>
                  <TableCell>Jeff Warrender</TableCell>
                  <TableCell>Digital Materials Received</TableCell>
                  <TableCell align="center">14</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>RB</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Cave Collect</TableCell>
                  <TableCell>Cole Smith</TableCell>
                  <TableCell>Digital Materials Received</TableCell>
                  <TableCell align="center">14</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>RB</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Monsoon Traders</TableCell>
                  <TableCell>Alexey Smirnov</TableCell>
                  <TableCell>Digital Materials Received</TableCell>
                  <TableCell align="center">13</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>RB</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

const Submissions = () => {
  return (
    <>
      <Box pt={2}>
        <Card variant="elevation" sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              My Pitches
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Designers</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Days In Status</TableCell>
                  <TableCell align="right">Assigned</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell>Little Succulents</TableCell>
                  <TableCell>Ryan Boucher & Jay Bendixen</TableCell>
                  <TableCell>Pending Contract</TableCell>
                  <TableCell align="center">90</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>TV</Avatar>
                      <Avatar>BK</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>One Trick Ponies</TableCell>
                  <TableCell>Ryan Boucher</TableCell>
                  <TableCell>Physical Materials Received</TableCell>
                  <TableCell align="center">31</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>CE</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>6 Months in a Leaky Boat</TableCell>
                  <TableCell>Ryan Boucher</TableCell>
                  <TableCell>Digital Materials Received</TableCell>
                  <TableCell align="center">29</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>LD</Avatar>
                      <Avatar>KC</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>Heist Dogs</TableCell>
                  <TableCell>Ryan Boucher</TableCell>
                  <TableCell>Digital Materials Received</TableCell>
                  <TableCell align="center">16</TableCell>
                  <TableCell align="right">
                    <AvatarGroup>
                      <Avatar>LW</Avatar>
                    </AvatarGroup>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
      <Box pt={2}>
        <Card variant="elevation" sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Little Succulents
            </Typography>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  <TableCell>Publisher</TableCell>
                  <TableCell align="center">Interest</TableCell>
                  <TableCell align="right">Detail</TableCell>
                  <TableCell align="right">Next Action</TableCell>
                  <TableCell align="right">Days</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <Row
                  publisher="Skybound Entertainment"
                  status="Yes"
                  detail="Pending Contract"
                  nextAction="Wait for contract"
                  days={DateTime.fromISO("20230616").diffNow("days").toFormat("d") * -1}
                  history={skyboundActions}
                />
                <Row
                  publisher="25th Century Games"
                  status="Maybe"
                  detail="Changes Required"
                  nextAction="Review changes requested"
                  days={DateTime.fromISO("20230906").diffNow("days").toFormat("d") * -1}
                  history={[]}
                />
                <Row
                  publisher="Hasbro"
                  status="No"
                  detail="Theme"
                  nextAction="-"
                  days={DateTime.fromISO("20230823").diffNow("days").toFormat("d") * -1}
                  history={[]}
                />
              </TableBody>
            </Table>
          </CardContent>
        </Card>
      </Box>
    </>
  );
};

export default Submissions;
