export const Path = {
  Home: "/",
  SelectAccount: "/select-account",
  settings: () => ({
    none: () => "/settings",
    category: (category) => `/settings/${category}`,
  }),
  Landing: {
    AddToSubmissionsBase: `/landing/add-to-submissions-logged-out`,
    AddToSubmissionsBaseLoggedIn: `/landing/add-to-submissions-logged-in`,
    /**
     * @param {GameId} gameId
     */
    AddToSubmissions: (gameId) => `/landing/add-to-submissions-logged-out?gameId=${gameId}`,
  },
  Publisher: {
    New: "/publisher/new",
  },
  user: () => ({
    /**
     * @param {GameId} gameId
     */
    game: (gameId) => `/games/${gameId}`,
    games: (gameId) => ({
      sellsheets: `/games/${gameId}/sellsheets`,
    }),
  }),
  games: () => `/games`,
  /**
   * @param {GameId} gameId
   */
  game: (gameId) => ({
    link: () => `/games/${gameId}`,
    sellsheets: () => `/games/${gameId}/sellsheets`,
    rules: () => `/games/${gameId}/rules`,
    publishers: () => `/games/${gameId}/publishers`,
    /**
     * @param {PublisherId} publisherId
     */
    publisher: (publisherId) => `/games/${gameId}/publishers/${publisherId}`,
  }),
};
