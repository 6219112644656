import React from "react";
import { s2GameId, s2PublisherId } from "../util/type-conversion";
import { useParams } from "react-router-dom";

/**
 * @returns {GameId}
 */
export const useGameIdFromRoute = () => {
  return s2GameId(useParams().gameId || "");
};

/**
 * @returns {PublisherId}
 */
export const usePublisherIdFromRoute = () => {
  return s2PublisherId(useParams().publisherId || "");
};

export const WithGameIdFromRoute = ({ children }) => {
  const gameId = s2GameId(useParams().gameId || "");

  return <>{React.cloneElement(children, { gameId })}</>;
};
