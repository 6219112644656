const CanCon = ["2024-01-26", "2024-01-27", "2024-01-28"];
const ToyFair = ["2024-02-11", "2024-02-12", "2024-02-13", "2024-02-14"];
const GameExpo = ["2024-03-23", "2024-03-24"];
const MeepleCon = ["2023-11-24", "2023-11-25", "2023-11-26"];
const Origins = ["2024-6-19", "2024-6-20", "2024-6-21", "2024-6-22", "2024-6-23"];
const GenCon = ["2024-08-01", "2024-08-02", "2024-08-03", "2024-08-04"];
const UkExpo = ["2024-05-31", "2024-06-01", "2024-06-02"];
const PaxAus = ["2024-10-11", "2024-10-12", "2024-10-13"];
const Essen = ["2024-10-03", "2024-10-04", "2024-10-05", "2024-10-06"];
const TableTopConQld = ["2024-05-25", "2024-05-26"];
const PlayCon = ["2024-07-12", "2024-07-13", "2024-07-14"];
const SundayNightLights = ["2023-11-13", "2023-11-27", "2023-12-11"];
const IncubatorOnline = ["2023-11-08", "2023-11-22", "2023-12-06", "2023-12-20"];

export const customCSSclasses = {
  holidays: [
    "2023-11-07",
    "2023-12-25",
    "2023-12-26",
    "2024-01-01",
    "2024-01-02",
    "2024-01-26",
    "2024-03-13",
    "2024-04-07",
    "2024-04-08",
    "2024-04-09",
    "2024-04-10",
    "2024-04-25",
    "2024-06-12",
    "2024-11-07",
    "2024-12-25",
    "2024-12-26",
    "2024-02-10",
    "2024-02-11",
    "2024-02-12",
    "2024-02-13",
    "2024-02-14",
    "2024-02-15",
    "2024-02-16",
    "2024-02-17",
    "2024-10-01",
    "2024-10-02",
    "2024-10-03",
    "2024-10-04",
    "2024-10-05",
    "2024-10-06",
    "2024-10-07",
  ],
  weekend: "Sat,Sun",
  awards: ["2023-12-01", "2024-01-31"],
  playtesting: [...SundayNightLights, ...IncubatorOnline],
  cons: [
    ...CanCon,
    ...ToyFair,
    ...MeepleCon,
    ...GameExpo,
    ...Origins,
    ...GenCon,
    ...UkExpo,
    ...PaxAus,
    ...Essen,
    ...TableTopConQld,
    ...PlayCon,
  ],
};
