import React, { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { SmartTextField } from "../games/SmartTextField";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token } from "../util/type-conversion";
import { enqueueSnackbar } from "notistack";

export const UpdatableNameTextField = ({ initialValue, label, helperText, updateFunction, queryKey, ...props }) => {
  const [saved, setSaved] = useState(initialValue);
  const [lastSavedValue, setLastSaved] = useState(initialValue);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newValue) => {
      const token = s2Token(await getAccessTokenSilently());

      const response = await updateFunction(token, newValue);
      return response;
    },
    onSuccess: (newValue) => {
      setLastSaved(newValue);
      enqueueSnackbar(`${label} saved to ${newValue}`, { variant: "success" });
      queryClient.invalidateQueries({ queryKey });
    },
  });

  const onChange = async (newValue) => {
    setSaved(newValue);
  };

  const saveChanges = () => {
    if (saved === lastSavedValue) {
      return;
    }

    mutation.mutate(saved);
  };

  const onKeyDownHandler = (ev) => {
    if (ev.key !== "Enter") {
      return;
    }

    saveChanges();
  };

  return (
    <SmartTextField
      label={label}
      helperText={helperText}
      initialValue={initialValue}
      onChange={onChange}
      onKeyDown={onKeyDownHandler}
      onBlur={saveChanges}
      {...props}
    />
  );
};
