import React, { useContext } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  TextareaAutosize,
} from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchContact } from "../users/users-service";
import { enqueueSnackbar } from "notistack";
import { DefaultCacheTime, DefaultStaleTime } from "../core/react-query";
import { DesignersByGameId } from "./query-keys";
import { EditableGameName } from "./EditableGameName";
import { EditableTag } from "./EditableTag";
import { PublisherLatestSizzle } from "../sizzle/LatestSizzle";
import { PublisherSellsheet } from "../sellsheets/PublicSellsheet";
import { PublisherPublicRules } from "../rules/PublicRules";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { usePublicGame } from "./queries";
import { AccountContext } from "../core/AccountProvider";
import { usePublisherGame } from "../publisher/queries";
import { formatAge, formatDuration, formatPlayerCount } from "./formatters";
import { getDaySubmitted } from "./formatting";
import { PublisherStatus } from "./PublisherStatus";
import { AddToSubmissionsButton } from "./AddToSubmissionsButton";

/**
 *
 * @param {object} props
 * @property {GameDesigner[]} props.designer
 */
const Designers = ({ gameId, designers }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data } = useQuery({
    queryKey: DesignersByGameId(gameId),
    queryFn: async () => {
      if (designers.length === 0) {
        return;
      }

      const token = s2Token(await getAccessTokenSilently());

      const all = Promise.all(
        designers.map(({ designerId }) => {
          return fetchContact(token, designerId);
        })
      );

      return await all;
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });

  if (!data) {
    return <p>no data</p>;
  }

  return (
    <ListItem>
      <ListItemText
        primary={data.length === 1 ? "Designer" : "Designers"}
        secondary={data.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}
      />
      <ListItemIcon>{/* <ArrowForwardOutlined /> */}</ListItemIcon>
    </ListItem>
  );
};

/**
 * @param {object} props
 * @param {PublicGame|null} props.data
 * @param {ReactQueryStatus} props.status
 * @param {Error|null} props.publicGameError
 */
export const PublisherViewOfGameDetail = () => {
  const gameId = useGameIdFromRoute();
  const { publisherId } = useContext(AccountContext);
  const publicGame = usePublicGame(gameId);
  const publisherGame = usePublisherGame(publisherId, gameId);

  if (!publicGame.status || !gameId) {
    return <EmptyDetailCard text="Select a game from the list on the left." />;
  }

  if (publicGame.status === "loading" || publisherGame.status === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (publicGame.status === "error" || !publicGame.data) {
    if (publicGame.error instanceof Error) {
      enqueueSnackbar("Error loading content", { variant: "error" });
    }

    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  if (!publisherGame.data) {
    return (
      <Box>
        <Box pb={1}>
          <Card elevation={1} square={false}>
            <Box p={1} style={{ display: "flex", justifyContent: "flex-end" }}>
              <AddToSubmissionsButton gameId={publicGame.data.id} />
            </Box>
          </Card>
        </Box>
        <Card elevation={1} square={false}>
          <Stack direction="row">
            <Box p={2}>
              <Box alignItems="flex-end" display="flex" flex="1" pb={1}></Box>
              <EditableGameName text={publicGame.data.name} disabled />
              <EditableTag text={publicGame.data.tag} disabled />
            </Box>
          </Stack>
          <List>
            <Stack direction="row">
              <ListItem>
                <ListItemText primary="Players" secondary={formatPlayerCount(publicGame.data.playerCount)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Duration" secondary={formatDuration(publicGame.data.duration)} />
              </ListItem>
              <ListItem>
                <ListItemText primary="Age" secondary={formatAge(publicGame.data.age)} />
              </ListItem>
            </Stack>
            <Designers gameId={publicGame.data.id} designers={publicGame.data.designers} />
            <PublisherLatestSizzle url={publicGame.data.sizzle} />
            <PublisherSellsheet url={publicGame.data.sellsheet} />
            <PublisherPublicRules url={publicGame.data.rules} />
          </List>
        </Card>
      </Box>
    );
  }

  return (
    <Box>
      <Box pb={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ display: "flex", justifyContent: "space-between" }}>
            <Button color="primary" variant="contained" disabled>
              REQUEST MATERIALS
            </Button>
            <Stack direction="row" spacing={1}>
              <Button color="success" variant="outlined" disabled>
                SIGN
              </Button>
              <Button color="error" variant="outlined" disabled>
                DECLINE
              </Button>
            </Stack>
          </Box>
        </Card>
      </Box>
      <Box pb={1}>
        <Card elevation={1} square={false}>
          <Box p={1} style={{ flexDirection: "column", display: "flex", justifyContent: "space-between" }}>
            <TextareaAutosize minRows={5} style={{ width: "100%" }} />
            <Box pt={1} style={{ width: "100%", justifyContent: "space-between", display: "flex" }}>
              <FormGroup>
                <FormControlLabel control={<Checkbox />} label="Share update with designer" />
              </FormGroup>
              <Button variant="contained">Add Comment</Button>
            </Box>
          </Box>
        </Card>
      </Box>
      <Card elevation={1} square={false}>
        <Stack direction="row">
          <Box p={2} display="flex" flexDirection="column" flex={1}>
            <Box justifyContent="space-between" display="flex" flex={1} pb={1}>
              <Chip label={getDaySubmitted(publisherGame.data?.dateSubmitted)} />
              <PublisherStatus status={publisherGame.data?.status} />
            </Box>
            <EditableGameName text={publicGame.data.name} disabled />
            <EditableTag text={publicGame.data.tag} disabled />
          </Box>
        </Stack>
        <List>
          <Stack direction="row">
            <ListItem>
              <ListItemText primary="Players" secondary={formatPlayerCount(publicGame.data.playerCount)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Duration" secondary={formatDuration(publicGame.data.duration)} />
            </ListItem>
            <ListItem>
              <ListItemText primary="Age" secondary={formatAge(publicGame.data.age)} />
            </ListItem>
          </Stack>
          <Designers gameId={publicGame.data.id} designers={publicGame.data.designers} />
          <PublisherLatestSizzle url={publicGame.data.sizzle} />
          <PublisherSellsheet url={publicGame.data.sellsheet} />
          <PublisherPublicRules url={publicGame.data.rules} />
        </List>
      </Card>
    </Box>
  );
};
