import React, { useEffect, useState } from "react";
import { ListDetail } from "../core/ListDetail";
import { GameDetailFromRoute } from "./GameDetail";
import { GamesList } from "./GamesList";
import { useQuery } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchGames } from "./games-service";
import { MyGamesAsSummaries } from "./query-keys";

const initialFilter = "PITCHING";
const GamesListFilters = [
  {
    filter: (game) => ["InDevelopmentEarly", "InDevelopmentMid", "InDevelopmentLate"].includes(game.status),
    label: "DESIGNING",
  },
  { filter: (game) => ["PitchingNow"].includes(game.status), label: "PITCHING" },
  { filter: (game) => ["Signed", "Published"].includes(game.status), label: "SIGNED" },
  { filter: (game) => ["Idea", "Abandoned"].includes(game.status), label: "OTHER" },
];

export const GamesListAndDetail = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { data = [] } = useQuery({
    queryKey: MyGamesAsSummaries(),
    queryFn: async () => {
      const games = await fetchGames(s2Token(await getAccessTokenSilently()));

      return games;
    },
  });

  /**
   * @type {[GameSummary[],React.Dispatch<React.SetStateAction<GameSummary[]>>]}
   */
  const [filteredGames, setFilteredGames] = useState([]);
  const [filter, setFilter] = useState(initialFilter);

  const onFilterClicked = (label) => {
    setFilter(label);
  };

  useEffect(() => {
    if (data.length === 0) {
      return;
    }

    const appliedFilter = GamesListFilters.find(({ label }) => label === filter);
    if (!appliedFilter) {
      return;
    }

    setFilteredGames(data.filter((game) => appliedFilter.filter(game)));
  }, [data, filter]);

  const SelectedGame = () => <GameDetailFromRoute />;
  const ListView = () => (
    <GamesList filter={filter} filters={GamesListFilters} onFilterClicked={onFilterClicked} summaries={filteredGames} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedGame} />;
};
