import { DateTime } from "luxon";

/**
 * @param {PublisherGameStatus?} [status]
 * @returns {string}
 */
export const formatPublisherStatus = (status) => {
  if (status === "PitchReceived") {
    return "NEW";
  }
  if (status === "Signed") {
    return "SIGNED";
  }
  if (status === "Declined") {
    return "DECLINED";
  }
  if (status === "Lost") {
    return "LOST";
  }

  return "UNKNOWN";
};
/**
 * @param {PublisherGameStatus?} [status]
 * @returns {"error"|"success"|"default"|"warning"|"info"|"primary"|"secondary"}
 */
export const formatPublisherStatusColour = (status) => {
  if (status === "PitchReceived") {
    return "info";
  }
  if (status === "Signed") {
    return "success";
  }
  if (status === "Declined") {
    return "warning";
  }
  if (status === "Lost") {
    return "error";
  }

  return "default";
};

/**
 * @param {number} timestampSubmitted
 * @returns {string}
 */
export const getDaySubmitted = (timestampSubmitted = DateTime.now().toMillis()) => {
  const days = DateTime.now().diff(DateTime.fromMillis(timestampSubmitted), "days").days;
  const daysRounded = Math.floor(days);

  return `Submitted: ${daysRounded === 1 ? "1 day ago" : `${daysRounded} days ago`}`;
};

/**
 * @param {number} timestamp
 * @returns {number}
 */
export const getDaysAgo = (timestamp = DateTime.now()) => {
  const days = DateTime.now().diff(DateTime.fromMillis(timestamp), "days").days;
  const daysRounded = Math.floor(days);

  return daysRounded;
};

/**
 * @param {number} timestamp
 * @returns {string}
 */
export const formatDaysAgo = (timestamp = DateTime.now()) => {
  const days = getDaysAgo(timestamp);

  return `${days === 1 ? "1 day ago" : `${days} days ago`}`;
};

/**
 * @param {number} timestamp
 * @returns {string}
 */
export const formatEventDate = (timestamp = DateTime.now()) => {
  return `${DateTime.fromMillis(timestamp).toFormat("DD")} (${formatDaysAgo(timestamp)})`;
};

/**
 * @param {number} timestamp
 * @returns {string}
 */
export const formatEventDateShort = (timestamp = DateTime.now()) => {
  return `${DateTime.fromMillis(timestamp).toFormat("DD")}`;
};
