import React, { useContext, useEffect, useState } from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { DesignerGameStatusHelperText, DesignerGameStatusToLabel } from "./model";
import { GameContext } from "../providers/GameProvider";
import { SmartTextField } from "./SmartTextField";
import { FullScreenDialog } from "../core/FullScreenDialog";

const GameNameTextField = ({ onChange }) => {
  return (
    <SmartTextField required label="Game Name" helperText="A good pitch starts with a good name." onChange={onChange} />
  );
};

const GameTagTextField = ({ onChange }) => {
  return <SmartTextField required label="Tag Line" helperText="Your game is 25 words or less." onChange={onChange} />;
};

export default function AddGameFullScreenDialog() {
  const { addGame } = useContext(GameContext);

  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [status, setStatus] = useState("Idea");
  const [canSave, setCanSave] = useState(false);

  const statusOnChangeHandler = (ev) => setStatus(ev.target.value);

  const onSaveHandler = async () => {
    addGame(name, tag, status);
  };

  useEffect(() => {
    setCanSave(!(!!name && !!tag));
  }, [name, tag]);

  return (
    <FullScreenDialog title="Add Game" actionText="save" onSaveHandler={onSaveHandler} canSave={canSave}>
      <GameNameTextField onChange={setName} />
      <GameTagTextField onChange={setTag} />
      <FormControl sx={{ width: "100%" }} margin="normal">
        <InputLabel id="game-status">Status</InputLabel>
        <Select
          labelId="game-status"
          value={status}
          onChange={statusOnChangeHandler}
          placeholder="status"
          label="Status"
          input={<OutlinedInput label="Status" />}
        >
          <MenuItem value="Idea">{DesignerGameStatusToLabel.Idea}</MenuItem>
          <MenuItem value="InDevelopmentEarly">{DesignerGameStatusToLabel.InDevelopmentEarly}</MenuItem>
          <MenuItem value="InDevelopmentMid">{DesignerGameStatusToLabel.InDevelopmentMid}</MenuItem>
          <MenuItem value="InDevelopmentLate">{DesignerGameStatusToLabel.InDevelopmentLate}</MenuItem>
          <MenuItem value="PitchingNow">{DesignerGameStatusToLabel.PitchingNow}</MenuItem>
          <MenuItem value="Signed">{DesignerGameStatusToLabel.Signed}</MenuItem>
          <MenuItem value="Published">{DesignerGameStatusToLabel.Published}</MenuItem>
        </Select>
        <FormHelperText>{DesignerGameStatusHelperText[status] || ""}</FormHelperText>
      </FormControl>
    </FullScreenDialog>
  );
}
