import React from "react";
import { ListView } from "../core/ListView";
import { ListItemButton, ListItemText } from "@mui/material";

/**
 *
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.category
 * @param {string} props.helperText
 * @param {boolean} props.selected
 * @param {Function} props.onClick
 */
const SettingsCategory = ({ id, category, helperText, selected, onClick }) => {
  const onClickHandler = () => {
    onClick(id);
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={category} secondary={helperText} />
    </ListItemButton>
  );
};

export const DesignerSettingsList = ({ onListItemSelected, selectedListItemId }) => {
  return (
    <ListView title="Settings" Filters={null} Action={null}>
      <SettingsCategory
        category="About You"
        id="about-you"
        helperText="Adjust your name and other settings about you."
        selected={selectedListItemId === "about-you"}
        onClick={onListItemSelected}
      />
      <SettingsCategory
        category="Location"
        id="your-location"
        helperText="Set your location and timezone."
        selected={selectedListItemId === "your-location"}
        onClick={onListItemSelected}
      />
    </ListView>
  );
};

export const PublisherSettingsList = ({ onListItemSelected, selectedListItemId }) => {
  return (
    <ListView title="Publisher Settings" Filters={null} Action={null}>
      <SettingsCategory
        category="Publisher Info"
        id="about-publisher"
        helperText="Adjust your company information."
        selected={selectedListItemId === "about-publisher"}
        onClick={onListItemSelected}
      />
      <SettingsCategory
        category="Contact Information"
        id="contact"
        helperText="Preferred method of contact."
        selected={selectedListItemId === "contact"}
        onClick={onListItemSelected}
      />
    </ListView>
  );
};
