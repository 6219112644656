import {
  MenuItem,
  Select,
  Typography,
  Card,
  CardContent,
  OutlinedInput,
  InputLabel,
  FormControl,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { useParams } from "react-router-dom";
import numeral from "numeral";
import { ExchangeRates2 } from "../fx/rates";

/**
 * @param {object} from
 * @param {number} from.amount
 * @param {SupportedCurrencies} from.currency
 * @param {SupportedCurrencies} toCurrencyCode
 * @returns {number}
 */
const toCurrency = (from, toCurrencyCode) => {
  if (from.currency === toCurrencyCode) {
    return from.amount;
  }

  const todayRates = ExchangeRates2.filter((rate) => rate.date === "2023-10-16");
  const rate = todayRates.find((rate) => rate.from === from.currency && rate.to === toCurrencyCode);
  if (!rate) {
    const reverseRate = todayRates.find((rate) => rate.to === from.currency && rate.from === toCurrencyCode);
    if (!reverseRate) {
      return 0;
    }

    return from.amount / reverseRate.rate;
  }

  return from.amount * rate.rate;
};

const ConsolidatorDiscount = 1 - 0.67;
const DistributorDiscount = 1 - 0.6;
const RetailerDiscount = 1 - 0.5;
const AmazonDiscount = 1 - 0.12;
const TGDANonMemberDiscount = 1 - 0.12;
const KickstarterDiscount = 1 - 0.1;
const TGDAMemberDiscount = 1 - 0.08;

export const FinancialModelling = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  const amounts = {
    msrpAUD: game.editions[0].msrp.find((msrp) => msrp.currency === "AUD")?.amount,
    msrpUSD: game.editions[0].msrp.find((msrp) => msrp.currency === "USD")?.amount,
    msrpGBP: game.editions[0].msrp.find((msrp) => msrp.currency === "GBP")?.amount,
    msrpINR: game.editions[0].msrp.find((msrp) => msrp.currency === "INR")?.amount,
    unitPrice: game.quotes.find((quote) => quote.accepted)?.costPerUnit2,
    freightEstimateAUS: game.printRuns[0].shipments[0].quotes.find(({ accepted }) => accepted)?.amount,
    freightEstimateUS: game.printRuns[0].shipments[1].quotes.find(({ accepted }) => accepted)?.amount,
    freightEstimateUK: game.printRuns[0].shipments[2].quotes.find(({ accepted }) => accepted)?.amount,
    freightEstimateIN: game.printRuns[0].shipments[3].quotes.find(({ accepted }) => accepted)?.amount,
    unitsAUS: game.printRuns[0].shipments[0].units,
    unitsUS: game.printRuns[0].shipments[1].units,
    unitsUK: game.printRuns[0].shipments[2].units,
    unitsIN: game.printRuns[0].shipments[3].units,
    royalties: game.designers.reduce((t, designer) => t + designer.royalty, 0),
    unitPriceLTT: 10.18,
    freightEstimateLTT: 1761.68,
    unitsLTT: 501,
  };

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Financial Modelling
        </Typography>
        <FormControl sx={{ width: "100%" }} margin="normal">
          <InputLabel id="game-status">Components</InputLabel>
          <Select
            labelId="game-status"
            value={game.components.find((component) => component.status === "Active")?.id}
            onChange={() => null}
            placeholder="components"
            label="Components"
            input={<OutlinedInput label="Components" />}
          >
            {game.components.map((component) => (
              <MenuItem key={component.id} value={component.id}>
                {component.configuationName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "100%" }} margin="normal">
          <InputLabel id="game-status">Manufacturing Quote</InputLabel>
          <Select
            labelId="game-status"
            value={game.quotes.find((quote) => quote.accepted)?.id}
            onChange={() => null}
            placeholder="quote"
            label="Manufacturing Quote"
            input={<OutlinedInput label="Manufacturing Quote" />}
          >
            {game.quotes.map((quote) => (
              <MenuItem key={quote.id} value={quote.id}>
                {quote.quantity}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              {game.printRuns[0].shipments.map((destination) => (
                <TableCell align="right">{destination.to.valueOf()}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>MSRP</TableCell>
              <TableCell align="right">${amounts.msrpAUD}</TableCell>
              <TableCell align="right">${amounts.msrpUSD}</TableCell>
              <TableCell align="right">£{amounts.msrpGBP}</TableCell>
              <TableCell align="right">₹{amounts.msrpINR}</TableCell>
              <TableCell align="right">${amounts.msrpUSD}</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>Units</TableCell>
              {game.printRuns[0].shipments.map((destination) => (
                <TableCell align="right">{destination.units}</TableCell>
              ))}
            </TableRow> */}
            <TableRow>
              <TableCell>Royalties</TableCell>
              {game.printRuns[0].shipments.map(() => (
                <TableCell align="right">{numeral(amounts.royalties).format("%")}</TableCell>
              ))}
            </TableRow>
            <TableRow>
              <TableCell>Unit Price</TableCell>
              <TableCell align="right">${numeral(toCurrency(amounts.unitPrice, "AUD")).format("0.00")}</TableCell>
              <TableCell align="right">${numeral(toCurrency(amounts.unitPrice, "USD")).format("0.00")}</TableCell>
              <TableCell align="right">£{numeral(toCurrency(amounts.unitPrice, "GBP")).format("0.00")}</TableCell>
              <TableCell align="right">₹{numeral(toCurrency(amounts.unitPrice, "INR")).format("0.00")}</TableCell>
              <TableCell align="right">
                ${numeral(toCurrency({ amount: amounts.unitPriceLTT, currency: "USD" }, "USD")).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Freight Estimate</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.freightEstimateAUS / amounts.unitsAUS,
                      currency: "USD",
                    },
                    "AUD"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.freightEstimateUS / amounts.unitsUS,
                      currency: "USD",
                    },
                    "USD"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.freightEstimateUK / amounts.unitsUK,
                      currency: "USD",
                    },
                    "GBP"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                ₹
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.freightEstimateIN / amounts.unitsIN,
                      currency: "USD",
                    },
                    "INR"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.freightEstimateLTT / amounts.unitsLTT,
                      currency: "USD",
                    },
                    "USD"
                  )
                ).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Landed Price</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                      currency: "USD",
                    },
                    "AUD"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                      currency: "USD",
                    },
                    "USD"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.unitPrice.amount + amounts.freightEstimateUK / amounts.unitsUK,
                      currency: "USD",
                    },
                    "GBP"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                ₹
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.unitPrice.amount + amounts.freightEstimateIN / amounts.unitsIN,
                      currency: "USD",
                    },
                    "INR"
                  )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  toCurrency(
                    {
                      amount: amounts.unitPriceLTT + amounts.freightEstimateLTT / amounts.unitsLTT,
                      currency: "USD",
                    },
                    "USD"
                  )
                ).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Direct Margin</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  amounts.msrpGBP * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUK / amounts.unitsUK,
                        currency: "USD",
                      },
                      "GBP"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                ₹
                {numeral(
                  amounts.msrpINR * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateIN / amounts.unitsIN,
                        currency: "USD",
                      },
                      "INR"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPriceLTT + amounts.freightEstimateLTT / amounts.unitsLTT,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TGDA Margin (Member)</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * TGDAMemberDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Kickstarter Margin</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * KickstarterDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * KickstarterDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  amounts.msrpGBP * KickstarterDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUK / amounts.unitsUK,
                        currency: "USD",
                      },
                      "GBP"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                ₹
                {numeral(
                  amounts.msrpINR * KickstarterDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateIN / amounts.unitsIN,
                        currency: "USD",
                      },
                      "INR"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * KickstarterDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPriceLTT + amounts.freightEstimateLTT / amounts.unitsLTT,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Amazon Margin</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * AmazonDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * AmazonDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  amounts.msrpGBP * AmazonDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUK / amounts.unitsUK,
                        currency: "USD",
                      },
                      "GBP"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                ₹
                {numeral(
                  amounts.msrpINR * AmazonDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateIN / amounts.unitsIN,
                        currency: "USD",
                      },
                      "INR"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * AmazonDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPriceLTT + amounts.freightEstimateLTT / amounts.unitsLTT,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>TGDA Margin (Non-Member)</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * TGDANonMemberDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Retailer Margin</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * RetailerDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * RetailerDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                £
                {numeral(
                  amounts.msrpGBP * RetailerDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUK / amounts.unitsUK,
                        currency: "USD",
                      },
                      "GBP"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Distributor Margin</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpAUD * DistributorDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateAUS / amounts.unitsAUS,
                        currency: "USD",
                      },
                      "AUD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * DistributorDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            <TableRow>
              <TableCell>Consolidator Margin</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">
                $
                {numeral(
                  amounts.msrpUSD * ConsolidatorDiscount * (1 - amounts.royalties) -
                    toCurrency(
                      {
                        amount: amounts.unitPrice.amount + amounts.freightEstimateUS / amounts.unitsUS,
                        currency: "USD",
                      },
                      "USD"
                    )
                ).format("0.00")}
              </TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
              <TableCell align="right">-</TableCell>
            </TableRow>
            {/* <TableRow>
              <TableCell>Kickstarter Fee</TableCell>
              {game.printRuns[0].shipments.map(() => (
                <TableCell>{numeral(3).format("$0.00")}</TableCell>
              ))}
            </TableRow> */}
            {/* <TableRow>
              <TableCell>Taxes</TableCell>
              {game.printRuns[0].shipments.map(() => (
                <TableCell>(from country)</TableCell>
              ))}
            </TableRow> */}
            {/* <TableRow>
              <TableCell>Fixed Costs</TableCell>
              <TableCell>{numeral(8000 / 1000).format("$0.00")}</TableCell>
              <TableCell>{numeral(8000 / 1000).format("$0.00")}</TableCell>
              <TableCell>{numeral(8000 / 1000).format("$0.00")}</TableCell>
            </TableRow> */}
            {/* <TableRow>
              <TableCell>Estimated Revenue</TableCell>
              {game.printRuns[0].shipments.map(() => (
                <TableCell align="right">(calculation)</TableCell>
              ))}
            </TableRow> */}
            {/* <TableRow>
              <TableCell>Financial Position</TableCell>
              {game.printRuns[0].shipments.map(() => (
                <TableCell>(calculation)</TableCell>
              ))}
            </TableRow> */}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
