import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { useParams } from "react-router-dom";
import { DirectionsBoatOutlined, FlightTakeoffOutlined, RocketLaunchOutlined, Star } from "@mui/icons-material";
import numeral from "numeral";
import { DateTime } from "luxon";

/**
 * @type {Record<FreightMethods, object>}
 */
const Icons = {
  Ocean: DirectionsBoatOutlined,
  Air: FlightTakeoffOutlined,
  Express: RocketLaunchOutlined,
};

export const FreightQuotes = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Freight Quotes
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Destination</TableCell>
              <TableCell align="right">Quote Date</TableCell>
              <TableCell align="right">Cost / Unit</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Days</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {game.printRuns[0].shipments.map((destination) => {
              return destination.quotes.map((quote) => {
                const Icon = Icons[quote.method];
                return (
                  <TableRow>
                    <TableCell align="left">{quote.accepted && <Star />}</TableCell>
                    <TableCell align="right">{destination.to}</TableCell>
                    <TableCell align="right">{DateTime.fromMillis(quote.timestamp).toFormat("dd LLL y")}</TableCell>
                    <TableCell align="right">
                      {numeral(quote.amount / destination.units).format("$0,000.00")} USD
                    </TableCell>
                    <TableCell align="right">{numeral(quote.amount).format("$0,000.00")} USD</TableCell>
                    <TableCell align="right">{`${quote.minDays} - ${quote.maxDays}`}</TableCell>
                    <TableCell align="right">
                      <Icon />
                    </TableCell>
                    <TableCell align="right">
                      {DateTime.now().toMillis() > quote.expiry ? "EXPIRED" : "VALID"}
                    </TableCell>
                  </TableRow>
                );
              });
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
