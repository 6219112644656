import React, { useContext } from "react";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";
import { useAuth0 } from "@auth0/auth0-react";
import { AccountContext } from "../core/AccountProvider";
import { addGameToPublisher } from "../publisher/service";
import { s2Token } from "../util/type-conversion";
import { useQueryClient } from "@tanstack/react-query";
import { AddIsInQueue } from "./AddIsInQueue";
import { PublisherSubmissionsByPublisherId } from "./query-keys";

/**
 * @param {object} props
 * @param {GameId} props.gameId
 * @param {boolean} props.isInQueue
 */
const AddToSubmissionsButtonBase = ({ gameId, isInQueue }) => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();
  const navigate = useNavigate();
  const { mode, publisherId } = useContext(AccountContext);

  const queryClient = useQueryClient();

  const onClickHandler = async () => {
    if (!isAuthenticated) {
      navigate(Path.Landing.AddToSubmissions(gameId));
      return;
    }
    if (mode !== "Publisher" || !publisherId) {
      navigate(Path.Landing.AddToSubmissions(gameId));
      return;
    }
    if (isInQueue) {
      return;
    }

    const token = s2Token(await getAccessTokenSilently());
    await addGameToPublisher(token, publisherId, gameId);
    queryClient.invalidateQueries({ queryKey: PublisherSubmissionsByPublisherId(publisherId) });
  };

  return (
    <Button variant="contained" onClick={onClickHandler}>
      {isInQueue ? "View Game In Queue" : "Add to Submissions"}
    </Button>
  );
};

export const AddToSubmissionsButton = ({ gameId }) => (
  <AddIsInQueue gameId={gameId}>
    <AddToSubmissionsButtonBase gameId={gameId} isInQueue={false} />
  </AddIsInQueue>
);
