import { Grid, Box } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { useParams } from "react-router-dom";
import { TimelineSimple } from "../timeline/TimelineSimple";
import { FinancialModelling } from "../financials/FinancialModelling";
import { BudgetSimple } from "../financials/BudgetSimple";
import { FreightQuotes } from "../freight/FreightQuotes";
import { Shipments } from "../freight/Shipments";
import { ManufacturingQuotes } from "../concept/manufacturing/ManufacturingQuotes";
import { ComponentConfiguration } from "../concept/manufacturing/ComponentConfiguration";
import { Designers } from "../designers/GameDesignerOverview";
import { GameOverview } from "./GameOverview";
import { ArtReadyness } from "../concept/manufacturing/ArtReadyness";

const GameView = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return;
  }

  return (
    <Grid container columns={1} justifyContent="center" alignItems="center" pt={2}>
      <Box>
        <Box>
          <GameOverview />
        </Box>
        <Box pt={2}>
          <FinancialModelling />
        </Box>
        <Box pt={2}>
          <Shipments />
        </Box>
        <Box pt={2}>
          <FreightQuotes />
        </Box>
        <Box pt={2}>
          <Designers />
        </Box>
        <Box pt={2}>
          <ComponentConfiguration />
        </Box>
        <Box pt={2}>
          <ArtReadyness />
        </Box>
        <Box pt={2}>
          <ManufacturingQuotes />
        </Box>
        <Box pt={2}>
          <BudgetSimple />
        </Box>
        <Box pt={2}>
          <TimelineSimple />
        </Box>
      </Box>
    </Grid>
  );
};

export default GameView;
