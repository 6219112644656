import React from "react";
import { Typography } from "@mui/material";

export const publicGameName = "h3";
export const sectionHeading = "h4";

export const ItemHeading = ({ children }) => (
  <Typography sx={{ fontFamily: "'aktiv-grotesk', sans-serif", fontWeight: 700, fontStyle: "normal" }}>
    {children}
  </Typography>
);

export const SectionHeading = ({ children }) => (
  <Typography
    variant={sectionHeading}
    textAlign="left"
    color="text.secondary"
    noWrap
    sx={{ fontFamily: "'aktiv-grotesk', sans-serif", fontStyle: "normal" }}
  >
    {children}
  </Typography>
);

export const GameNameHeading = ({ children }) => (
  <Typography
    variant={publicGameName}
    textAlign="left"
    noWrap
    sx={{ fontFamily: "'aktiv-grotesk', sans-serif", fontWeight: 700, fontStyle: "normal" }}
  >
    {children}
  </Typography>
);
