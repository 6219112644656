import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/roboto-condensed/400.css";
import "@fontsource/roboto-condensed/700.css";
import AddGame from "./concept/AddGame";
import reportWebVitals from "./reportWebVitals";
import GameProvider from "./providers/GameProvider";
import { CssBaseline, ThemeProvider } from "@mui/material";
import "./index.css";
import { GamesListAndDetail } from "./games/GamesListAndDetail";
import ConfigProvider from "./config/ConfigProvider";
import UsersProvider from "./users/UsersProvider";
import { Settings } from "./settings/Settings";
import { Path } from "./Path";
import { SellsheetListAndDetail } from "./sellsheets/SellsheetListAndDetail";
import { RulesListAndDetail } from "./rules/RulesListAndDetail";
import { pdfjs } from "react-pdf";
import "./calendar/style.css";
import { PublicGameDetailFromRoute } from "./games/PublicGameDetail";
import { AddToSubmissionsLandingPage } from "./landing-pages/add-to-submissions";
import { CreateNewPublisher } from "./publisher/CreateNewPublisher";
import { SinglePageView } from "./core/SinglePageView";
import { s2GameId, s2PublisherId } from "./util/type-conversion";
import { SubmissionsListAndDetail } from "./publisher/SubmissionsListAndDetail";
import { PublisherSettings } from "./publisher/PublisherSettings";
import { AddToSubmissionsLandingPageLoggedIn } from "./landing-pages/add-to-submissions-logged-in";
import AccountProvider from "./core/AccountProvider";
import { AppWithSideMenu } from "./core/AppWithSideMenu";
import { AppFrame } from "./AppFrame";
import { PublisherInterestListAndDetail } from "./games/PublisherInterestListAndDetail";
import { conceptRoutes } from "./conceptRoutes";
import { theme } from "./theme";
import { ErrorPage } from "./ErrorPage";
import { LogoutScreen } from "./login/LogoutButton";
import { SelectAccountPage } from "./core/Switcher";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const queryClient = new QueryClient();

const doesntSupportPublisherView = [
  {
    path: "/games/:gameId/sellsheets",
    element: (
      <AppWithSideMenu>
        <SellsheetListAndDetail />
      </AppWithSideMenu>
    ),
  },
  {
    path: "/games/:gameId/rules",
    element: (
      <AppWithSideMenu>
        <RulesListAndDetail />
      </AppWithSideMenu>
    ),
  },
  {
    path: Path.Landing.AddToSubmissionsBase,
    element: <AddToSubmissionsLandingPage />,
  },
  {
    path: Path.Landing.AddToSubmissionsBaseLoggedIn,
    element: (
      <AppWithSideMenu>
        <AddToSubmissionsLandingPageLoggedIn />
      </AppWithSideMenu>
    ),
  },
  {
    path: Path.Publisher.New,
    element: (
      <SinglePageView>
        <CreateNewPublisher />
      </SinglePageView>
    ),
  },
  {
    path: "/contacts",
    element: <AppWithSideMenu>{/* <ContactListWithDetail /> */}</AppWithSideMenu>,
  },
  {
    path: "/me/add-game",
    element: (
      <AppWithSideMenu>
        <AddGame />
      </AppWithSideMenu>
    ),
  },
];

const router = createBrowserRouter([
  {
    path: "/",
    element: <AppWithSideMenu DesignerView={GamesListAndDetail} PublisherView={SubmissionsListAndDetail} />,
  },
  {
    path: Path.SelectAccount,
    element: <SelectAccountPage />,
  },
  {
    path: Path.Error,
    element: <ErrorPage />,
  },
  {
    path: Path.Logout,
    element: <LogoutScreen />,
  },
  {
    path: "/games",
    element: <AppWithSideMenu DesignerView={GamesListAndDetail} />,
  },
  {
    path: Path.settings().none(),
    element: <AppWithSideMenu DesignerView={Settings} PublisherView={PublisherSettings} />,
  },
  {
    path: Path.settings().category(":category"),
    element: <AppWithSideMenu DesignerView={Settings} PublisherView={PublisherSettings} />,
  },
  {
    path: Path.game(s2GameId(":gameId")).link(),
    element: (
      <AppWithSideMenu
        DesignerView={GamesListAndDetail}
        PublisherView={SubmissionsListAndDetail}
        UnauthenticatedView={PublicGameDetailFromRoute}
      />
    ),
  },
  {
    path: Path.game(s2GameId(":gameId")).publishers(),
    element: (
      <AppWithSideMenu
        DesignerView={PublisherInterestListAndDetail}
        PublisherView={Path.Home}
        UnauthenticatedView={Path.Home}
      />
    ),
  },
  {
    path: Path.game(s2GameId(":gameId")).publisher(s2PublisherId(":publisherId")),
    element: (
      <AppWithSideMenu
        DesignerView={PublisherInterestListAndDetail}
        PublisherView={Path.Home}
        UnauthenticatedView={Path.Home}
      />
    ),
  },
  ...doesntSupportPublisherView,
  ...conceptRoutes,
]);

// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Auth0Provider
      domain="pitch2table.au.auth0.com"
      clientId="b4b4nckDFscWQkApsWPSxc7RrhbiPJG5"
      authorizationParams={{
        audience: "https://secure.pitch2table.com",
        redirect_uri: window.location.origin,
      }}
    >
      <ThemeProvider theme={theme}>
        <QueryClientProvider client={queryClient}>
          <SnackbarProvider>
            <ConfigProvider>
              <UsersProvider>
                <AccountProvider>
                  <GameProvider>
                    <CssBaseline />
                    <AppFrame>
                      <RouterProvider router={router} />
                    </AppFrame>
                  </GameProvider>
                </AccountProvider>
              </UsersProvider>
            </ConfigProvider>
          </SnackbarProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </Auth0Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
