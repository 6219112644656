import React from "react";
import { Box, Link, List, Paper, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { ArrowBack } from "@mui/icons-material";

/**
 * @param {object} props
 * @property {string} props.title
 * @property {React.JSX.Element|null} props.children
 * @property {React.JSX.Element|null} props.Filters
 * @property {React.JSX.Element|null} props.Action
 * @property {boolean} props.back
 * @returns
 */
export const ListView = ({ title, children, Filters = null, Action = null, back = false }) => {
  return (
    <Paper elevation={1} square={false}>
      <Stack direction="row" justifyContent="center">
        <Box flex={1} pt={1} justifyContent="center" alignItems="center" display="flex">
          {back && (
            <Link to={back} component={RouterLink} color="inherit">
              <ArrowBack />
            </Link>
          )}
        </Box>
        <Typography variant="h4" textAlign="center" p={2}>
          {title}
        </Typography>
        <Box flex={1} pt={2}>
          {Action && <Action />}
        </Box>
      </Stack>
      <Stack direction="row" spacing={1} justifyContent="center" p={2}>
        {Filters && <Filters />}
      </Stack>
      <List>{children}</List>
    </Paper>
  );
};
