import React from "react";
import { Box, Card, CircularProgress, Grid, List, Stack, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useParams } from "react-router-dom";
import { enqueueSnackbar } from "notistack";
import { PublicSellsheet } from "../sellsheets/PublicSellsheet";
import { SinglePageView } from "../core/SinglePageView";
import { LatestSizzle } from "../sizzle/LatestSizzle";
import { PublicRules } from "../rules/PublicRules";
import { ItemHeading } from "../core/font-sizes";
import { AddToSubmissionsButton } from "./AddToSubmissionsButton";
import { ImmutableGameTag } from "./ImmutableGameTag";
import { Designers } from "./Designers";
import { s2GameId } from "../util/type-conversion";
import { WithPublicGame } from "./WithPublicGame";
import { PublicImmutableGameName } from "./EditableGameName";
import { formatAge, formatDuration, formatPlayerCount } from "./formatters";

const KeyStat = ({ title, value }) => {
  return (
    <Box
      m={1}
      p={1}
      sx={{ border: 1, borderColor: "black", borderStyle: "solid", borderRadius: 1 }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
    >
      <ItemHeading>{title}</ItemHeading>
      <Typography fontSize={24}>{value}</Typography>
    </Box>
  );
};

/**
 * @param {object} props
 * @param {PublicGame|null} props.publicGame
 * @param {ReactQueryStatus} props.publicGameStatus
 * @param {Error|null} props.publicGameError
 */
export const PublicGameDetail = ({ publicGame, publicGameStatus, publicGameError }) => {
  if (!publicGame) {
    return <EmptyDetailCard text="Game not found." />;
  }

  if (publicGameStatus === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (publicGameStatus === "error" || !publicGame) {
    if (publicGameError instanceof Error) {
      enqueueSnackbar("Error loading content", { variant: "error" });
    }

    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <Stack direction="row" justifyContent="flex-end">
        <Box p={2}>
          <AddToSubmissionsButton gameId={publicGame.id} />
        </Box>
      </Stack>
      <Stack direction="row" justifyContent="space-between">
        <Box p={2}>
          <PublicImmutableGameName text={publicGame.name} />
          <ImmutableGameTag text={publicGame.tag} />
          <Designers designers={publicGame.designers} />
        </Box>
        <Stack p={1} direction="column" flex="0.5">
          <KeyStat title="Players" value={formatPlayerCount(publicGame.playerCount)} />
          <KeyStat title="Duration" value={formatDuration(publicGame.duration)} />
          <KeyStat title="Age" value={formatAge(publicGame.age)} />
        </Stack>
      </Stack>
      <List>
        <LatestSizzle url={publicGame.sizzle} />
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <PublicSellsheet url={publicGame.sellsheet} />
          </Grid>
          <Grid item xs={6}>
            <PublicRules url={publicGame.rules} />
          </Grid>
        </Grid>
      </List>
      <Stack direction="row" justifyContent="flex-end">
        <Box p={2}>
          <AddToSubmissionsButton gameId={publicGame.id} />
        </Box>
      </Stack>
    </Card>
  );
};

export const PublicGameDetailFromRoute = () => {
  const gameId = s2GameId(useParams().gameId || "");

  return (
    <SinglePageView>
      <WithPublicGame gameId={gameId}>
        <PublicGameDetail publicGame={null} publicGameError={null} publicGameStatus="loading" />
      </WithPublicGame>
    </SinglePageView>
  );
};
