import {
  ListSubheader,
  MenuItem,
  Select,
  TextField,
  Typography,
  Button,
  Card,
  CardActions,
  Grid,
  CardContent,
  OutlinedInput,
  InputLabel,
  FormControl,
  FormHelperText,
  styled,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { GameContext } from "../providers/GameProvider";
import { useNavigate } from "react-router-dom";
import { HelperText, StatusToLabel } from "../games/model";

const Frame = styled("div")(({ theme }) => ({
  width: "100%",
  [theme.breakpoints.up("md")]: {
    width: "75%",
  },
}));

//A game of trust, being the most valuable player and an octopus in disguise.

const AddGame = () => {
  const { addGame } = useContext(GameContext);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [status, setStatus] = useState("Idea");
  const [isValid, setValidStatus] = useState(false);

  const nameOnChangeHandler = (ev) => {
    setName(ev.target.value);
  };
  const tagOnChangeHandler = (ev) => {
    setTag(ev.target.value);
  };
  const statusOnChangeHandler = (ev) => setStatus(ev.target.value);

  const validate = useCallback(() => {
    if (name.length === 0) {
      setValidStatus(false);
      return false;
    }
    if (tag.length === 0) {
      setValidStatus(false);
      return false;
    }

    setValidStatus(true);
    return true;
  }, [setValidStatus, name, tag]);

  useEffect(() => {
    validate();
  }, [name, tag, validate]);

  const submitFormHandler = async (ev) => {
    ev.preventDefault();

    addGame(name, tag, status);
    navigate("/");
  };

  return (
    <Grid container columns={1} justifyContent="center" alignItems="center" p={8}>
      <Frame>
        <Card variant="elevation" sx={{ width: "100%" }}>
          <CardContent>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Game Essentials
            </Typography>
            <TextField required fullWidth type="text" label="Game Name" onChange={nameOnChangeHandler} margin="dense" />
            <TextField
              required
              multiline
              fullWidth
              type="text"
              label="Tag Line"
              onChange={tagOnChangeHandler}
              margin="normal"
            />
            <FormControl sx={{ width: "100%" }} margin="normal">
              <InputLabel id="game-status">Status</InputLabel>
              <Select
                labelId="game-status"
                value={status}
                onChange={statusOnChangeHandler}
                placeholder="status"
                label="Status"
                input={<OutlinedInput label="Status" />}
              >
                <ListSubheader>New</ListSubheader>
                <MenuItem value="Idea">&nbsp;&gt;&nbsp;{StatusToLabel.Idea}</MenuItem>
                <MenuItem value="InDevelopmentEarly">&nbsp;&gt;&nbsp;{StatusToLabel.InDevelopmentEarly}</MenuItem>
                <MenuItem value="InDevelopmentMid">&nbsp;&gt;&nbsp;{StatusToLabel.InDevelopmentMid}</MenuItem>
                <MenuItem value="InDevelopmentLate">&nbsp;&gt;&nbsp;{StatusToLabel.InDevelopmentLate}</MenuItem>
                <ListSubheader>Goal: Pitch</ListSubheader>
                <MenuItem value="PitchPreparation">&nbsp;&gt;&nbsp;{StatusToLabel.PitchPreparation}</MenuItem>
                <MenuItem value="PitchingNow">&nbsp;&gt;&nbsp;{StatusToLabel.PitchingNow}</MenuItem>
                <MenuItem value="Signed">&nbsp;&gt;&nbsp;{StatusToLabel.Signed}</MenuItem>
                <ListSubheader>Goal: Publish</ListSubheader>
                <MenuItem value="DesignComplete">&nbsp;&gt;&nbsp;{StatusToLabel.DesignComplete}</MenuItem>
                <MenuItem value="InProduction">&nbsp;&gt;&nbsp;{StatusToLabel.InProduction}</MenuItem>
                <ListSubheader>Completed</ListSubheader>
                <MenuItem value="Released">&nbsp;&gt;&nbsp;{StatusToLabel.Released}</MenuItem>
                <MenuItem value="Abandoned">&nbsp;&gt;&nbsp;{StatusToLabel.Abandoned}</MenuItem>
              </Select>
              <FormHelperText>{HelperText[status] || ""}</FormHelperText>
            </FormControl>
          </CardContent>
          <CardActions>
            <Button onClick={submitFormHandler} disabled={!isValid}>
              Add Game
            </Button>
          </CardActions>
        </Card>
      </Frame>
    </Grid>
  );
};

export default AddGame;
