import { DateTime } from "luxon";

/**
 * @typedef {object} GameContext
 * @property {Game[]} games
 * @property {Function} addGame
 * @property {Function} updateId
 * @property {Function} updateName
 * @property {Function} updateTag
 * @property {Function} updateStatus
 */

/**
 * @param {string} name
 * @param {string} tag
 * @param {DesignerGameStatus} status
 * @param {number} year
 * @param {string} id
 * @returns {Game}
 */
export const makeGame = (name, tag, status, year, id) => ({
  id: name,
  name,
  archived: false,
  public: false,
  briefDescription: tag,
  internalId: id,
  year,
  components: [],
  quotes: [],
  status,
  editions: [],
  budgets: [],
  designers: [],
  printRuns: [],
  awards: [],
});

/**
 * @type {GameContext}
 */
export const initialState = {
  games: [
    {
      id: "2309128390123",
      name: "Little Succulents",
      archived: false,
      public: false,
      briefDescription: "A game about personal plant growth.",
      internalId: "2022#31",
      year: 2022,
      status: "PitchingNow",
      printRuns: [],
      designers: [
        { designerId: "distributedlife", royalty: 0.05 },
        { designerId: "little-briefcase", royalty: 0.05 },
      ],
      budgets: [],
      editions: [],
      quotes: [],
      components: [],
      awards: [],
    },
    {
      id: "2398473289472",
      name: "Ten Wickets",
      archived: false,
      public: false,
      briefDescription: "The two-player auction cricket game where every auction you win scores for the other player.",
      internalId: "2019-08",
      year: 2019,
      status: "Signed",
      printRuns: [
        {
          name: "First",
          cartonMarks: [
            "Ten Wickets",
            "12 PCS/CTN",
            "Carton Size: 30x28.5x20CM",
            "Carton Weight : KG",
            "Made in China",
          ],
          shipments: [
            {
              units: 352,
              pallet: "EUR2-Quarter",
              to: "AUS",
              lessThanContainerLoad: true,
              quotes: [
                {
                  amount: 803.4,
                  currency: "USD",
                  price: {
                    amount: 803.4,
                    currency: "USD",
                  },
                  accepted: true,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
                  id: "loose-units",
                  minDays: 32,
                  maxDays: 58,
                  timestamp: DateTime.fromISO("2023-10-24").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 617,
                  currency: "USD",
                  price: {
                    amount: 617,
                    currency: "USD",
                  },
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
                  id: "freight-os-ch-robinson",
                  minDays: 27,
                  maxDays: 32,
                  timestamp: DateTime.fromISO("2023-09-25").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 780,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
                  id: "freight-os-loose-cartons",
                  minDays: 32,
                  maxDays: 58,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 906.8,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
                  id: "freight-os-best-value",
                  minDays: 34,
                  maxDays: 50,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 3689,
                  currency: "USD",
                  accepted: false,
                  method: "Express",
                  expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
                  id: "freight-os-quickest",
                  minDays: 2,
                  maxDays: 5,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 1111.52,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
                  id: "freight-os-ocean-2",
                  minDays: 27,
                  maxDays: 38,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 1826,
                  currency: "USD",
                  accepted: false,
                  method: "Air",
                  expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
                  id: "freight-os-air",
                  minDays: 7,
                  maxDays: 10,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
              ],
            },
            {
              to: "US",
              units: 120,
              pallet: "48x40",
              lessThanContainerLoad: true,
              quotes: [
                {
                  amount: 362.05,
                  currency: "USD",
                  accepted: true,
                  method: "Express",
                  expiry: DateTime.fromISO("2023-10-31").endOf("day").toMillis(),
                  id: "freight-os-cheapest",
                  minDays: 15,
                  maxDays: 25,
                  timestamp: DateTime.fromISO("2023-10-24").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 432.65,
                  currency: "USD",
                  accepted: false,
                  method: "Air",
                  expiry: DateTime.fromISO("2023-10-30").endOf("day").toMillis(),
                  id: "freight-air-cheapest",
                  minDays: 4,
                  maxDays: 8,
                  timestamp: DateTime.fromISO("2023-10-17").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 309.9,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-10-10").endOf("day").toMillis(),
                  id: "freight-os-cheapest",
                  minDays: 15,
                  maxDays: 25,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
              ],
            },
            {
              to: "UK",
              units: 336,
              pallet: "EUR2-Quarter",
              lessThanContainerLoad: true,
              quotes: [
                {
                  amount: 600.93,
                  currency: "USD",
                  accepted: true,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-11-15").endOf("day").toMillis(),
                  id: "freight-os-cheapest-2",
                  minDays: 42,
                  maxDays: 51,
                  timestamp: DateTime.fromISO("2023-10-24").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 565.96,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-10-11").endOf("day").toMillis(),
                  id: "freight-os-cheapest",
                  minDays: 42,
                  maxDays: 51,
                  timestamp: DateTime.fromISO("2023-09-11").toMillis(),
                  company: "Freightos",
                },
                {
                  amount: 526.14,
                  currency: "USD",
                  accepted: false,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-09-22").endOf("day").toMillis(),
                  id: "freight-os-direct",
                  minDays: 42,
                  maxDays: 51,
                  timestamp: DateTime.fromISO("2023-10-11").toMillis(),
                  company: "Freightos",
                },
              ],
            },
            {
              to: "AmznIndia",
              units: 192,
              pallet: "EUR2-Quarter",
              lessThanContainerLoad: true,
              quotes: [
                {
                  amount: 617,
                  currency: "USD",
                  price: {
                    amount: 617,
                    currency: "USD",
                  },
                  accepted: true,
                  method: "Ocean",
                  expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
                  id: "to-validate",
                  minDays: 27,
                  maxDays: 32,
                  timestamp: DateTime.fromISO("2023-09-25").toMillis(),
                  company: "Freightos",
                },
              ],
            },
            {
              to: "LTT-AMZN",
              units: 500,
              pallet: "EUR2-Quarter",
              lessThanContainerLoad: true,
              quotes: [
                {
                  amount: 363,
                  currency: "USD",
                  price: {
                    amount: 363,
                    currency: "USD",
                  },
                  accepted: true,
                  method: "Air",
                  expiry: DateTime.fromISO("2023-09-30").endOf("day").toMillis(),
                  id: "to-validate",
                  minDays: 15,
                  maxDays: 20,
                  timestamp: DateTime.fromISO("2023-09-25").toMillis(),
                  company: "LLT",
                },
              ],
            },
          ],
          manufacturingQuotes: [],
        },
      ],
      designers: [{ designerId: "distributedlife", royalty: 0.1 }],
      budgets: [
        {
          id: "abcd",
          name: "Small Box Budget",
          items: [
            { amount: 5000, currency: "AUD", name: "Art" },
            { amount: 3000, currency: "AUD", name: "Marketing" },
          ],
        },
      ],
      editions: [
        {
          name: "First Edition",
          status: "InProduction",
          sku: "SBSCR01",
          budgetId: "abcd",
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          msrp: [
            { amount: 29.99, currency: "AUD" },
            { amount: 14.99, currency: "GBP" },
            { amount: 19.99, currency: "USD" },
            { amount: 19.99, currency: "EUR" },
            { amount: 999, currency: "INR" },
          ],
          quoteId: "2023-07-20-Whatz-1000",
          timeline: [
            { name: "FinalsToManufacturer", timestamp: DateTime.fromISO("2023-08-21").toMillis(), customName: null },
            { name: "MassProductionApproval", timestamp: DateTime.fromISO("2023-09-05").toMillis(), customName: null },
            { name: "MassProductionCompleted", timestamp: null, customName: null },
            { name: "ReadyForShipment", timestamp: null, customName: null },
            { name: "ShipDeparted", timestamp: null, customName: null },
            { name: "ShipArrived", timestamp: null, customName: null },
            { name: "CustomsCompleted", timestamp: null, customName: null },
            { name: "ArrivedAtWarehouse", timestamp: null, customName: null },
            { name: "ReadyForDispatch", timestamp: null, customName: null },
          ],
        },
      ],
      quotes: [
        {
          id: "2023-07-20-Whatz-1000-original-100-sheets",
          quantity: 1000,
          terms: "EXW",
          accepted: false,
          costPerUnit: 3.41,
          currency: "USD",
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "4524c3c3-eee3-451f-a92d-045ca2d35349",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-1000-no-tarot-no-tray",
          quantity: 1000,
          terms: "EXW",
          accepted: false,
          costPerUnit: 2.82,
          currency: "USD",
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "ab18b65e-8968-473e-8e29-a479817515c0",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-1000",
          quantity: 1000,
          terms: "EXW",
          accepted: false,
          costPerUnit: 3.05,
          currency: "USD",
          costPerUnit2: {
            currency: "USD",
            amount: 3.05,
          },
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-1000-after-discount",
          quantity: 1000,
          terms: "EXW",
          accepted: true,
          costPerUnit: 3.05 - 0.3,
          currency: "USD",
          costPerUnit2: {
            currency: "USD",
            amount: 3.05 - 0.3,
          },
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-2000",
          quantity: 2000,
          terms: "EXW",
          costPerUnit: 2.47,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-3000",
          quantity: 3000,
          terms: "EXW",
          costPerUnit: 2.26,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          printerId: "WhatzGames",
        },
        {
          id: "2023-07-20-Whatz-5000",
          quantity: 5000,
          terms: "EXW",
          costPerUnit: 2.09,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1705708800000,
          componentConfigurationId: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          printerId: "WhatzGames",
        },
        {
          id: "LTT(51-100)",
          quantity: 100,
          terms: "EXW",
          costPerUnit: 17.33,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "ltt-base",
          printerId: "LTT",
        },
        {
          id: "LTT (101-300)",
          quantity: 300,
          terms: "EXW",
          costPerUnit: 14.83,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "ltt-base",
          printerId: "LTT",
        },
        {
          id: "LTT (301-500)",
          quantity: 500,
          terms: "EXW",
          costPerUnit: 12.49,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "ltt-base",
          printerId: "LTT",
        },
        {
          id: "LTT (501-999)",
          quantity: 999,
          terms: "EXW",
          costPerUnit: 9.14,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "ltt-base",
          printerId: "LTT",
        },
        {
          id: "Custom - 50",
          quantity: 50,
          terms: "EXW",
          costPerUnit: 22.59,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
          printerId: "LTT+Custom",
        },
        {
          id: "Custom - 100",
          quantity: 100,
          terms: "EXW",
          costPerUnit: 21.33,
          currency: "USD",
          accepted: false,
          timestamp: 1689811200000,
          expiry: 1689811200000,
          componentConfigurationId: "all-LTT-ex-pad-ex-rules-office-box",
          printerId: "LTT+Custom",
        },
      ],
      components: [
        {
          id: "1af1ef69-a452-4c61-b3c8-08c69c11ce75",
          configuationName: "50 sheets, double-sided",
          components: [
            {
              id: "W01",
              quantity: 1,
              name: "Retail Box",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 0,
              },
            },
            {
              id: "W02",
              quantity: 1,
              name: "Tray",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 0,
              },
            },
            {
              id: "W03",
              quantity: 1,
              name: "Rules",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: DateTime.fromISO("2023-09-05").toMillis(),
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: DateTime.fromISO("2023-09-05").toMillis(),
                finalSampleApproved: 0,
              },
            },
            {
              id: "W04",
              quantity: 50,
              name: "Note Pad",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 0,
              },
            },
            {
              id: "W05",
              quantity: 55,
              name: "Cards",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 0,
              },
            },
            {
              id: "W06",
              quantity: 29,
              name: "Cards",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 0,
              },
            },
            {
              id: "W07",
              quantity: 6,
              name: "Poker Chips",
              art: {
                artComplete: 1,
                finalsSubmitted: 1,
                eProofApproved: 1,
                whiteBoxApproved: 1693825490000,
                digitalPrintApproved: 1693825490000,
                finalSampleApproved: 1693825490000,
              },
            },
            {
              id: "W08",
              quantity: 1,
              name: "Assembly & Carton",
              art: null,
            },
          ],
          actualWeightInGrams: 463,
          estimatedWeightInGrams: 500,
          cartonSize: 12,
          cartonDimensions: {
            length: 290,
            width: 285,
            height: 200,
            units: "mm",
          },
          status: "Active",
        },
        {
          id: "ltt-base",
          configuationName: "All LTT",
          components: [
            {
              id: "LTT01",
              quantity: 1,
              name: "LTT Medium Box",
              art: null,
            },
            {
              id: "LTT02",
              quantity: 1,
              name: "Rules",
              art: null,
            },
            {
              id: "LTT03",
              quantity: 50,
              name: "Note Pad",
              art: null,
            },
            {
              id: "LTT04",
              quantity: 54,
              name: "Euro Cards",
              art: null,
            },
            {
              id: "LTT05",
              quantity: 29,
              name: "Tarot Cards",
              art: null,
            },
            {
              id: "LTT06",
              quantity: 6,
              name: "Poker Chips",
              art: null,
            },
          ],
          actualWeightInGrams: 0,
          estimatedWeightInGrams: 500,
          cartonSize: 0,
          cartonDimensions: null,
          status: "Archived",
        },
        {
          id: "4524c3c3-eee3-451f-a92d-045ca2d35349",
          configuationName: "100 sheets, single-sided",
          components: [
            { id: "W01", quantity: 1, name: "Retail Box", art: null },
            { id: "W02", quantity: 1, name: "Tray", art: null },
            { id: "W03", quantity: 1, name: "Rules", art: null },
            { id: "W04", quantity: 100, name: "Note Pad", art: null },
            { id: "W05", quantity: 55, name: "Cards", art: null },
            { id: "W06", quantity: 29, name: "Cards", art: null },
            { id: "W07", quantity: 6, name: "Poker Chips", art: null },
            { id: "W08", quantity: 1, name: "Assembly & Carton", art: null },
          ],
          actualWeightInGrams: null,
          estimatedWeightInGrams: 500,
          cartonSize: 12,
          cartonDimensions: null,
          status: "Archived",
        },
        {
          id: "ab18b65e-8968-473e-8e29-a479817515c0",
          configuationName: "no tarot, no tray",
          components: [
            { id: "W01", quantity: 1, name: "Retail Box", art: null },
            { id: "W03", quantity: 1, name: "Rules", art: null },
            { id: "W04", quantity: 50, name: "Note Pad", art: null },
            { id: "W05", quantity: 84, name: "Cards", art: null },
            { id: "W07", quantity: 6, name: "Poker Chips", art: null },
            { id: "W08", quantity: 1, name: "Assembly & Carton", art: null },
          ],
          actualWeightInGrams: null,
          estimatedWeightInGrams: 500,
          cartonSize: 12,
          cartonDimensions: null,
          status: "Archived",
        },
        {
          id: "all-LTT-ex-pad-ex-rules-office-box",
          configuationName: "LTT, ex-pad, rules. Officeworks Box",
          components: [
            { id: "W01", quantity: 1, name: "Retail Box", art: null },
            { id: "W03", quantity: 1, name: "Rules", art: null },
            { id: "W04", quantity: 50, name: "Note Pad", art: null },
            { id: "W05", quantity: 84, name: "Cards", art: null },
            { id: "W07", quantity: 6, name: "Poker Chips", art: null },
            { id: "W08", quantity: 1, name: "Assembly & Carton", art: null },
          ],
          actualWeightInGrams: null,
          estimatedWeightInGrams: 500,
          cartonSize: 1,
          cartonDimensions: null,
          status: "Archived",
        },
      ],
      awards: [],
    },
    makeGame(
      "Big Vase Throw Down",
      "Wager on who can throw this biggest vase in this pottery dice chucker",
      "InDevelopmentEarly",
      2023,
      "2023#51"
    ),
    makeGame(
      "One Trick Ponies",
      "Win tricks with ponies who are trying to win only one trick.",
      "PitchingNow",
      2023,
      "2023#17"
    ),
    makeGame(
      "Six Months on a Leaky Boat",
      "A game of trust, being the most valuable player and an octopus in disguise.",
      "PitchingNow",
      2023,
      "2023#24"
    ),
    makeGame("Heist Dogs", "A heist, two rivals and so many bones.", "PitchingNow", 2023, "2023#36"),
    makeGame("Biscuit Business", "Build your biscuit empire or what it crumble.", "Idea", 2023, "2023#53"),
    makeGame("Red Gold", "Cut trees, make money.", "Idea", 2023, "2023#54"),
    {
      awards: [],
      id: "29347238942",
      name: "18 Holes",
      archived: false,
      public: false,
      briefDescription: "Hit off-course on purpose in this strategy golf game for 1-5 players.",
      internalId: "2017-13",
      year: 2017,
      printRuns: [],
      designers: [{ designerId: "distributedlife", royalty: 0.1 }],
      budgets: [],
      status: "Published",
      editions: [
        {
          name: "First Edition",
          status: "Released",
          sku: "SBS1801",
          budgetId: null,
          componentConfigurationId: null,
          msrp: [
            { amount: 79.99, currency: "AUD" },
            { amount: 44.99, currency: "GBP" },
            { amount: 54.99, currency: "USD" },
            { amount: 49.99, currency: "EUR" },
          ],
          quoteId: null,
          timeline: [],
        },
        {
          name: "Second Edition",
          status: "Released",
          sku: "SBS1811",
          budgetId: null,
          componentConfigurationId: null,
          msrp: [
            { amount: 90, currency: "AUD" },
            { amount: 59.99, currency: "USD" },
            { amount: 54.99, currency: "EUR" },
            { amount: 49.99, currency: "GBP" },
          ],
          quoteId: null,
          timeline: [],
        },
      ],
      quotes: [],
      components: [],
    },
    makeGame("Invasion Earth", "", "InDevelopmentEarly", 2010, "2010#01"),
    makeGame("T20 Dice Cricket™", "", "InDevelopmentEarly", 2018, "2018#11"),
    makeGame("18 Holes™ - Putting + Links Expansion", "", "Published", 2019, "2019#05"),
    makeGame("Flotsam Falls™", "", "Signed", 2020, "2020#20"),
    makeGame("18 Holes™ - Course Architect", "", "Published", 2021, "2021#01"),
    makeGame("Synchronised Diving in Space", "", "InDevelopmentMid", 2021, "2021#05"),
    makeGame("Competitive Baby Settling", "", "InDevelopmentEarly", 2021, "2021#11"),
    makeGame("Parks™ - The Board Game", "", "InDevelopmentEarly", 2021, "2021#17"),
    makeGame("Studio Pottery", "", "InDevelopmentEarly", 2021, "2021#20"),
    makeGame("A Year on the Farm", "", "Idea", 2021, "2021#23"),
    makeGame("Gondola Gophers", "", "InDevelopmentMid", 2021, "2021#24"),
    makeGame("Victory Lap™ - Team Principal", "", "InDevelopmentEarly", 2022, "2022#13"),
    makeGame("Factory Dice", "", "Idea", 2022, "2022#20"),
    makeGame("Ten Art Now / Cat Burglars", "", "InDevelopmentEarly", 2022, "2022#26"),
    makeGame("Dishes for Dignitaries", "", "InDevelopmentLate", 2022, "2022#29"),
    makeGame("Three Little Pigs", "", "InDevelopmentEarly", 2022, "2022#36"),
    makeGame("Dice Rush!", "", "InDevelopmentEarly", 2023, "2023#06"),
    makeGame("Mop Squad", "", "Idea", 2023, "2023#12"),
    makeGame("Hermit Crab Game", "", "Idea", 2023, "2023#16"),
    makeGame("Old Dogs", "", "InDevelopmentEarly", 2023, "2023#18"),
    makeGame("Truck Taker", "", "InDevelopmentEarly", 2023, "2023#20"),
    makeGame("Poké", "", "InDevelopmentEarly", 2023, "2023#21"),
    makeGame("Abandoned Chips", "", "InDevelopmentMid", 2023, "2023#34"),
    makeGame("Redundancy", "", "Idea", 2023, "2023#37"),
    makeGame("18 Holes - PNP", "", "Idea", 2023, "2023#39"),
    makeGame("Penalty Shootout-2", "", "Idea", 2023, "2023#40"),
    makeGame("Showbags", "", "Idea", 2023, "2023#42"),
    makeGame("Raku", "", "InDevelopmentEarly", 2023, "2023#43"),
    makeGame("Production Potters", "", "Idea", 2023, "2023#44"),
    makeGame("Village Market", "", "Idea", 2023, "2023#45"),
    makeGame("X is better than Y", "", "Idea", 2023, "2023#46"),
    makeGame("Greenlit", "", "Idea", 2023, "2023#47"),
    makeGame("Poles of Inaccessability", "", "Idea", 2023, "2023#48"),
    makeGame("Cicadas", "", "Idea", 2023, "2023#49"),
    makeGame("Snowy River Hydro", "", "Idea", 2023, "2023#50"),
    makeGame("I Saw it First", "", "Idea", 2023, "2023#52"),
    makeGame("Victory Lap™ - The Line (boardgame)", "", "Idea", 2017, "2017-18"),
    makeGame("Farcical Aquatic Ceremony", "", "Idea", 2017, "2017-24"),
    makeGame("Baby vs. World", "", "Idea", 2018, "2018-08"),
    makeGame("Ultimate Sports Star ", "", "Idea", 2018, "2018-12"),
    makeGame("Ultimate meta sport manager", "", "Idea", 2019, "2019#02"),
    makeGame("Modern Life", "", "Idea", 2019, "2019#04"),
    makeGame("To Lunch", "", "Idea", 2019, "2019#07"),
    makeGame("Rugby Union", "", "Idea", 2019, "2019#10"),
    makeGame("Animal Civiliation", "", "Idea", 2019, "2019#13"),
    makeGame("Football Clone", "", "Idea", 2019, "2019#14"),
    makeGame("Spellcasting Championships", "", "Idea", 2019, "2019#15"),
    makeGame("Order Up!", "", "Idea", 2020, "2020#01"),
    makeGame("Rock Climbing", "", "Idea", 2020, "2020#02"),
    makeGame("The Placebo Effect ", "", "Idea", 2020, "2020#03"),
    makeGame("Cricket Worker Placement / Test Cricket", "", "Idea", 2020, "2020#04"),
    makeGame("Rock Band", "", "Idea", 2020, "2020#06"),
    makeGame("Dance Off", "", "Idea", 2020, "2020#07"),
    makeGame("Big Pharma", "", "Idea", 2020, "2020#08"),
    makeGame("Stunt Pilot", "", "Idea", 2020, "2020#09"),
    makeGame("End Times", "", "Idea", 2020, "2020#10"),
    makeGame("Battle Royale Bingo", "", "Idea", 2020, "2020#11"),
    makeGame("Civ Game", "", "Idea", 2020, "2020#12"),
    makeGame("Method Actors", "", "Idea", 2020, "2020#13"),
    makeGame("18 Holes™ - Clubhouse Defenders", "", "Idea", 2020, "2020#15"),
    makeGame("18 Holes™ - Fog of Golf", "", "Idea", 2020, "2020#16"),
    makeGame("Political Wizardry", "", "Idea", 2020, "2020#17"),
    makeGame("Rock Band Manager", "", "Idea", 2020, "2020#18"),
    makeGame("Embargo!", "", "Idea", 2020, "2020#19"),
    makeGame("Dutch Museum", "", "Idea", 2020, "2020#22"),
    makeGame("The Clock Queen", "", "Idea", 2020, "2020#23"),
    makeGame("Gambit", "", "Idea", 2020, "2020#24"),
    makeGame("Dog Training (Pace Notes)", "", "Idea", 2020, "2020#25"),
    makeGame("Stunt Pilot (tiles)", "", "Idea", 2020, "2020#26"),
    makeGame("A Hill to Die Upon", "", "Idea", 2020, "2020#27"),
    makeGame("Citrus", "", "Idea", 2020, "2020#28"),
    makeGame("Foreign Policy", "", "Idea", 2020, "2020#29"),
    makeGame("Victory Lap™ - Roll and Write", "", "Idea", 2021, "2021#02"),
    makeGame("Flotsam™ Legacy", "", "Idea", 2021, "2021#04"),
    makeGame("18 Holes™ - Press Your Luck Bag", "", "Idea", 2021, "2021#06"),
    makeGame("Invasion Earth™ (+James)", "", "Idea", 2021, "2021#08"),
    makeGame("Match Up! (AFL)", "", "Idea", 2021, "2021#10"),
    makeGame("Penalty Shootout-1", "", "Idea", 2021, "2021#12"),
    makeGame("Dog Agility Show", "", "Idea", 2021, "2021#13"),
    makeGame("Dog Show For One", "", "Idea", 2021, "2021#14"),
    makeGame("Motley Bridges of the Atlantic", "", "Idea", 2021, "2021#15"),
    makeGame("Invasion Earth™ - Infiltrate", "", "Idea", 2021, "2021#19"),
    makeGame("Art Estates", "", "Idea", 2021, "2021#21"),
    makeGame("Odd Socks", "", "Idea", 2021, "2021#27"),
    makeGame("Tinking", "", "Idea", 2021, "2021#28"),
    makeGame("Roaring", "", "Idea", 2021, "2021#29"),
    makeGame("Bin Night", "", "Idea", 2021, "2021#30"),
    makeGame("Grand Tour", "", "Idea", 2021, "2021#31"),
    makeGame("Fossil Divers of the Limestone Coast", "", "Idea", 2022, "2022#01"),
    makeGame("Abyssal (Roll and Write)", "", "Idea", 2022, "2022#02"),
    makeGame("Sandcastles™ - The Board Game", "", "InDevelopmentEarly", 2022, "2022#03"),
    makeGame("Ape Brand Canapes (+James)", "", "Idea", 2022, "2022#04"),
    makeGame("Kanga-Ruse (+James)", "", "Idea", 2022, "2022#05"),
    makeGame("Hive Mined (+James)", "", "Idea", 2022, "2022#06"),
    makeGame("Ships of Theseus", "", "Idea", 2022, "2022#07"),
    makeGame("Chill Mahjong", "", "Idea", 2022, "2022#11"),
    makeGame("Space Corn", "", "Idea", 2022, "2022#14"),
    makeGame("Parity", "", "Idea", 2022, "2022#15"),
    makeGame("Duelling Sushi Trains", "", "Idea", 2022, "2022#16"),
    makeGame("Fashion Tower", "", "Idea", 2022, "2022#17"),
    makeGame("Quality Control", "", "Idea", 2022, "2022#18"),
    makeGame("Flotsam Restaurants", "", "Idea", 2022, "2022#19"),
    makeGame("Rock Band Manager - Card Game (+James)", "", "Idea", 2022, "2022#21"),
    makeGame("Orbit", "", "Idea", 2022, "2022#22"),
    makeGame("Slingshot", "", "Idea", 2022, "2022#23"),
    makeGame("Two Deck Baseball", "", "Idea", 2022, "2022#24"),
    makeGame("Flotsam Lodge", "", "Idea", 2022, "2022#25"),
    makeGame("Golden Hour (+Jay)", "", "Idea", 2022, "2022#27"),
    makeGame("Eggine Builder (+Jay)", "", "Idea", 2022, "2022#28"),
    makeGame("Cockentrice", "", "Idea", 2022, "2022#30"),
    makeGame("Plant Placement (+Jay)", "", "Idea", 2022, "2022#32"),
    makeGame("Tiny House (+Jay)", "", "Idea", 2022, "2022#33"),
    makeGame("Greenwall", "", "Idea", 2022, "2022#34"),
    makeGame("Soufflé", "", "Idea", 2022, "2022#35"),
    makeGame("Redhenge", "Set collection with hedges, directions and ley lines", "Idea", 2023, "2023#01"),
    makeGame("Fisherfolk", "", "Idea", 2023, "2023#03"),
    makeGame("Ruin", "", "Idea", 2023, "2023#04"),
    makeGame("Tide Pool", "", "Idea", 2023, "2023#05"),
    makeGame("Coober Pedy", "", "Idea", 2023, "2023#07"),
    makeGame("ROYGBIV / Infrared-Ultravoilet", "", "Idea", 2023, "2023#08"),
    makeGame("Kalgoorlie", "", "Idea", 2023, "2023#09"),
    makeGame("Broome", "", "Idea", 2023, "2023#10"),
    makeGame("Succulent Bowl (+Jay)", "", "Idea", 2023, "2023#11"),
    makeGame("Bird on a Wire", "", "Idea", 2023, "2023#13"),
    makeGame("Timeline Travelling (+Jay)", "", "Idea", 2023, "2023#14"),
    makeGame("Write & Roll (+Jay)", "", "Idea", 2023, "2023#15"),
    makeGame("Star Signs", "", "Idea", 2023, "2023#19"),
    makeGame("Fault", "", "Idea", 2023, "2023#22"),
    makeGame("Constellations", "", "Idea", 2023, "2023#23"),
    makeGame("Echuca", "", "Idea", 2023, "2023#25"),
    makeGame("Dig Up!", "", "Idea", 2023, "2023#26"),
    makeGame("Balloon Racing", "", "Idea", 2023, "2023#28"),
    makeGame("Treasure Hunters", "", "Idea", 2023, "2023#29"),
    makeGame("Trick Taking Baseball", "", "Idea", 2023, "2023#32"),
    makeGame("Splash Diving", "", "Idea", 2023, "2023#35"),
    makeGame("Cricket Card Game", "", "Idea", 2009, "2009-02"),
    makeGame("Pace Notes", "", "Idea", 2016, "2016-28"),
    makeGame("Victory Lap™ - One Perfect Lap", "", "Idea", 2017, "2017-06"),
    makeGame("Victory Lap™ - One Perfect Race", "", "Idea", 2017, "2017-07"),
    makeGame("Victory Lap™ - One Perfect Season", "", "Idea", 2017, "2017-17"),
    makeGame("Trading Game (+Tim)", "", "Idea", 2019, "2019-11"),
    makeGame("18 Holes™ - Links", "", "Idea", 2020, "2020#21"),
    makeGame("Succulent", "", "Idea", 2021, "2021#03"),
    makeGame("18 Holes™ - Course Architect (hex based)", "", "Idea", 2021, "2021#07"),
    makeGame("Parks™ - Roll and Write", "", "Idea", 2021, "2021#16"),
    makeGame("Flotsam™ Roll and Write", "", "Idea", 2021, "2021#18"),
    makeGame("20 to None", "", "Idea", 2021, "2021#22"),
    makeGame("18 Holes™ - Dice", "", "Idea", 2021, "2021#25"),
    makeGame("Abyssal", "", "Idea", 2021, "2021#26"),
    makeGame("Timetravelling Ship of Theseus", "", "InDevelopmentEarly", 2022, "2022#08"),
    makeGame("Sandcastles™ Flip and Write", "", "Idea", 2022, "2022#09"),
    makeGame("Theseus vs. The Minotaur", "", "Idea", 2022, "2022#10"),
    makeGame("Flotsam Falls Dice", "", "InDevelopmentEarly", 2022, "2022#12"),
    makeGame("Rule-ette", "Place bets to modify the rules of the game", "Idea", 2023, "2023#55"),
  ],
  addGame: () => {},
  updateId: () => {},
  updateName: () => {},
  updateTag: () => {},
  updateStatus: () => {},
};
