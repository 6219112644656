/**
 * @param {string} string
 * @returns {boolean}
 */
export const isStringEmpty = (string) => `${string}`.length === 0;

/**
 * @param {string} url
 * @returns {boolean}
 */
export const isInvalidUrl = (url) => {
  if (!url) {
    return true;
  }
  if (isStringEmpty(url)) {
    return true;
  }
  if (!url.startsWith("https://")) {
    return true;
  }

  return false;
};
