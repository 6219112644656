import React from "react";
import { ListView } from "../core/ListView";
import { Chip, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import AddGameFullScreenDialog from "./AddGameFullScreenDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { NoItemsFound } from "../publisher/NoItemsFound";

/**
 *
 * @param {Object} props
 * @param {GameId} props.gameId
 * @param {GameSummary} props.summary
 */
const GameListEntry = ({ gameId, summary }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.gameId === gameId;

  const onClickHandler = () => {
    navigate(Path.user().game(gameId));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={summary.name} secondary={summary.tag} />
      <ListItemIcon>{StatusToText[summary.status]}</ListItemIcon>
    </ListItemButton>
  );
};

/**
 * @type {Record<DesignerGameStatus, string>}
 */

const StatusToText = {
  Idea: "IDEA",
  InDevelopmentEarly: "DEV - EARLY",
  InDevelopmentMid: "DEV - MID",
  InDevelopmentLate: "DEV - LATE",
  PitchingNow: "PITCHING",
  Published: "PUBLISHED",
  Signed: "SIGNED",
};

export const GamesList = ({ onFilterClicked, summaries, filter, filters = [] }) => {
  const Filters = () => (
    <>
      {filters.map(({ label }) => {
        const onChipClicked = () => onFilterClicked(label);
        return (
          <Chip key={label} label={label} onClick={onChipClicked} variant={label === filter ? "filled" : "outlined"} />
        );
      })}
    </>
  );

  const Action = () => <AddGameFullScreenDialog />;

  return (
    <ListView title="GAMES" Filters={Filters} Action={Action}>
      {summaries.map((summary) => (
        <GameListEntry gameId={summary.id.toString()} key={summary.id.toString()} summary={summary} />
      ))}
      {summaries.length === 0 && <NoItemsFound>No games found.</NoItemsFound>}
    </ListView>
  );
};
