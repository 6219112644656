import React, { useState } from "react";
import { Box, Button, TextareaAutosize } from "@mui/material";
import TimelineItem from "@mui/lab/TimelineItem";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from "@mui/lab";

export const FollowUpTimelineItem = () => {
  const [update, setUpdate] = useState("");

  const saveValue = (event) => {
    setUpdate(event.target.value);
  };
  const addUpdate = () => {};

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        <TimelineConnector />
      </TimelineSeparator>
      <TimelineContent>
        <TextareaAutosize minRows={3} style={{ width: "100%" }} onChange={saveValue} />
        <Box style={{ width: "100%", justifyContent: "flex-end", display: "flex" }}>
          <Button onClick={addUpdate} variant="outlined">
            Add Update
          </Button>
        </Box>
      </TimelineContent>
    </TimelineItem>
  );
};
