import React from "react";
import { Card, Typography } from "@mui/material";

export const EmptyDetailCard = ({ text }) => (
  <Card
    elevation={1}
    square={false}
    sx={{ height: "85vh", justifyContent: "center", alignItems: "center", flex: 1, display: "flex" }}
  >
    <Typography>{text}</Typography>
  </Card>
);
