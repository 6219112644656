import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Avatar } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { useParams } from "react-router-dom";
import numeral from "numeral";

export const Designers = () => {
  const { games } = useContext(GameContext);
  const params = useParams();
  const game = games.find((g) => g.internalId === params.gameId);

  if (!game) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Designers
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Royalty</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {game.designers.map((designer) => (
              <TableRow key={designer.designerId}>
                <TableCell>
                  <Avatar>RB</Avatar>
                </TableCell>
                <TableCell>{numeral(designer.royalty).format("%")}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
