import React, { useState } from "react";
import { ListDetail } from "../core/ListDetail";
import { SellsheetList } from "./SellsheetList";
import { SellsheetPreview } from "./SellsheetPreview";
import { Card } from "@mui/material";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useAllSellsheetsForGameId } from "./queries";

export const SellsheetListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data } = useAllSellsheetsForGameId(gameId);

  const [selected, setSelected] = useState("");

  const SelectedSellsheet = () => (
    <Card elevation={1} square={false}>
      <SellsheetPreview sellsheet={(data || []).find((sellsheet) => sellsheet.id === selected)} />
    </Card>
  );
  const ListView = () => (
    <SellsheetList sellsheets={data || []} selected={selected} onClick={setSelected} back={`/games/${gameId}`} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedSellsheet} />;
};
