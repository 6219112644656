import React, { useContext } from "react";
import { Box, Card, CircularProgress, List, ListItem } from "@mui/material";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token } from "../util/type-conversion";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import { fetchPublisher } from "../publisher/service";
import { FilePond, registerPlugin } from "react-filepond";
import endpoints from "../endpoints";
import { enqueueSnackbar } from "notistack";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { AccountContext } from "../core/AccountProvider";
import { PublisherByPublisherId } from "../games/query-keys";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileValidateType);

const publisherSupportEmail = "support@pitch2table.com";

const CompanyNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Company Name"
      helperText={`Locked. Contact ${publisherSupportEmail} to change`}
      initialValue={initialValue}
      updateFunction={() => null}
      queryKey=""
      disabled
    />
  );
};

export const AboutPublisherBase = () => {
  const { publisherId } = useContext(AccountContext);
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const { status, data } = useQuery({
    queryKey: PublisherByPublisherId(publisherId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      return fetchPublisher(token, publisherId);
    },
  });

  if (status === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (status === "error" || !data) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <Box pl={2} pr={2}>
          <FilePond
            allowMultiple={false}
            allowReplace={false}
            allowRevert={false}
            allowProcess={false}
            credits={false}
            instantUpload={true}
            labelIdle={'Drag & Drop company logo or <span class="filepond--label-action"> Browse </span>'}
            imageValidateSizeMaxWidth={1024}
            imageValidateSizeMaxHeight={1024}
            imageCropAspectRatio="1:1"
            acceptedFileTypes={["image/png", "image/jpeg"]}
            fileValidateTypeLabelExpectedTypesMap={{ "image/png": "png", "image/jpeg": "jpg" }}
            server={{
              process: async (_fieldName, file, _metadata, load, error, progress, abort) => {
                const formData = new FormData();
                formData.append("logo", file, file.name);

                const token = s2Token(await getAccessTokenSilently());

                const { publishers } = endpoints();
                const url = publishers().logo(publisherId);

                const request = new XMLHttpRequest();
                request.open("POST", url);
                request.setRequestHeader("Authorization", `Bearer ${token}`);

                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                };

                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                    enqueueSnackbar("Publisher logo updated", { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: PublisherByPublisherId(publisherId) });
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error("oh no");
                    enqueueSnackbar("An error occured uploading the logo", { variant: "error" });
                  }
                };

                request.send(formData);

                return {
                  abort: () => {
                    request.abort();
                    abort();
                  },
                };
              },
            }}
          />
        </Box>
        <ListItem>
          <CompanyNameTextField initialValue={data.name} />
        </ListItem>
      </List>
    </Card>
  );
};

export const AboutPublisher = () => {
  return <AboutPublisherBase />;
};
