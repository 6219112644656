/**
 * @param {string} x
 * @returns {string}
 */
export const noFormatter = (x) => x;

/**
 * @param {string} playerCount
 * @returns {string}
 */
export const formatPlayerCount = (playerCount) => (playerCount ? playerCount.split("-").join(" - ") : "not set");

/**
 * @param {string} duration
 * @returns {string}
 */
export const formatDuration = (duration) => (duration ? `${duration} minutes` : "not set");

/**
 * @param {number} age
 * @returns {string}
 */
export const formatAge = (age) => (age >= 0 ? `${age}+` : "not set");
