import { Typography, Card, CardContent, Link } from "@mui/material";
import React, { useContext } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { LocalShippingOutlined, PrecisionManufacturingOutlined, StarOutline } from "@mui/icons-material";
import { DateTime } from "luxon";
import {
  Timeline,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
} from "@mui/lab";
import { GameContext } from "../providers/GameProvider";

/**
 * @type {Record<TimelineEventName,string>}
 */
const TimelineEventDisplayName = {
  FinalsToManufacturer: "Finals Sent to Printer",
  MassProductionApproval: "Mass Production Approved",
  MassProductionCompleted: "Mass Production Completed",
  ReadyForShipment: "Shipment Ready",
  ShipDeparted: "Shipment Departed",
  ShipArrived: "Shipment Arrived",
  CustomsCompleted: "Shipment Cleared Customs",
  ArrivedAtWarehouse: "Shipment Arrived Warehouse",
  ReadyForDispatch: "Ready for Dispatch",
  Custom: "",
};

/**
 * @type {Record<TimelineEventName,object>}
 */
const TimelineEventDisplayIcon = {
  FinalsToManufacturer: LocalShippingOutlined,
  MassProductionApproval: PrecisionManufacturingOutlined,
  MassProductionCompleted: LocalShippingOutlined,
  ReadyForShipment: LocalShippingOutlined,
  ShipDeparted: LocalShippingOutlined,
  ShipArrived: LocalShippingOutlined,
  CustomsCompleted: LocalShippingOutlined,
  ArrivedAtWarehouse: LocalShippingOutlined,
  ReadyForDispatch: LocalShippingOutlined,
  Custom: LocalShippingOutlined,
};

/**
 * @type {Record<TimelineEventName,number>}
 */
const TimelineEventProjection = {
  FinalsToManufacturer: 0,
  MassProductionApproval: 30,
  MassProductionCompleted: 75,
  ReadyForShipment: 80,
  ShipDeparted: 85,
  ShipArrived: 106,
  CustomsCompleted: 113,
  ArrivedAtWarehouse: 120,
  ReadyForDispatch: 127,
  Custom: 0,
};

/**
 * @type {Record<TimelineEventName,TimelineEventName>}
 */
const TimelineEventNext = {
  FinalsToManufacturer: "MassProductionApproval",
  MassProductionApproval: "MassProductionCompleted",
  MassProductionCompleted: "ReadyForShipment",
  ReadyForShipment: "ShipDeparted",
  ShipDeparted: "ShipArrived",
  ShipArrived: "CustomsCompleted",
  CustomsCompleted: "ArrivedAtWarehouse",
  ArrivedAtWarehouse: "ReadyForDispatch",
  ReadyForDispatch: "ReadyForDispatch",
  Custom: "Custom",
};
/**
 * @type {Record<TimelineEventName,string>}
 */
const TimelineEventHandyTip = {
  FinalsToManufacturer: "",
  MassProductionApproval: "",
  MassProductionCompleted: "Have you organised CE, UKCA and ASTM F963? Have you organised freight forwarding?",
  ReadyForShipment: "Do you have insurance?",
  ShipDeparted: "Who is paying import taxes?",
  ShipArrived: "Customs takes time. Keep all documentation handy.",
  CustomsCompleted: "It's on the way to the warehouse, do you boxes for shipment?",
  ArrivedAtWarehouse: "Double check the sent inventory to the received inventory",
  ReadyForDispatch: "Post those games",
  Custom: "Custom",
};

export const TimelineSimple = () => {
  const { games } = useContext(GameContext);
  const params = useParams();
  const game = games.find((g) => g.internalId === params.gameId);

  if (!game) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Timeline
        </Typography>
        <Timeline position="right">
          {game.editions[0].timeline.map((event) => {
            const Icon = TimelineEventDisplayIcon[event.name];
            const pastEvents = game.editions[0].timeline.filter(({ timestamp }) => !!timestamp);
            pastEvents.sort((a, b) => (b.timestamp || 0) - (a.timestamp || 0));
            const priorEvent = pastEvents[0];
            const latest = priorEvent.timestamp;
            const daysToRemoveFromProjection = TimelineEventProjection[priorEvent.name];
            const projectionDays = TimelineEventProjection[event.name] - daysToRemoveFromProjection;
            const projectionDaysToMills = projectionDays * 24 * 60 * 60 * 1000;

            const nextEvent = TimelineEventNext[priorEvent.name];
            const isNextEvent = nextEvent === event.name;

            return (
              <>
                {isNextEvent && (
                  <TimelineItem>
                    <TimelineOppositeContent sx={{ m: "auto 0" }} align="right" variant="body2" color="text.secondary">
                      {DateTime.now().toFormat("d MMMM yyyy")}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                      <TimelineConnector />
                      <TimelineDot color="primary" variant="filled">
                        <StarOutline />
                      </TimelineDot>
                      <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{ py: "12px", px: 2 }}>
                      <Typography variant="h6" component="span">
                        Today
                      </Typography>
                      <Typography>{TimelineEventHandyTip[nextEvent]}</Typography>
                    </TimelineContent>
                  </TimelineItem>
                )}
                <TimelineItem>
                  <TimelineOppositeContent sx={{ m: "auto 0" }} align="right" variant="body2" color="text.secondary">
                    {DateTime.fromMillis(event.timestamp || latest + projectionDaysToMills).toFormat("d MMMM yyyy")}
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      color={event.timestamp ? "grey" : "primary"}
                      variant={event.timestamp ? "filled" : "outlined"}
                    >
                      <Icon />
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent sx={{ py: "12px", px: 2 }}>
                    <Typography variant="h6" component="span">
                      {event.name === "Custom" ? event.customName : TimelineEventDisplayName[event.name]}
                    </Typography>
                    <Typography>
                      {event.timestamp && (
                        <Link component={RouterLink} to="/game/id/art/finals">
                          View details
                        </Link>
                      )}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </>
            );
          })}
        </Timeline>
      </CardContent>
    </Card>
  );
};
