import React, { createContext, useEffect, useMemo } from "react";
import { seedUser } from "./users-service.js";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token, s2UserId, s2url } from "../util/type-conversion.js";
import { useUserProfile } from "../user/queries.js";
import { useQueryClient } from "@tanstack/react-query";
import { ThisUser } from "../games/query-keys.js";

/**
 * @typedef {Object} UsersContext
 * @property {PublicContact|undefined} thisUser
 */

/**
 * @type {UsersContext}
 */
const initialState = {
  thisUser: {
    firstName: "",
    id: s2UserId(""),
    isPrimaryRecord: false,
    lastName: "",
    location: { address: "", city: "", country: "", hasLoadingDock: false, name: "", timezone: "" },
    pictureUrl: s2url(""),
    preferredName: "",
    pronouns: "",
    seeded: false,
  },
};

export const UsersContext = createContext(initialState);

const UsersProvider = ({ children }) => {
  const { getAccessTokenSilently, isAuthenticated, user } = useAuth0();
  const queryClient = useQueryClient();
  const { data } = useUserProfile();

  useEffect(() => {
    async function fetchData() {
      if (!data) {
        return;
      }

      if (!data.seeded && user) {
        const token = s2Token(await getAccessTokenSilently());

        await seedUser(token, user.given_name || "", user.family_name || "", s2url(user.picture || ""));

        queryClient.invalidateQueries({ queryKey: ThisUser() });
      }
    }

    fetchData();
  }, [getAccessTokenSilently, data, user, isAuthenticated, queryClient]);

  const value = useMemo(
    () => ({
      thisUser: data,
    }),
    [data]
  );

  return <UsersContext.Provider value={value}>{children}</UsersContext.Provider>;
};

export default UsersProvider;
