import React from "react";
import TopBar from "./core/TopBar";
import { Stack } from "@mui/material";
import GameView from "./games/GameView";
import Planning from "./concept/planning/Planning";
import Sales from "./financials/Sales";
import Submissions from "./portfolio/Submissions";
import { GamePitches } from "./games/GamePitches";
import { Calendar } from "react-yearly-calendar";
import { customCSSclasses } from "./CanCon";

export const conceptRoutes = [
  {
    path: "/concept/game-pitches",
    element: (
      <>
        <GamePitches />
      </>
    ),
  },
  {
    path: "/concept/sales",
    element: (
      <>
        <TopBar />
        <Sales />
      </>
    ),
  },
  {
    path: "/concept/submissions",
    element: (
      <>
        <TopBar />
        <Submissions />
      </>
    ),
  },
  {
    path: "/concept/planning",
    element: (
      <>
        <TopBar />
        <Planning />
      </>
    ),
  },
  {
    path: "/concept/game/:gameId",
    element: (
      <>
        <TopBar />
        <GameView />
      </>
    ),
  },
  {
    path: "/concept/yearly-planning",
    element: (
      <>
        <TopBar />
        <Stack direction="column">
          <Calendar year={2023} firstDayOfWeek={1} customClasses={customCSSclasses} />
          <Calendar year={2024} firstDayOfWeek={1} customClasses={customCSSclasses} showDaysOfWeek={false} />
        </Stack>
      </>
    ),
  },
];
