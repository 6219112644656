import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";

export const SmartTextField = ({ initialValue = "", onChange, ...props }) => {
  const [name, setName] = useState(initialValue);
  const [isValid, setValidStatus] = useState(false);

  const onChangeHandler = (ev) => {
    setName(ev.target.value);
  };

  const validate = useCallback(() => {
    if (name.length === 0) {
      onChange("");
      setValidStatus(false);
      return false;
    }

    setValidStatus(true);
    onChange(name);
    return true;
  }, [setValidStatus, name, onChange]);

  useEffect(() => {
    validate();
  }, [name, validate]);

  return (
    <TextField
      defaultValue={initialValue}
      fullWidth
      type="text"
      margin="dense"
      {...props}
      error={!isValid}
      onChange={onChangeHandler}
    />
  );
};
