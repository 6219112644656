import React from "react";
import numeral from "numeral";
import { Box, Typography, Paper, TextField, InputAdornment } from "@mui/material";
import { AttachMoneyOutlined, People, Percent, Person } from "@mui/icons-material";

const Sales = () => {
  return (
    <>
      <Box pt={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Mailing List
            </Typography>
            <TextField
              required
              fullWidth
              type="text"
              value={numeral(1500).format("0,0")}
              label="Mailing List Size"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              value={6}
              label="Click Rate"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              value={3}
              label="Conversion Rate"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={45}
              label="Estimated Sales from Mailing List"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Box pt={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Advertising
            </Typography>
            <TextField
              required
              fullWidth
              type="text"
              value={numeral(5000).format("0,0")}
              label="Ad Spend Budget"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachMoneyOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              value={23.42}
              label="Cost per Sale (CPs)"
              margin="normal"
              helperText="Note: the CPS is a significant proportion (46%) of your average order value $50"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachMoneyOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={213}
              label="Estimated Sales from Advertising Spend"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Box pt={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Kickstarter
            </Typography>
            <TextField
              required
              fullWidth
              type="text"
              value={200}
              label="Kickstarter Followers"
              helperText=""
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <People />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              value={20}
              label="Kickstarter Conversion Rate"
              helperText=""
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Percent />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={200 * 0.2}
              label="Estimated Backers from Kickstarter Follwers"
              margin="normal"
              helperText="Note: 15-20% is a common percentage."
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>
      </Box>
      <Box pt={2}>
        <Paper>
          <Box p={2}>
            <Typography variant="h6" color="text.secondary" gutterBottom>
              Summary
            </Typography>
            <TextField
              required
              fullWidth
              type="text"
              value={50}
              label="Average Backer Order Size"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachMoneyOutlined />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={45}
              label="Estimated Backers from Mailing List"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={213}
              label="Estimated Backers from Advertising"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={40}
              label="Estimated Backers from Kickstarter"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={213 + 45 + 40}
              label="Estimated Total Backers"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Person />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              required
              fullWidth
              type="text"
              disabled
              value={numeral(298 * 50).format("0,0")}
              label="Estimated Kickstarter Revenue"
              margin="normal"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <AttachMoneyOutlined />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
        </Paper>
      </Box>
    </>
  );
};

export default Sales;
