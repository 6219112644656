import React from "react";
import { Box, Grid } from "@mui/material";

export const ListDetail = ({ ListView, DetailView }) => {
  return (
    <Box p={2} width="100%" flex={1}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <ListView />
        </Grid>
        <Grid item xs={6}>
          <DetailView />
        </Grid>
      </Grid>
    </Box>
  );
};
