import React, { useRef } from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import {
  Box,
  Card,
  Chip,
  CircularProgress,
  Link,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import { ArrowForwardOutlined, CancelOutlined, Check, EditOutlined, ErrorOutline } from "@mui/icons-material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { updateGame } from "./games-service";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";
import { fetchContact } from "../users/users-service";
import EasyEdit from "react-easy-edit";
import { useParams } from "react-router-dom";
import { UploadedSellsheet } from "../sellsheets/UploadedSellsheet";
import { UploadedRules } from "../rules/UploadedRules";
import { enqueueSnackbar } from "notistack";
import { UploadedSizzle } from "../sizzle/UploadedSizzle";
import { isStringEmpty } from "../core/validation";
import { useHover } from "usehooks-ts";
import { DesignersByGameId, PrivateGameByGameId } from "./query-keys";
import { EditableGameName } from "./EditableGameName";
import { EditableTag } from "./EditableTag";
import { formatPlayerCount, formatAge, formatDuration } from "./formatters";
import { useDesignerGame, usePublisherInterestInGame } from "./queries";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../Path";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";

const PublicText = "Anyone with the link can view this game.";
const PrivateText = "Co-designers and publishers you have submitted to have access";

/**
 *
 * @param {object} props
 * @property {VisibilityTypes} props.visibility
 * @property {Function} props.onToggle
 * @returns
 */
const Visibility = ({ visibility, onToggle }) => {
  const onChangeHandler = (e) => {
    onToggle(e.target.checked ? "Public" : "Private");
  };

  return (
    <ListItem>
      <ListItemText primary="Visibility" secondary={visibility === "Public" ? PublicText : PrivateText}></ListItemText>
      <ListItemButton>
        <Switch defaultChecked={visibility === "Public"} onChange={onChangeHandler} />
      </ListItemButton>
    </ListItem>
  );
};

const EditableInternalIdDisplay = ({ value }) => (
  <Typography variant="body2" textAlign="right" color="text.disabled">
    {value}
  </Typography>
);

const EditableInternalId = ({ text, onSave }) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Stack direction="row" display="flex" justifyContent="flex-end">
      {isHover && (
        <Box pl={1} display="flex" justifyContent="center" alignItems="center">
          <EditOutlined fontSize="small" />
        </Box>
      )}
      <Box ref={hoverRef}>
        <EasyEdit
          value={text}
          type="text"
          onSave={onSave}
          saveButtonLabel={<Check />}
          cancelButtonLabel={<CancelOutlined />}
          displayComponent={<EditableInternalIdDisplay value="" />}
        />
      </Box>
    </Stack>
  );
};

/**
 *
 * @param {object} props
 * @property {GameDesigner[]} props.designer
 */
const Designers = ({ gameId, designers }) => {
  const { getAccessTokenSilently } = useAuth0();

  const { data } = useQuery({
    queryKey: DesignersByGameId(gameId),
    queryFn: async () => {
      if (designers.length === 0) {
        return;
      }

      const token = s2Token(await getAccessTokenSilently());

      const all = Promise.all(
        designers.map(({ designerId }) => {
          return fetchContact(token, designerId);
        })
      );

      return await all;
    },
  });

  if (!data) {
    return <p>no data</p>;
  }

  return (
    <ListItem>
      <ListItemText
        primary={data.length === 1 ? "Designer" : "Designers"}
        secondary={data.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}
      />
      <ListItemIcon>{/* <ArrowForwardOutlined /> */}</ListItemIcon>
    </ListItem>
  );
};

const EditableListItemTextDisplay = ({ value, formatter }) => {
  return (
    <Typography variant="body2" color="text.secondary" display="block">
      {formatter(value)}
    </Typography>
  );
};

const EditableListItemText = ({ primary, secondary, formatter, onSave }) => {
  return (
    <Box>
      <Typography>{primary}</Typography>
      <EasyEdit
        value={secondary}
        type="text"
        onSave={onSave}
        saveButtonLabel={<Check />}
        cancelButtonLabel={<CancelOutlined />}
        displayComponent={<EditableListItemTextDisplay value="" formatter={formatter} />}
        onValidate={(value) => !!value}
      />
    </Box>
  );
};

/**
 *
 * @param {object} props
 * @property {GameId?} props.gameId
 */
export const GameDetail = ({ gameId }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const { status, data, error } = useDesignerGame(gameId);
  const publishers = usePublisherInterestInGame(gameId).data;

  const mutation = useMutation({
    /**
     * @param {object} data
     * @param {string} [data.name]
     * @param {string} [data.tag]
     * @param {string} [data.internalId]
     * @param {VisibilityTypes?} [data.visibility]
     * @param {string} [data.playerCount]
     * @param {string} [data.duration]
     * @param {number} [data.age]
     */
    mutationFn: async ({ name, tag, internalId, visibility, playerCount, duration, age }) => {
      if (!data) {
        return;
      }

      const token = s2Token(await getAccessTokenSilently());
      return await updateGame(token, data.id, name, tag, internalId, undefined, visibility, playerCount, duration, age);
    },
    onSuccess: () => {
      enqueueSnackbar("Saved", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: PrivateGameByGameId(gameId) });
    },
    onError: () => enqueueSnackbar("Update failed", { variant: "error" }),
  });

  /**
   * @param {string} name
   */
  const updateName = (name) => {
    if (isStringEmpty(name)) {
      return;
    }

    mutation.mutate({ name });
  };

  /**
   * @param {string} tag
   */
  const updateTag = (tag) => {
    if (isStringEmpty(tag)) {
      return;
    }

    mutation.mutate({ tag });
  };

  /**
   * @param {string} playerCount
   */
  const updatePlayerCount = (playerCount) => {
    if (isStringEmpty(playerCount)) {
      return;
    }

    mutation.mutate({ playerCount });
  };

  /**
   * @param {string} duration
   */
  const updateDuration = (duration) => {
    if (isStringEmpty(duration)) {
      return;
    }

    mutation.mutate({ duration });
  };

  /**
   * @param {number} age
   */
  const updateAge = (age) => {
    if (age <= 0) {
      return;
    }

    mutation.mutate({ age });
  };

  /**
   * @param {string} internalId
   */
  const updateInternalId = (internalId) => {
    if (isStringEmpty(internalId)) {
      return;
    }

    mutation.mutate({ internalId });
  };

  /**
   * @param {VisibilityTypes} visibility
   */
  const updateVisibility = (visibility) => {
    mutation.mutate({ visibility });
  };

  if (status === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (status === "error" || !data) {
    console.log(error);
    if (error instanceof Error) {
      enqueueSnackbar("Error loading content", { variant: "error" });
    }

    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <Stack direction="row">
        <Stack direction="row" p={2} flex={1} justifyContent="space-between">
          <Chip label={data.visibility} color={data.visibility === "Public" ? "warning" : "default"} size="small" />
          <EditableInternalId text={data.internalId} onSave={updateInternalId} />
        </Stack>
      </Stack>
      <Stack direction="row">
        <Box p={2} pt={0}>
          <EditableGameName text={data.name} onSave={updateName} />
          <EditableTag text={data.tag} onSave={updateTag} />
        </Box>
      </Stack>
      <List>
        <Designers gameId={data.id} designers={data.designers} />
        <Stack direction="row">
          <ListItem>
            <EditableListItemText
              primary="Players"
              onSave={updatePlayerCount}
              secondary={data.playerCount}
              formatter={formatPlayerCount}
            />
          </ListItem>
          <ListItem>
            <EditableListItemText
              primary="Duration"
              onSave={updateDuration}
              secondary={data.duration}
              formatter={formatDuration}
            />
          </ListItem>
          <ListItem>
            <EditableListItemText primary="Age" onSave={updateAge} secondary={data.age} formatter={formatAge} />
          </ListItem>
        </Stack>
        <ListItem>
          <ListItemText
            primary="Publisher Interest"
            secondary={`${publishers ? publishers.length : "?"} in progress`}
          />
          <Link to={Path.game(gameId).publishers()} component={RouterLink}>
            <ListItemIcon>
              <ListItemButton>
                <ArrowForwardOutlined />
              </ListItemButton>
            </ListItemIcon>
          </Link>
        </ListItem>
        <UploadedSellsheet url={data.sellsheet} gameId={gameId} />
        <UploadedRules url={data.rules} gameId={gameId} />
        <UploadedSizzle url={data.sizzle} gameId={gameId} />
        <Visibility visibility={data.visibility} gameId={gameId} onToggle={updateVisibility} />
      </List>
    </Card>
  );
};

export const GameDetailFromRoute = () => {
  const gameId = useGameIdFromRoute();

  if (!gameId) {
    return <EmptyDetailCard text="Select a game from the list on the left." />;
  }

  return <GameDetail gameId={gameId} />;
};
