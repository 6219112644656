import { Typography, Card, CardContent, Table, TableHead, TableRow, TableCell, TableBody, Link } from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../../providers/GameProvider";
import { Link as RouterLink, useParams } from "react-router-dom";
import { Star } from "@mui/icons-material";
import numeral from "numeral";
import { DateTime } from "luxon";

export const ManufacturingQuotes = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Manufacturing Quotes
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell align="right">Date</TableCell>
              <TableCell align="right">Qty</TableCell>
              <TableCell align="right">Cost / Unit</TableCell>
              <TableCell align="right">Terms</TableCell>
              <TableCell align="right">Total</TableCell>
              <TableCell align="right">Status</TableCell>
              <TableCell align="right">Components</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {game.quotes.map((quote) => {
              const componentConfiguration = game.components.find(
                (config) => config.id === quote.componentConfigurationId
              );

              return (
                <TableRow>
                  <TableCell align="left">{quote.accepted && <Star />}</TableCell>
                  <TableCell align="right">{DateTime.fromMillis(quote.timestamp).toFormat("dd LLL y")}</TableCell>
                  <TableCell align="right">{numeral(quote.quantity).format("0,000")}</TableCell>
                  <TableCell align="right">{numeral(quote.costPerUnit * 1.4751).format("$0.00")} AUD</TableCell>
                  <TableCell align="right">{quote.terms}</TableCell>
                  <TableCell align="right">
                    {numeral(quote.costPerUnit * 1.4751 * quote.quantity).format("$0,000.00")}
                  </TableCell>
                  <TableCell align="right">{DateTime.now().toMillis() > quote.expiry ? "EXPIRED" : "VALID"}</TableCell>
                  <TableCell align="right">
                    <Link component={RouterLink} to={`/game/${game.id}/components/${quote.componentConfigurationId}`}>
                      {componentConfiguration ? componentConfiguration.configuationName : "UNKNOWN"}
                    </Link>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
