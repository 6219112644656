import React, { useRef, useState } from "react";
import { Box, Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
  ArrowForwardOutlined,
  ContentCopy,
  EditOffOutlined,
  EditOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";
import { FilePond } from "react-filepond";
import endpoints from "../endpoints";
import { useCopyToClipboard, useHover } from "usehooks-ts";
import { Path } from "../Path";

import "filepond/dist/filepond.min.css";
import { AllSellsheetsByGameId } from "../games/query-keys";

export const UploadedSellsheet = ({ gameId, url }) => {
  const { getAccessTokenSilently } = useAuth0();
  const [, copy] = useCopyToClipboard();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const [isEditing, setEditing] = useState(false);
  const queryClient = useQueryClient();

  const copyUrlToClipboard = () => {
    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  const toggleEditing = () => {
    setEditing((current) => !current);
  };

  return (
    <>
      <ListItem ref={hoverRef}>
        <ListItemText primary="Sellsheets" secondary={url ? "" : "Not uploaded"} />
        {url ? (
          <>
            {isHover && (
              <>
                <ListItemIcon>
                  <ListItemButton onClick={copyUrlToClipboard}>
                    <ContentCopy />
                  </ListItemButton>
                </ListItemIcon>
                <a href={url} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <ListItemButton>
                      <OpenInNewOutlined />
                    </ListItemButton>
                  </ListItemIcon>
                </a>
              </>
            )}
            {(isHover || isEditing) && (
              <ListItemIcon>
                <ListItemButton onClick={toggleEditing}>
                  {isEditing ? <EditOffOutlined /> : <EditOutlined />}
                </ListItemButton>
              </ListItemIcon>
            )}
            <Link to={Path.game(gameId).sellsheets()} component={RouterLink}>
              <ListItemIcon>
                <ListItemButton>
                  <ArrowForwardOutlined />
                </ListItemButton>
              </ListItemIcon>
            </Link>
          </>
        ) : (
          <>
            {(isHover || isEditing) && (
              <ListItemIcon>
                <ListItemButton onClick={toggleEditing}>
                  {isEditing ? <EditOffOutlined /> : <EditOutlined />}
                </ListItemButton>
              </ListItemIcon>
            )}
          </>
        )}
      </ListItem>
      {isEditing && (
        <Box pl={2} pr={2}>
          <FilePond
            allowMultiple={false}
            allowReplace={false}
            allowRevert={false}
            allowProcess={false}
            credits={false}
            instantUpload={true}
            labelIdle={'Drag & Drop your sellsheet or <span class="filepond--label-action"> Browse </span>'}
            server={{
              process: async (_fieldName, file, _metadata, load, error, progress, abort) => {
                const formData = new FormData();
                formData.append("sellsheet", file, file.name);

                const token = s2Token(await getAccessTokenSilently());

                const { games } = endpoints();
                const url = games().sellSheet(gameId);

                const request = new XMLHttpRequest();
                request.open("POST", url);
                request.setRequestHeader("Authorization", `Bearer ${token}`);

                request.upload.onprogress = (e) => {
                  progress(e.lengthComputable, e.loaded, e.total);
                };

                request.onload = function () {
                  if (request.status >= 200 && request.status < 300) {
                    // the load method accepts either a string (id) or an object
                    load(request.responseText);
                    setEditing(false);
                    enqueueSnackbar("Latest sellsheet uploaded", { variant: "success" });
                    queryClient.invalidateQueries({ queryKey: AllSellsheetsByGameId(gameId) });
                  } else {
                    // Can call the error method if something is wrong, should exit after
                    error("oh no");
                    enqueueSnackbar("An error occured uploading the sellsheet", { variant: "error" });
                  }
                };

                request.send(formData);

                return {
                  abort: () => {
                    request.abort();
                    abort();
                  },
                };
              },
            }}
          />
        </Box>
      )}
    </>
  );
};
