import {
  TextField,
  Typography,
  Card,
  CardContent,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import React, { useContext } from "react";
import { GameContext } from "../providers/GameProvider";
import { useParams } from "react-router-dom";
import numeral from "numeral";
import { cartonsPerPallot, palletHeight, palletWeight } from "../freight/pallet-packing";

export const Shipments = () => {
  const { games } = useContext(GameContext);
  const params = useParams();

  const game = games.find((g) => g.internalId === params.gameId);
  if (!game) {
    return null;
  }

  return (
    <Card variant="elevation" sx={{ width: "100%" }}>
      <CardContent>
        <Typography variant="h6" color="text.secondary" gutterBottom>
          Shipments
        </Typography>
        <Typography color="text.secondary" gutterBottom>
          Weight, goods value and cartons are based on the selected component configuration.
        </Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="right">Warehouse</TableCell>
              <TableCell align="right">Units</TableCell>
              <TableCell align="right">Weight (KG)</TableCell>
              <TableCell align="right">Goods Value</TableCell>
              <TableCell align="right">Cartons</TableCell>
              <TableCell align="right">Pallets</TableCell>
              <TableCell align="right">Height</TableCell>
              <TableCell align="right">Weight</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {game.printRuns[0].shipments.map((destination) => {
              const gameWeight = 463;
              const gameCostPerUnit = 2.75;
              const weightGrams = destination.units * gameWeight;
              const weightKG = weightGrams / 1000;
              const goodsValue = destination.units * gameCostPerUnit;
              const cpp = cartonsPerPallot(destination.pallet, 290, 285, 200);
              const unitsPerPallet = cpp * 12;
              const pallets = destination.units / unitsPerPallet;
              const cartons = destination.units / 12;
              const cartonWeight = 12 * gameWeight;

              return (
                <TableRow>
                  <TableCell align="right">{destination.to}</TableCell>
                  <TableCell align="right">
                    <TextField type="number" value={destination.units} size="small" margin="none" />
                  </TableCell>
                  <TableCell align="right">{numeral(weightKG).format("0,000.0")}</TableCell>
                  <TableCell align="right">{numeral(goodsValue).format("$0,000.00")}</TableCell>
                  <TableCell align="right">{numeral(cartons).format("0,0.0")}</TableCell>
                  <TableCell align="right">{`${numeral(pallets).format("0.0")}`}</TableCell>
                  <TableCell align="right">{`${numeral(palletHeight(destination.pallet, 200)).format(
                    "0"
                  )}mm`}</TableCell>
                  <TableCell align="right">
                    {numeral(Math.ceil(palletWeight(destination.pallet, 290, 285, 200, cartonWeight)) / 1000).format(
                      "0"
                    )}{" "}
                    kg
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          <TableHead>
            <TableRow>
              <TableCell align="right">TOTAL</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right">
                {numeral(game.printRuns[0].shipments.reduce((t, d) => t + d.units, 0)).format("0,000")}
              </TableCell>
              <TableCell align="right">
                {numeral((game.printRuns[0].shipments.reduce((t, d) => t + d.units, 0) * 643) / 1000).format("0,000.0")}
              </TableCell>
              <TableCell align="right">
                {numeral(game.printRuns[0].shipments.reduce((t, d) => t + d.units, 0) * 4.5).format("$0,000.0")}
              </TableCell>
              <TableCell align="right">83.3</TableCell>
              <TableCell align="right">3.0</TableCell>
              <TableCell align="right"></TableCell>
              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </CardContent>
    </Card>
  );
};
