/**
 * @param {string} s
 * @returns {Email}
 */
// @ts-ignore
export const s2email = (s) => s;

/**
 * @param {string} s
 * @returns {Url}
 */
// @ts-ignore
export const s2url = (s) => s;

/**
 *
 * @param {string} s
 * @returns {TokenString}
 */
// @ts-ignore
export const s2Token = (s) => s;

/**
 *
 * @param {string} s
 * @returns {UserId}
 */
// @ts-ignore
export const s2UserId = (s) => s;

/**
 *
 * @param {string} s
 * @returns {GameId}
 */
// @ts-ignore
export const s2GameId = (s) => s;

/**
 *
 * @param {string} s
 * @returns {PublisherId}
 */
// @ts-ignore
export const s2PublisherId = (s) => s;
