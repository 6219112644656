import { useQuery } from "@tanstack/react-query";
import { fetchLatestRulesForGame } from "../games/games-service";
import { LatestRulesByGameId } from "../games/query-keys";

/**
 * @param {GameId} gameId
 * @returns
 */
export const useLatestRules = (gameId) => {
  return useQuery({
    queryKey: LatestRulesByGameId(gameId),
    queryFn: async () => {
      const rules = await fetchLatestRulesForGame(gameId);
      return rules.length > 0 ? rules[0] : null;
    },
  });
};
