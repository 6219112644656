import React, { useRef, useState } from "react";
import { Box, Icon, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import {
  CancelOutlined,
  Check,
  ContentCopy,
  EditOffOutlined,
  EditOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import EasyEdit from "react-easy-edit";
import { useAuth0 } from "@auth0/auth0-react";
import { useCopyToClipboard, useElementSize, useHover } from "usehooks-ts";

import "filepond/dist/filepond.min.css";
import ReactPlayer from "react-player";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { s2Token } from "../util/type-conversion";
import { updateGame } from "../games/games-service";
import { isInvalidUrl } from "../core/validation";
import { PrivateGameByGameId } from "../games/query-keys";

export const UploadedSizzle = ({ gameId, url }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();
  const [, copy] = useCopyToClipboard();
  const [squareRef, { width }] = useElementSize();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);
  const [isEditing, setEditing] = useState(false);

  const copyUrlToClipboard = () => {
    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  const mutation = useMutation({
    /**
     * @param {object} data
     * @property {GameId} data.gameId
     * @property {Url} data.sizzle
     */
    mutationFn: async ({ gameId, sizzle }) => {
      const token = s2Token(await getAccessTokenSilently());
      return await updateGame(token, gameId, "", "", "", sizzle);
    },
    onSuccess: () => {
      enqueueSnackbar("Sizzle updated", { variant: "success" });
      queryClient.invalidateQueries({ queryKey: PrivateGameByGameId(gameId) });
    },
    onError: () => enqueueSnackbar("Sizzle update failed", { variant: "error" }),
  });

  /**
   * @param {Url} sizzle
   */
  const updateSizzleLink = (sizzle) => {
    if (isInvalidUrl(sizzle)) {
      enqueueSnackbar("Please supply valid url", { variant: "info" });
      return;
    }

    mutation.mutate({ gameId, sizzle });
  };

  const toggleEditing = () => {
    setEditing((current) => !current);
  };

  return (
    <>
      <ListItem ref={hoverRef}>
        <ListItemText primary="Sizzle Reel" secondary={isEditing ? "Not uploaded" : ""} />
        {url ? (
          <>
            {isHover && (
              <>
                <ListItemIcon>
                  <ListItemButton onClick={copyUrlToClipboard}>
                    <ContentCopy />
                  </ListItemButton>
                </ListItemIcon>
                <a href={url} target="_blank" rel="noreferrer">
                  <ListItemIcon>
                    <ListItemButton>
                      <OpenInNewOutlined />
                    </ListItemButton>
                  </ListItemIcon>
                </a>
              </>
            )}
            <ListItemIcon>
              {isHover || isEditing ? (
                <ListItemButton onClick={toggleEditing}>
                  {isEditing ? <EditOffOutlined /> : <EditOutlined />}
                </ListItemButton>
              ) : (
                <ListItemButton disabled>
                  <Icon />
                </ListItemButton>
              )}
            </ListItemIcon>
          </>
        ) : (
          <>
            <ListItemIcon>
              {isHover || isEditing ? (
                <ListItemButton onClick={toggleEditing}>
                  {isEditing ? <EditOffOutlined /> : <EditOutlined />}
                </ListItemButton>
              ) : (
                <ListItemButton disabled>
                  <Icon />
                </ListItemButton>
              )}
            </ListItemIcon>
          </>
        )}
      </ListItem>
      {isEditing && (
        <Box p={2}>
          <Typography variant="body1" textAlign="left" color="text.secondary" noWrap>
            <EasyEdit
              value={url || "Click to add sizzle video link"}
              type="text"
              onSave={updateSizzleLink}
              saveButtonLabel={<Check />}
              cancelButtonLabel={<CancelOutlined />}
            />
          </Typography>
        </Box>
      )}
      {url && (
        <Box flex={1} justifyContent="center" alignItems="center" display="flex" ref={squareRef}>
          <ReactPlayer light url={url} width={width - 32} height={(width - 32) * 0.5625} />
        </Box>
      )}
    </>
  );
};
