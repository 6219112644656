import React from "react";
import { Link, ListItemButton } from "@mui/material";
import { ChangeCircle } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Path } from "../Path";

const ModeButtonBase = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === Path.SelectAccount}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.SelectAccount}
        aria-label="go account selection"
        aria-controls="selectaccount"
        sx={{ flexGrow: 1 }}
      >
        <ChangeCircle />
      </Link>
    </ListItemButton>
  );
};

export const ModeButton = () => <ModeButtonBase />;
