import React from "react";
import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ContentCopy, OpenInNewOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useCopyToClipboard } from "usehooks-ts";
import { SellsheetPreview } from "./SellsheetPreview";
import { SectionHeading } from "../core/font-sizes";
import { useLatestSellsheet } from "./queries";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";

/**
 * @param {object} props
 * @param {Url?} [props.url]
 * @returns {React.JSX.Element}
 */
export const PublicSellsheet = ({ url }) => {
  const [, copy] = useCopyToClipboard();
  const gameId = useGameIdFromRoute();
  const { data } = useLatestSellsheet(gameId);

  const copyUrlToClipboard = () => {
    if (!url) {
      return;
    }

    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  if (!data || data.length === 0) {
    return (
      <ListItem>
        <ListItemText primary="Sellsheets" secondary="Not available" />
      </ListItem>
    );
  }

  return (
    <Stack direction="column">
      <Stack p={2} pb={0} direction="row" display="flex" justifyContent="space-between">
        <SectionHeading>Sellsheet</SectionHeading>
        {url && (
          <Box>
            <ListItemIcon>
              <ListItemButton onClick={copyUrlToClipboard}>
                <ContentCopy />
              </ListItemButton>
            </ListItemIcon>
            <a href={url} target="_blank" rel="noreferrer">
              <ListItemIcon>
                <ListItemButton>
                  <OpenInNewOutlined />
                </ListItemButton>
              </ListItemIcon>
            </a>
          </Box>
        )}
      </Stack>
      <SellsheetPreview sellsheet={data[0]} />
    </Stack>
  );
};

/**
 * @param {object} props
 * @param {Url?} [props.url]
 * @returns {React.JSX.Element}
 */
export const PublisherSellsheet = ({ url }) => {
  const [, copy] = useCopyToClipboard();
  const gameId = useGameIdFromRoute();
  const { data } = useLatestSellsheet(gameId);

  const copyUrlToClipboard = () => {
    if (!url) {
      return;
    }

    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  if (!data || data.length === 0) {
    return (
      <ListItem>
        <ListItemText primary="Sellsheets" secondary={"Not available"} />
      </ListItem>
    );
  }

  return (
    <Stack direction="column">
      <Stack p={2} pb={0} direction="row" display="flex" justifyContent="space-between">
        <ListItemText primary="Sellsheet" secondary="" />
        {url && (
          <Box>
            <ListItemIcon>
              <ListItemButton onClick={copyUrlToClipboard}>
                <ContentCopy />
              </ListItemButton>
            </ListItemIcon>
            <a href={url} target="_blank" rel="noreferrer">
              <ListItemIcon>
                <ListItemButton>
                  <OpenInNewOutlined />
                </ListItemButton>
              </ListItemIcon>
            </a>
          </Box>
        )}
      </Stack>
      <SellsheetPreview sellsheet={data[0]} />
    </Stack>
  );
};
