import React from "react";
import { DateTime } from "luxon";
import { ListView } from "../core/ListView";
import { ListItemButton, ListItemText, Typography } from "@mui/material";

/**
 * @param {Object} props
 * @property {Rules} rules
 * @property {boolean} selected
 * @property {Function} onClick
 */
const RulesListEntry = ({ rules, selected, version, onClick }) => {
  return (
    <ListItemButton
      alignItems="flex-start"
      selected={selected}
      onClick={() => {
        onClick(rules.id);
      }}
    >
      <ListItemText
        primary={`v${version}`}
        secondary={`Uploaded ${DateTime.fromMillis(rules.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @property {Rules[]} rules
 * @property {string} selected
 * @property {Function} onClick
 */
export const RulesList = ({ rules = [], selected, onClick, back }) => {
  return (
    <ListView title="RULES" Filters={null} Action={null} back={back}>
      {rules.map((document, index) => (
        <RulesListEntry
          key={document.id.toString()}
          rules={document}
          selected={selected === document.id}
          onClick={onClick}
          version={rules.length - index}
        />
      ))}
      {rules.length === 0 && <Typography>No rules found.</Typography>}
    </ListView>
  );
};
