import React from "react";
import {
  Box,
  Card,
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { fetchThisUser, updateCity, updateCountry, updateTimezone } from "../users/users-service";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token } from "../util/type-conversion";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import { ThisUser } from "../games/query-keys";
import { DateTime } from "luxon";
import tzData from "tzdata";

const CityTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="City"
      helperText="The city where you reside."
      initialValue={initialValue}
      updateFunction={updateCity}
      queryKey={ThisUser()}
    />
  );
};

const CountryTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Country"
      helperText="The country you live in. This is relevant to some publishers."
      initialValue={initialValue}
      updateFunction={updateCountry}
      queryKey={ThisUser()}
    />
  );
};

const luxonValidTimezones = [
  ...new Set(Object.keys(tzData.zones).filter((tz) => tz.includes("/") && DateTime.local().setZone(tz).isValid)),
].sort((a, b) => (a < b ? -1 : 1));

const TimezoneSelect = ({ value, onChange, queryKey }) => {
  const { getAccessTokenSilently } = useAuth0();
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: async (newValue) => {
      const token = s2Token(await getAccessTokenSilently());

      return await onChange(token, newValue);
    },
    onSuccess: () => queryClient.invalidateQueries({ queryKey }),
  });

  const onChangeHandler = async (event) => {
    mutation.mutate(event.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="game-status">Timezone</InputLabel>
      <Select
        labelId="game-status"
        value={value}
        onChange={onChangeHandler}
        placeholder="status"
        label="Time Zone"
        input={<OutlinedInput label="Time Zone" />}
      >
        {luxonValidTimezones.map((x) => (
          <MenuItem key={x} value={x}>
            {x.replace("_", " ")}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>The timezone you live in. This is relevant to most publishers.</FormHelperText>
    </FormControl>
  );
};

const TimeZoneDropDown = ({ initialValue }) => {
  return <TimezoneSelect value={initialValue} onChange={updateTimezone} queryKey={ThisUser()} />;
};

export const YourLocation = () => {
  const { getAccessTokenSilently } = useAuth0();

  const { status, data } = useQuery({
    queryKey: ThisUser(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      return fetchThisUser(token);
    },
  });

  if (status === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (status === "error" || !data) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <CityTextField initialValue={data.location.city} />
        </ListItem>
        <ListItem>
          <CountryTextField initialValue={data.location.country} />
        </ListItem>
        <ListItem>
          <TimeZoneDropDown initialValue={data.location.timezone} />
        </ListItem>
      </List>
    </Card>
  );
};
