import React from "react";
import { Box, ListItemButton, ListItemIcon, ListItemText, Stack } from "@mui/material";
import { ContentCopy, OpenInNewOutlined } from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { useCopyToClipboard, useElementSize } from "usehooks-ts";
import ReactPlayer from "react-player";
import { SectionHeading } from "../core/font-sizes";
import "filepond/dist/filepond.min.css";

/**
 * @param {object} props
 * @param {Url?} [props.url]
 * @returns {React.JSX.Element}
 */
export const LatestSizzle = ({ url }) => {
  const [, copy] = useCopyToClipboard();
  const [squareRef, { width }] = useElementSize();

  const copyUrlToClipboard = () => {
    if (!url) {
      return;
    }

    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  if (!url) {
    return <></>;
  }

  return (
    <>
      <Stack p={2} pb={1} direction="row" display="flex" justifyContent="space-between">
        <SectionHeading>Sizzle Reel</SectionHeading>
        {url && (
          <Box>
            <ListItemIcon>
              <ListItemButton onClick={copyUrlToClipboard}>
                <ContentCopy />
              </ListItemButton>
            </ListItemIcon>
            <a href={url} target="_blank" rel="noreferrer">
              <ListItemIcon>
                <ListItemButton>
                  <OpenInNewOutlined />
                </ListItemButton>
              </ListItemIcon>
            </a>
          </Box>
        )}
      </Stack>
      {url && (
        <Box pb={2} flex={1} justifyContent="center" alignItems="center" display="flex" ref={squareRef}>
          <ReactPlayer light url={url} width={width - 32} height={(width - 32) * 0.5625} />
        </Box>
      )}
    </>
  );
};

/**
 * @param {object} props
 * @param {Url?} [props.url]
 * @returns {React.JSX.Element}
 */
export const PublisherLatestSizzle = ({ url }) => {
  const [, copy] = useCopyToClipboard();
  const [squareRef, { width }] = useElementSize();

  const copyUrlToClipboard = () => {
    if (!url) {
      return;
    }

    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  return (
    <>
      <Stack p={2} pb={1} direction="row" display="flex" justifyContent="space-between">
        <ListItemText primary="Sizzle Reel" secondary={!url && "Not available"} />
        {url && (
          <Box>
            <ListItemIcon>
              <ListItemButton onClick={copyUrlToClipboard}>
                <ContentCopy />
              </ListItemButton>
            </ListItemIcon>
            <a href={url} target="_blank" rel="noreferrer">
              <ListItemIcon>
                <ListItemButton>
                  <OpenInNewOutlined />
                </ListItemButton>
              </ListItemIcon>
            </a>
          </Box>
        )}
      </Stack>
      {url && (
        <Box pb={2} flex={1} justifyContent="center" alignItems="center" display="flex" ref={squareRef}>
          <ReactPlayer light url={url} width={width - 32} height={(width - 32) * 0.5625} />
        </Box>
      )}
    </>
  );
};
