import React from "react";
import { Box, Typography } from "@mui/material";

export const ImmutableGameTag = ({ text }) => {
  return (
    <Typography variant="h5" textAlign="left">
      <Box>{text}</Box>
    </Typography>
  );
};
