import React, { useEffect, useState } from "react";
import { Alert, Box, Card, CircularProgress, List, ListItemText, Typography } from "@mui/material";
import { ErrorOutline } from "@mui/icons-material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { enqueueSnackbar } from "notistack";
import { useDesignerGame, usePublisherInterestInGame } from "./queries";
import { useGameIdFromRoute, usePublisherIdFromRoute } from "./WithGameIdFromRoute";
import { formatEventDate, getDaysAgo } from "./formatting";
import { usePublisherContact } from "../publisher/queries";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Timeline, TimelineContent, TimelineDot, TimelineSeparator } from "@mui/lab";
import { FollowUpTimelineItem } from "./FollowUpTimelineItem";

/**
 * @type {DesignerPublisherGameView}
 */
const initialPublisherGame = undefined;

export const PublisherInterestInGame = () => {
  const gameId = useGameIdFromRoute();
  const publisherId = usePublisherIdFromRoute();

  const { status, error } = useDesignerGame(gameId);
  const { data: contact, status: contactStatus } = usePublisherContact(publisherId);
  const publishers = usePublisherInterestInGame(gameId).data;

  /**
   * @type {[(DesignerPublisherGameView|undefined),React.Dispatch<React.SetStateAction<(DesignerPublisherGameView|undefined)>>]}
   */
  const [publisherGame, setPublisherGame] = useState(initialPublisherGame);

  useEffect(() => {
    if (!publishers) {
      return;
    }

    setPublisherGame(
      publishers.find((publisher) => publisher.publisherId === publisherId && publisher.gameId === gameId)
    );
  }, [gameId, publisherId, publishers]);

  if (!gameId || !publisherId) {
    return <EmptyDetailCard text="Select a publisher from the list on the left." />;
  }

  if (status === "loading" || contactStatus === "loading") {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (status === "error" || !publisherGame || !contact) {
    if (error instanceof Error) {
      enqueueSnackbar("Error loading content", { variant: "error" });
    }

    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <ErrorOutline />
        </Box>
      </Card>
    );
  }

  const canFollowUp = getDaysAgo(publisherGame.dateSubmitted) >= 28;
  const daysToWait = 28 - getDaysAgo(publisherGame.dateSubmitted);

  return (
    <>
      <Card elevation={1} square={false}>
        <Box p={2}>
          <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
            {publisherGame.publisherName}
          </Typography>
          <List>
            <Box pb={2}>
              <ListItemText primary="Contact" secondary={contact.name} />
              <ListItemText primary="" secondary={contact.pronouns} />
              <ListItemText primary="" secondary={contact.email} />
              <ListItemText primary="" secondary={contact.number} />
            </Box>
          </List>
        </Box>
      </Card>
      <Box pt={1}>
        <Card elevation={1} square={false}>
          <Box p={2}>
            <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
              History
            </Typography>
            <Box pt={1}>
              {canFollowUp ? (
                <Alert severity="warning">
                  It has been 28 days since your last contact. Consider reaching out to the publisher. Be kind and
                  respectful.
                </Alert>
              ) : (
                <Alert severity="info">
                  {`It has not been 28 days since your last contact. We advise waiting ${daysToWait} more
                  ${daysToWait === 1 ? "day" : "days"} before reaching out to the publisher.`}
                </Alert>
              )}
              <Timeline
                sx={{
                  [`& .${timelineItemClasses.root}:before`]: {
                    flex: 0,
                    padding: 0,
                  },
                }}
              >
                <FollowUpTimelineItem />
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineDot />
                  </TimelineSeparator>
                  <TimelineContent>
                    <Typography>Submitted to Publisher</Typography>
                    <Typography color="text.secondary" variant="body2">
                      {formatEventDate(publisherGame.dateSubmitted)}
                    </Typography>
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            </Box>
          </Box>
        </Card>
      </Box>
    </>
  );
};
