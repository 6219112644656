import { createTheme } from "@mui/material";

const carissma = "#ec7f98";
const cannonPink = "#8b3d60";
const asparagus = "#7ca166";
const seaBuckthorn = "#f9912e";
const pomegrante = "#f44336";
const amethystSmoke = "#A19EB8";
const newYorkPink = "#D9888C";

export const theme = createTheme({
  typography: {
    fontFamily: "proxima-nova, sans-serif",
    body2: {
      fontFamily: "proxima-nova, sans-serif",
    },
  },
  palette: {
    primary: { main: carissma, light: amethystSmoke },
    info: { main: cannonPink },
    success: { main: asparagus },
    warning: { main: seaBuckthorn },
    error: { main: pomegrante },
    background: { default: "transparent" },
    secondary: { main: newYorkPink },
  },
});
