import React from "react";
import { Box } from "@mui/material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useElementSize } from "usehooks-ts";
import { PdfViewer } from "../core/PdfViewer";
import { assetPath } from "../endpoints";

/**
 * @param {object} props
 * @property {Rules?} props.rules
 */
export const RulesPreview = ({ rules }) => {
  const [squareRef, { width, height }] = useElementSize();

  if (!rules) {
    return <EmptyDetailCard text="Select rules to view." />;
  }

  return (
    <Box p={2} pt={0} ref={squareRef}>
      <img src={assetPath(rules.s3location)} alt="" style={{ width: "100%" }} />
      <PdfViewer path={assetPath(rules.s3location)} width={width} height={height} />
    </Box>
  );
};
