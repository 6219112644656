import React from "react";
import { ListView } from "../core/ListView";
import {
  Avatar,
  Box,
  Chip,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { getDaySubmitted } from "./formatting";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { PublisherStatus } from "./PublisherStatus";
import { NoItemsFound } from "../publisher/NoItemsFound";

/**
 * @param {Object} props
 * @param {DesignerPublisherGameView} props.publisher
 */
const PublisherListEntry = ({ publisher }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.publisherId === publisher.publisherId;

  const onClickHandler = () => {
    navigate(Path.game(publisher.gameId).publisher(publisher.publisherId));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <Avatar alt={publisher.publisherName} src={publisher.logoUrl} />
      </ListItemAvatar>
      <ListItemText primary={publisher.publisherName} secondary={getDaySubmitted(publisher.dateSubmitted)} />
      <ListItemIcon>
        <PublisherStatus status={publisher.status} />
      </ListItemIcon>
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {string} props.filter
 * @param {object[]} props.filters
 * @param {Function} props.onFilterClicked
 * @param {DesignerPublisherGameView[]} props.publishers
 */
export const PublisherInterestList = ({ onFilterClicked, publishers, filter, filters = [] }) => {
  const gameId = useGameIdFromRoute();

  const Filters = () => (
    <>
      {filters.map(({ label }) => {
        const onChipClicked = () => onFilterClicked(label);
        return (
          <Chip key={label} label={label} onClick={onChipClicked} variant={label === filter ? "filled" : "outlined"} />
        );
      })}
    </>
  );

  // const Action = () => <AddGameFullScreenDialog title="Add Game" actionText="save" />;

  return (
    <ListView back={Path.game(gameId).link()} title="PUBLISHERS" Filters={Filters}>
      {publishers.map((publisher) => (
        <PublisherListEntry key={publisher.id.toString()} publisher={publisher} />
      ))}
      {publishers.length === 0 && <NoItemsFound>No publishers found.</NoItemsFound>}
    </ListView>
  );
};
