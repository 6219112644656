import { useQuery } from "@tanstack/react-query";
import { DefaultCacheTime, DefaultStaleTime } from "../core/react-query";
import { s2Token } from "../util/type-conversion";
import { useAuth0 } from "@auth0/auth0-react";
import { ThisUser } from "../games/query-keys";
import { fetchThisUser } from "../users/users-service";

export const useUserProfile = () => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: ThisUser(),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());
      return fetchThisUser(token);
    },
    staleTime: DefaultStaleTime,
    cacheTime: DefaultCacheTime,
  });
};
