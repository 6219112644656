import { useQuery } from "@tanstack/react-query";
import { fetchLatestSellsheetForGame, fetchSellsheetsForGame } from "../games/games-service";
import { AllSellsheetsByGameId, LatestSellsheetByGameId } from "../games/query-keys";
import { useAuth0 } from "@auth0/auth0-react";
import { s2Token } from "../util/type-conversion";

/**
 * @param {GameId} gameId
 */
export const useLatestSellsheet = (gameId) => {
  return useQuery({
    queryKey: LatestSellsheetByGameId(gameId),
    queryFn: async () => {
      const sellsheets = await fetchLatestSellsheetForGame(gameId);
      return sellsheets;
    },
  });
};

/**
 * @param {GameId} gameId
 */
export const useAllSellsheetsForGameId = (gameId) => {
  const { getAccessTokenSilently } = useAuth0();

  return useQuery({
    queryKey: AllSellsheetsByGameId(gameId),
    queryFn: async () => {
      const token = s2Token(await getAccessTokenSilently());

      const sellsheets = await fetchSellsheetsForGame(token, gameId);
      sellsheets.sort((a, b) => b.uploadedTimestamp - a.uploadedTimestamp);

      return sellsheets;
    },
  });
};
