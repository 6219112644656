import React, { useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useElementSize } from "usehooks-ts";
import { PdfViewer } from "../core/PdfViewer";
import { assetPath } from "../endpoints";

/**
 * @param {object} props
 * @property {Sellsheet?} props.sellsheet
 */
export const SellsheetPreview = ({ sellsheet }) => {
  const [squareRef, { width, height }] = useElementSize();
  const [loaded, setLoaded] = useState(false);
  const [hideImage, setHideImage] = useState(false);

  if (!sellsheet) {
    return <EmptyDetailCard text="Select sellsheet to view." />;
  }

  const useableWidth = width - 48;

  return (
    <Box p={2} pt={2} ref={squareRef}>
      <div>
        {!hideImage && (
          <>
            {!loaded && <CircularProgress />}
            <img
              onLoad={() => setLoaded(true)}
              style={{ width: loaded ? useableWidth : 0 }}
              src={assetPath(sellsheet.s3location)}
              alt="fullImage"
            />
          </>
        )}
      </div>
      <PdfViewer
        path={assetPath(sellsheet.s3location)}
        width={width}
        height={height}
        notifyOthers={() => setHideImage(true)}
      />
    </Box>
  );
};
